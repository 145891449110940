import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_FOLDER_ELEMENTS, GET_RECENT_DOCUMENTS } from '../actions';
import { loginUser } from '../../api/auth';
import { getRecentlyUploadedFiles } from '../../api/file';
import { getRecentDocumentsSuccess } from './actions';

const loginWithEmailPasswordAsync = async (email, password) =>
  await loginUser(email, password)
    .then(result => result)
    .catch(error => error);

function* getFolderElements({ payload }) {}

function* getRecentDocuments({ payload }) {
  const documents = yield call(getRecentlyUploadedFiles);

  yield put(getRecentDocumentsSuccess(documents));
}

export function* watchGetFolderElements() {
  yield takeEvery(GET_FOLDER_ELEMENTS, getFolderElements);
}

export function* watchGetRecentDocuments() {
  yield takeEvery(GET_RECENT_DOCUMENTS, getRecentDocuments);
}

export default function* rootSaga() {
  yield all([fork(watchGetFolderElements), fork(watchGetRecentDocuments)]);
}
