import React, { useState } from 'react';

import Modal from '../../custom/Modal';
import './style.scss';

const TemplateTipModal = props => {
  return (
    <Modal toggle={props.closeModalEmitter}>
      <div className="esign-modal template-tip-modal">
        <h1 className="esign-modal__title">
          Tip: Consider Creating a Template
        </h1>
        <p>
          If you’ll be sending this form out again in the future, consider
          creating a <b>template</b> (it’s free!).
        </p>
        <br />
        <p>To create a template, all you need to do is:</p>
        <br />
        <p>1) Place fields onto a form,</p>
        <p>2) Specify the role of the recipient (“Client” for example), and</p>
        <p>3) Save it!</p>
        <br />
        <p>
          This allows sign requests to be sent with the click of a button,
          removing the need to place fields each and every time.
        </p>
        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={props.rejectTip}
          >
            No, Thanks
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={props.saveAsTemplate}
          >
            Save as Template
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateTipModal;
