import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomInput from '../custom/CustomInput';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';

import './style.scss';

class SignersForm extends React.Component {
  onFullNameChange = (e, ind) => {
    const { onFullNameChange } = this.props;
    onFullNameChange(e.target.value, ind);
  };

  onEmailChange = (e, ind) => {
    const { onEmailChange } = this.props;
    onEmailChange(e.target.value, ind);
  };

  addSigner = () => {
    const { addSigner } = this.props;
    addSigner({ fullName: '', email: '' });
  };

  removeSigner = index => {
    const { removeSigner } = this.props;
    removeSigner(index);
  };

  onKeyPressHandler = e => {
    if (e.key === 'Enter') {
      this.props.onEnterKeyPress();
    }
  };

  getTitle = index => {
    if (this.props.areYouSigner) {
      if (index === 0) {
        return 'Your name';
      }
      return `Additional signer  ${index}`;
    }
    return `Signer ${index + 1}`;
  };

  getPlaceholder = index => {
    if (this.props.areYouSigner && index === 0) {
      return 'Full name';
    }
    return 'Signer’s name';
  };

  render() {
    const { signers, showErrorMessage } = this.props;
    return (
      <div className="signers-form">
        <div>
          {signers.map((signer, ind) => {
            return (
              <div
                className="signers-form__add-signer-block"
                key={signer.toString() + ind}
              >
                <span className="signers-form__title">
                  {this.getTitle(ind)}
                </span>
                <div className="input-field-wrapper">
                  <CustomInput
                    value={signer.fullName}
                    type="text"
                    disabled={signer.isDisabled}
                    placeholder={this.getPlaceholder(ind)}
                    name="fullName"
                    onChange={e => this.onFullNameChange(e, ind)}
                    onKeyPress={this.onKeyPressHandler}
                    error={
                      (signer.isChanged &&
                        !signer.isNameValid &&
                        showErrorMessage) ||
                      signer.fullName.length > 50
                    }
                    errorMessage={
                      signer.fullName.length > 50
                        ? 'Invalid name: The name shouldn’t be longer than 50 characters.'
                        : 'Invalid name'
                    }
                  />
                </div>
                <div className="input-field-wrapper">
                  <CustomInput
                    value={signer.email}
                    type="email"
                    disabled={signer.isDisabled}
                    placeholder="Email address"
                    name="email"
                    onChange={e => this.onEmailChange(e, ind)}
                    onKeyPress={this.onKeyPressHandler}
                    error={
                      signer.isChanged &&
                      !signer.isEmailValid &&
                      showErrorMessage
                    }
                    errorMessage="Invalid email"
                  />
                </div>
                {!signer.isDisabled && (
                  <button
                    className="remove-signer-btn"
                    onClick={() => this.removeSigner(ind)}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div>
          <button className="add-signer-btn" onClick={this.addSigner}>
            <div className="plus-btn">
              <CloseIcon />
            </div>
            Add new signer
          </button>
        </div>
      </div>
    );
  }
}

SignersForm.propTypes = {
  signers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      isNameValid: PropTypes.bool,
      isEmailValid: PropTypes.bool,
      isChanged: PropTypes.bool,
      isDisabled: PropTypes.bool,
    }),
  ),
  addSigner: PropTypes.func,
  removeSigner: PropTypes.func,
  onFullNameChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  showErrorMessage: PropTypes.bool,
};

SignersForm.defaultProps = {
  signers: [],
  showErrorMessage: false,
};

const mapStateToProps = ({ createTemplate }) => {
  const { areYouSigner } = createTemplate;
  return { areYouSigner };
};

export default connect(mapStateToProps, null)(SignersForm);
