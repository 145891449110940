import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { sendVerificationCode, checkVerificationCode } from '../../api/user';

import {
  setVerificationCode,
  setAreYouSigner,
} from '../../redux/createTemplate/actions';

import { ReactComponent as WarningIcon } from '../../assets/img/warning.svg';

import Modal from '../../components/custom/Modal';
import CustomInput from '../../components/custom/CustomInput';
import NotificationManager from '../Notifications/NotificationManager';

const VrificationEmailStep = props => {
  const [verificationCode, setVerificationCodeLocal] = useState('');
  const [activeSendRequest, setActiveSendRequest] = useState(false);
  const [delayResendCode, setDelayResendCode] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Verification code should have a 6-digit code',
  );

  useEffect(() => {
    setDelayResendCode(true);
    sendVerificationCode(props.senderEmail).then(res => {
      if (res?.success) {
        NotificationManager.success(
          'Verification code was sent',
          '',
          4000,
          null,
          null,
          'filled',
        );

        setTimeout(() => {
          setDelayResendCode(false);
        }, 5000);
      }
    });
  }, []);

  useEffect(() => {
    setErrorMessage('Verification code should have a 4-digit code');

    if (verificationCode.length > 4) {
      setError(true);
    } else {
      setError(false);
    }
  }, [verificationCode]);

  const onCheckVerififcationCodeHandler = () => {
    setActiveSendRequest(true);

    checkVerificationCode({
      email: props.senderEmail,
      code: +verificationCode,
    }).then(res => {
      if (res) {
        if (res.success) {
          setError(false);
          props.setVerificationCode(+verificationCode);
          props.goToNextStepEmitter();
        } else {
          if (res?.message) {
            setError(true);
            setErrorMessage(res?.message);
          } else {
            setError(true);
            setErrorMessage('Verification code is invalid');
          }
        }
      } else {
        setError(true);
        setErrorMessage(res?.message);
      }

      setActiveSendRequest(false);
    });
  };

  const onResendVerificationCodeHandler = () => {
    setDelayResendCode(true);
    sendVerificationCode(props.senderEmail).then(res => {
      if (res?.success) {
        NotificationManager.success(
          'Verification code was sent',
          '',
          4000,
          null,
          null,
          'filled',
        );

        setTimeout(() => {
          setDelayResendCode(false);
        }, 5000);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, [verificationCode]);

  const onEnterKeyDownHandler = e => {
    if (e.keyCode === 13) {
      if (
        verificationCode.length === 4 &&
        verificationCode.trim().length &&
        !activeSendRequest
      ) {
        onCheckVerififcationCodeHandler();
      }
    }
  };

  return (
    // <Modal toggle={props.closeModalEmitter} className="sign-document-page-wrapper">
    <div
      className={`esign-modal verification-email-modal ${props.animationState}`}
    >
      <div className="verification-email-modal--title">Security checkpoint</div>
      <div className="verification-email-modal--subtitle">
        <WarningIcon /> &nbsp; Verification required. Please check your email.
      </div>
      <div className="verification-email-modal--description">
        We've sent an email to {props.senderEmail} containing a 4-digit code.
        Please enter the code below to continue.
      </div>
      <div className="verification-email-modal--input">
        <CustomInput
          type="number"
          name="verificationCode"
          placeholder="Type 4-digit code"
          value={verificationCode}
          onChange={e => setVerificationCodeLocal(e.target.value)}
          error={error}
          errorMessage={errorMessage}
        />
      </div>
      <div className="verification-email-modal--buttons">
        <button
          style={
            props.deviceWidth > 768
              ? {
                  position: 'absolute',
                  left: '30px',
                }
              : {}
          }
          disabled={delayResendCode}
          className="esign-button esign-button--light"
          onClick={() => onResendVerificationCodeHandler()}
        >
          Resend email
        </button>
        <div className="verification-email-modal--buttons">
          <button
            className="esign-button esign-button--light"
            onClick={() => {
              props.setAreYouSigner(true);
              props.goToPrevStepEmitter();
            }}
          >
            Back
          </button>
          <button
            className="esign-button esign-button--blue"
            disabled={
              verificationCode.length !== 4 ||
              !verificationCode.trim().length ||
              activeSendRequest
            }
            onClick={() => onCheckVerififcationCodeHandler()}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

const mapStateToProps = ({ createTemplate, navBar }) => {
  const { senderEmail } = createTemplate;
  const { deviceWidth } = navBar;

  return { senderEmail, createTemplate, deviceWidth };
};

const mapDispatchToProps = {
  setVerificationCode,
  setAreYouSigner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VrificationEmailStep);
