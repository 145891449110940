import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { ReactComponent as DownloadIcon } from '../../../../assets/img/download-2.svg';
import { ReactComponent as EditIcon } from '../../../../assets/img/edit.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';

import Modal from '../../../custom/Modal';
import Loader from '../../../Loader';

import './style.scss';

const PreviewWpDocumentModal = props => {
  const [numPages, setNumPages] = useState(null);

  return (
    <Modal showCloseButton={false} toggle={props.closeModalEmitter}>
      <div className="esign-modal preview-wp-document-modal">
        <div className="preview-wp-document-modal--header">
          <div className="buttons-group">
            <button
              className="esign-button esign-button--blue"
              onClick={() =>
                props.onFillNowHandler(props.file.url, props.file.id)
              }
            >
              <EditIcon /> Fill Now
            </button>
            <button
              className="esign-button esign-button--light"
              onClick={() =>
                props.onSaveDocumentToAccount(props.file.url, props.file.name)
              }
            >
              <DownloadIcon /> Save
            </button>
          </div>
          <div className="close-btn" onClick={props.closeModalEmitter}>
            <CloseIcon />
          </div>
        </div>
        <div className="preview-wp-document-modal--container">
          <div className="preview-wrapper">
            <Document
              file={props.file.url}
              onLoadSuccess={data => setNumPages(data.numPages)}
              onLoadError={() => {}}
              loading={
                <div className="spinner">
                  <Loader size={25} />
                </div>
              }
            >
              {Array.from(new Array(numPages), (item, index) => (
                <Page
                  pageNumber={index + 1}
                  loading={null}
                  onLoadError={() => {}}
                  onRenderError={() => {}}
                />
              ))}
            </Document>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewWpDocumentModal;
