import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EyeIcon } from '../../../assets/img/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/img/eye-off.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/img/exclamation-mark.svg';

import './style.scss';

const CustomInput = props => {
  const {
    wrapperClassName,
    value,
    label,
    error,
    errorMessage,
    placeholder,
    type,
    children,
    required,
    valid,
    ...rest
  } = props;

  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setIsEmpty(value === '');
  }, [value]);

  const [inputType, setInputType] = useState(type);

  const showHidePassword = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <div
      className={`esign-input-wrapper ${wrapperClassName} ${
        valid ? `esign-input-wrapper--valid` : ``
      } ${error ? 'esign-input-wrapper--error' : ''} ${
        !isEmpty ? 'esign-input-wrapper--focused' : ''
      }`}
    >
      {label && <span className="esign-input__label">{label}</span>}
      {error && errorMessage && (
        <span className="esign-input__error-msg">
          {errorMessage ? (
            <>
              <ErrorIcon />
              {errorMessage}
            </>
          ) : (
            errorMessage
          )}
        </span>
      )}
      <input
        {...rest}
        value={value}
        type={inputType}
        placeholder={placeholder}
        required
      />
      <span className="esign-input__placeholder">
        {placeholder}
        {required && <i>&nbsp;*</i>}
      </span>
      {type === 'password' && (
        <button
          type="button"
          className="esign-input__show-hide-btn"
          onClick={showHidePassword}
        >
          {inputType === 'password' ? <EyeIcon /> : <EyeOffIcon />}
        </button>
      )}
      {children}
    </div>
  );
};

CustomInput.propTypes = {
  wrapperClassName: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

CustomInput.defaultProps = {
  wrapperClassName: '',
  error: false,
  errorMessage: null,
};

export default CustomInput;
