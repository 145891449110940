import {
  SET_SELECTED_FILTER,
  GET_FOLDER_ELEMENTS,
  SET_FOLDER_ELEMENTS,
  SET_TYPE_MAIL_BOX,
  GET_FOLDER_ELEMENTS_SUCCESS,
  GET_RECENT_DOCUMENTS,
  GET_RECENT_DOCUMENTS_SUCCESS,
  TRIGGER_UPDATE_WAIT_FOR_ME_DOCUMENTS,
  TOGGLE_DOCUMENT_CHECKBOX,
  TOGGLE_ALL_DOCUMENTS_CHECKBOX,
  CHANGE_PERMISSION_VIA_SHARE_MODAL,
  CHECK_AS_FAVORITE,
  CHECK_AS_UNFAVORITE,
} from '../actions';

export const ckeckAsFavorite = id => ({
  type: CHECK_AS_FAVORITE,
  payload: id,
});

export const ckeckAsUnFavorite = id => ({
  type: CHECK_AS_UNFAVORITE,
  payload: id,
});

export const changePermissionViaShareModal = (email, permission) => ({
  type: CHANGE_PERMISSION_VIA_SHARE_MODAL,
  payload: { email, permission },
});

export const setSelectedFilter = selectedFilter => ({
  type: SET_SELECTED_FILTER,
  payload: selectedFilter,
});

export const getFolderElements = folderPath => ({
  type: GET_FOLDER_ELEMENTS,
  payload: { folderPath },
});

export const setFolderElements = folderElements => ({
  type: SET_FOLDER_ELEMENTS,
  payload: folderElements,
});

export const setTypeMailBox = mailBoxType => ({
  type: SET_TYPE_MAIL_BOX,
  payload: mailBoxType,
});

export const getRecentDocuments = () => ({
  type: GET_RECENT_DOCUMENTS,
});

export const getRecentDocumentsSuccess = documents => ({
  type: GET_RECENT_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const getFolderElementsSuccess = docs => ({
  type: GET_FOLDER_ELEMENTS_SUCCESS,
  payload: docs,
});

export const updateWaitForMeDocuments = triggerUpdate => ({
  type: TRIGGER_UPDATE_WAIT_FOR_ME_DOCUMENTS,
  payload: triggerUpdate,
});

export const toggleDocumentCheckbox = (isChecked, index) => ({
  type: TOGGLE_DOCUMENT_CHECKBOX,
  payload: { isChecked, index },
});

export const toggleAllDocumentsCheckbox = isChecked => ({
  type: TOGGLE_ALL_DOCUMENTS_CHECKBOX,
  payload: { isChecked },
});
