import {
  SET_FILE_ID,
  SET_UPLOADED_FILES,
  SET_IS_IMMEDIATE_TEMPLATE,
  SET_REQUEST_TYPE,
  SET_SENDER_EMAIL,
  SET_SENDER_FULL_NAME,
  SET_SIGNERS,
  RESTORE_SIGNERS,
  SET_IS_MULTIPLE_SIGNERS,
  SET_ARE_YOU_SIGNER,
  SET_ARE_YOU_SIGNER_ONLY,
  SET_RECEIVE_COPY,
  SET_VERIFICATION_CODE,
  SET_WHO_NEEDS_TO_SIGN,
  SET_EMPTY_FIELDS,
  RESET_TEMPLATE_STATE,
  SET_ORDERED,
  SET_DOC_TEMPLATE_TYPE,
  SET_ROLES,
  SET_ROLE_CONTACT,
  SET_SIGNER_CONTACT,
  SET_PROCESS_TEMPLATE,
  SET_ACTIVE_FILEID,
  SET_ACTIVE_ROLE_CONTACT,
  SET_ACTIVE_SIGNER_CONTACT,
  SET_ACTIVE_FIELDS,
  SET_LOADING_TEMPLATE,
  SET_SIGNATURE_REQUEST_ID_TEMPLATE,
} from '../actions';

export const setFileId = id => ({
  type: SET_FILE_ID,
  payload: id,
});

export const setUploadedFiles = files => ({
  type: SET_UPLOADED_FILES,
  payload: files,
});

export const setIsImmediateTemplate = isImmediate => ({
  type: SET_IS_IMMEDIATE_TEMPLATE,
  payload: isImmediate,
});

export const setRequestType = requestType => ({
  type: SET_REQUEST_TYPE,
  payload: requestType,
});

export const setSenderEmail = email => ({
  type: SET_SENDER_EMAIL,
  payload: email,
});

export const setSenderFullName = fullName => ({
  type: SET_SENDER_FULL_NAME,
  payload: fullName,
});

export const setSigners = signers => ({
  type: SET_SIGNERS,
  payload: signers,
});

export const restoreSigners = () => ({
  type: RESTORE_SIGNERS,
});

export const setIsMultipleSigners = isMultiple => ({
  type: SET_IS_MULTIPLE_SIGNERS,
  payload: isMultiple,
});

export const setAreYouSigner = areYouSigner => ({
  type: SET_ARE_YOU_SIGNER,
  payload: areYouSigner,
});

export const setAreYouSignerOnly = areYouSignerOnly => ({
  type: SET_ARE_YOU_SIGNER_ONLY,
  payload: areYouSignerOnly,
});

export const setVerificationCode = verificationCode => ({
  type: SET_VERIFICATION_CODE,
  payload: verificationCode,
});

export const setReceiveCopy = receive => ({
  type: SET_RECEIVE_COPY,
  payload: receive,
});

// V2 Methods
export const setWhoNeedsToSign = signersType => ({
  type: SET_WHO_NEEDS_TO_SIGN,
  payload: signersType,
});

export const setEmptyFields = fields => ({
  type: SET_EMPTY_FIELDS,
  payload: fields,
});

export const resetState = () => ({
  type: RESET_TEMPLATE_STATE,
});

export const setOrdered = ordered => ({
  type: SET_ORDERED,
  payload: ordered,
});

export const setDocTemplateType = type => ({
  type: SET_DOC_TEMPLATE_TYPE,
  payload: type,
});

export const setRoles = roles => ({
  type: SET_ROLES,
  payload: roles,
});

export const setRoleContact = roleContact => ({
  type: SET_ROLE_CONTACT,
  payload: roleContact,
});

export const setSignerContact = signerContact => ({
  type: SET_SIGNER_CONTACT,
  payload: signerContact,
});

export const setProcessTemplate = activeProcess => ({
  type: SET_PROCESS_TEMPLATE,
  payload: activeProcess,
});

export const setActiveFileId = activeFileId => ({
  type: SET_ACTIVE_FILEID,
  payload: activeFileId,
});

export const setActiveRoleContact = activeRoleContact => ({
  type: SET_ACTIVE_ROLE_CONTACT,
  payload: activeRoleContact,
});

export const setActiveSignerContact = activeSignerContact => ({
  type: SET_ACTIVE_SIGNER_CONTACT,
  payload: activeSignerContact,
});

export const setLoadingTemplate = loadingTemplate => ({
  type: SET_LOADING_TEMPLATE,
  payload: loadingTemplate,
});

export const setSignatureRequestId = signatureRequestId => ({
  type: SET_SIGNATURE_REQUEST_ID_TEMPLATE,
  payload: signatureRequestId,
});

export const setActiveFields = activeFields => ({
  type: SET_ACTIVE_FIELDS,
  payload: activeFields,
});
