import { api, getTokenFromCookie } from './index';
import { BASE_URL_V2, BASE_URL_V3 } from '../static/config';
import axios from 'axios';

export const createTemplate = payload => {
  return axios
    .post(`${BASE_URL_V2}/template`, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const createTemplateLink = payload => {
  return axios
    .post(`${BASE_URL_V2}/template/link`, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const updateTemplate = (templateId, payload) => {
  return axios
    .put(`${BASE_URL_V2}/template/${templateId}`, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const findAllTemplate = (params, signal = null) => {
  return axios
    .get(`${BASE_URL_V2}/template?${params}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
      signal,
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getTemplate = id => {
  return axios
    .get(`${BASE_URL_V2}/template/${id}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getTemplateUrl = id => {
  return axios
    .get(`${BASE_URL_V2}/template/${id}/download`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const copyTemplate = id => {
  return axios
    .post(
      `${BASE_URL_V2}/template/${id}/copy`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`,
        },
      },
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const changeFileTitleTemplate = (templateId, name) => {
  return axios
    .put(
      `${BASE_URL_V2}/template/${templateId}/rename`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`,
        },
      },
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const deleteTemplate = templateId => {
  return axios
    .delete(`${BASE_URL_V2}/template/${templateId}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getTemplateToken = id => {
  return axios
    .post(
      `${BASE_URL_V2}/template/${id}/token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`,
        },
      },
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getTemplateByToken = token => {
  return axios
    .get(`${BASE_URL_V2}/template/link?token=${token}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};
