import { useEffect, useState } from 'react';

/**
 * Custom hook to work with text input.
 * @param {string} initialValue.
 * @param {function} [isInvalidCheckFn]
 * @param {{maxLength: number}} [options]
 */
export function useFormInput(initialValue, isInvalidCheckFn, options = {}) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    let { value } = e.target;

    if ('maxLength' in options) {
      if (value.length > options.maxLength) {
        value = value.substring(0, options.maxLength);
      }
    }
    setValue(value);
  }

  let isInvalid = false;
  if (typeof isInvalidCheckFn === 'function') {
    isInvalid = isInvalidCheckFn(value);
  }

  return { value, handleChange, setValue, isInvalid };
}
