import {
  SET_IS_DOCUMENT_CONTROLLER_OPEN,
  TOGGLE_IS_DOCUMENT_DRAGGING,
  TOGGLE_UPLOAD_DOCUMENT_MODAL,
  TOGGLE_CREATE_FOLDER_MODAL,
  TOGGLE_UPLOAD_FOLDER_MODAL,
  TOGGLE_UPLOAD_DOCUMENT_SIGN_NOTARIZE_MODAL,
  SET_SEARCH_TEXT,
  TOGGLE_NEW_TEMPLATE_MODAL,
  TOGGLE_SHOW_CONTACT_MODAL,
  SET_DETAIL_MAIL_BOX,
} from '../actions';

const INIT_STATE = {
  isSomeControllerOpen: false,
  isSomeElementDragging: false,
  showUploadDocumentModal: false,
  showCreateFolderModal: false,
  showUploadFolderModal: false,
  showUploadDocumentSignNotarizeModal: false,
  searchText: '',
  showNewTemplateModal: false,
  showContactModal: false,
  detailMailbox: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_IS_DOCUMENT_CONTROLLER_OPEN:
      return { ...state, isSomeControllerOpen: action.payload };
    case TOGGLE_IS_DOCUMENT_DRAGGING:
      return { ...state, isSomeElementDragging: action.payload };
    case TOGGLE_UPLOAD_DOCUMENT_MODAL:
      return { ...state, showUploadDocumentModal: action.payload };
    case TOGGLE_CREATE_FOLDER_MODAL:
      return { ...state, showCreateFolderModal: action.payload };
    case TOGGLE_UPLOAD_FOLDER_MODAL:
      return { ...state, showUploadFolderModal: action.payload };
    case TOGGLE_UPLOAD_DOCUMENT_SIGN_NOTARIZE_MODAL:
      return { ...state, showUploadDocumentSignNotarizeModal: action.payload };
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.payload };
    case TOGGLE_NEW_TEMPLATE_MODAL:
      return { ...state, showNewTemplateModal: action.payload };
    case TOGGLE_SHOW_CONTACT_MODAL:
      return { ...state, showContactModal: action.payload };
    case SET_DETAIL_MAIL_BOX:
      return { ...state, detailMailbox: action.payload };
    default:
      return { ...state };
  }
};
