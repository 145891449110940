import React from 'react';
import Modal from '../../custom/Modal';

import './style.scss';

const ModalRecipientRemove = props => {
  return (
    <Modal toggle={props.closeModalEmitter}>
      <div className="esign-modal move-file-to-folder-modal">
        <h1 className="esign-modal__title">Recipient is Assigned Fields</h1>

        <p className="esign-modal__subtitle">
          By deleting this recipient, all {props.fieldRemoved} their fields will
          also be deleted
        </p>

        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={props.closeModalEmitter}
          >
            Cancel
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={() => props.onDeleteBtnClick()}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRecipientRemove;
