import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';

import {
  HOME_PAGE_URL,
  DOMAIN_URL,
  FACEBOOK_APP_ID,
  GOOGLE_AUTH_CLIENT_ID,
  APPLE_AUTH_CLIENT_ID,
} from '../../static/config';

import { ReactComponent as GoogleIcon } from '../../assets/img/google-app-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/img/facebook-app-icon.svg';
import { ReactComponent as AppleIcon } from '../../assets/img/apple-app-icon.svg';

import NotificationManager from '../Notifications/NotificationManager';

const ExternalSignInSignUp = props => {
  const [isCookiesDisabled, setIsCookiesDisabled] = useState(false);

  function onGoogleFailureHandler(e) {
    // incognito mode in Chrome
    if (e.details === 'Cookies are not enabled in current environment.') {
      setIsCookiesDisabled(true);
    }
  }

  function onGoogleBtnClickHandler(openGoogleLoginModalEmitter) {
    if (isCookiesDisabled) {
      NotificationManager.error(
        'You probably use incognito browser mode. Please use normal mode to continue.',
        '',
        5000,
        null,
        null,
        'filled',
      );
    } else {
      openGoogleLoginModalEmitter();
    }
  }

  return (
    <>
      <div className="enter-to-app__external-auth-btn enter-to-app__external-auth-btn--google">
        <GoogleLogin
          clientId={GOOGLE_AUTH_CLIENT_ID}
          render={renderProps => (
            <button
              type="button"
              onClick={() => onGoogleBtnClickHandler(renderProps.onClick)}
              disabled={renderProps.disabled}
            >
              <span>
                <GoogleIcon />
              </span>
              {props.googleButtonText}
            </button>
          )}
          onSuccess={data => props.onSuccessCallback(data, 'GOOGLE')}
          onFailure={onGoogleFailureHandler}
          cookiePolicy={'single_host_origin'}
        />
      </div>
      <div className="enter-to-app__external-auth-btn enter-to-app__external-auth-btn--facebook">
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          fields="name,email"
          tag={'button'}
          icon={
            <span>
              <FacebookIcon />
            </span>
          }
          textButton={props.facebookButtonText}
          callback={data => {
            if (data.status !== 'unknown') {
              props.onSuccessCallback(data, 'FACEBOOK');
            }
          }}
        />
      </div>
      <div className="enter-to-app__external-auth-btn enter-to-app__external-auth-btn--apple">
        <AppleSignin
          authOptions={{
            clientId: APPLE_AUTH_CLIENT_ID,
            scope: 'email name',
            redirectURI: DOMAIN_URL,
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          noDefaultStyle={true}
          skipScript={false}
          render={renderProps => (
            <button
              type="button"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <span>
                <AppleIcon />
              </span>
              {props.appleButtonText}
            </button>
          )}
          onSuccess={data => props.onSuccessCallback(data, 'APPLE')}
        />
      </div>
    </>
  );
};

ExternalSignInSignUp.propTypes = {
  onSuccessCallback: PropTypes.func.isRequired,
  googleButtonText: PropTypes.string,
  facebookButtonText: PropTypes.string,
  appleButtonText: PropTypes.string,
};

ExternalSignInSignUp.defaultProps = {
  googleButtonText: 'Continue with Google',
  facebookButtonText: 'Continue with Facebook',
  appleButtonText: 'Continue with Apple',
};

export default ExternalSignInSignUp;
