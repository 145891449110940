import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setAreYouSigner,
  setAreYouSignerOnly,
  setReceiveCopy,
  setSenderEmail,
  setSenderFullName,
} from '../../redux/createTemplate/actions';

import { useFormInput } from './helpers';
import { validateEmail, validateFullName } from '../../utils';

import Modal from '../custom/Modal';
import RadioGroup from '../custom/RadioGroup';
import CustomInput from '../custom/CustomInput';

const areYouSignerOptions = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
];

const AskAreYouSignerStep = props => {
  const [allowFormValidation, setAllowFormValidation] = useState(false);

  const userEmailController = useFormInput(
    props.user?.email ?? '',
    value => !validateEmail(value),
  );

  const userFullNameController = useFormInput(
    props.user?.fullName ?? '',
    value => value.trim() === '' || !validateFullName(value),
  );

  function areYouSignerHandler(event) {
    props.setAreYouSigner(event.value === 'YES');

    if (props.isMultipleSigners) {
      props.setAreYouSignerOnly(false);
    } else {
      if (event.value === 'YES') {
        props.setAreYouSignerOnly(true);
      } else {
        props.setAreYouSignerOnly(false);
      }
    }
  }

  function goToNextStep() {
    setAllowFormValidation(true);

    if (!isGoToNextStepDisable()) {
      props.setSenderEmail(userEmailController.value);
      props.setSenderFullName(userFullNameController.value);
      props.goToNextStepEmitter();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, [
    props.areYouSigner,
    userEmailController.value,
    userFullNameController.value,
  ]);

  const onEnterKeyDownHandler = e => {
    if (e.keyCode === 13) {
      if (props.user) {
        goToNextStep();
      } else {
        if (!props.areYouSigner) {
          if (
            !userEmailController.isInvalid &&
            !userFullNameController.isInvalid
          ) {
            goToNextStep();
          }
        } else {
          goToNextStep();
        }
      }
    }
  };

  function isGoToNextStepDisable() {
    if (props.areYouSigner) {
      return false;
    } else {
      return (
        userFullNameController.isInvalid ||
        userFullNameController.value.length > 50 ||
        userEmailController.isInvalid
      );
    }
  }

  return (
    // <Modal
    //   toggle={props.closeModalEmitter}
    //   disableBackdropClick={true}
    //   freezeBodyScroll={false}
    //   className="without-animation"
    // >
    <div className={`esign-modal signing-step-modal ${props.animationState}`}>
      <h3 className="esign-modal__title">
        {props.isMultipleSigners ? 'Are you a signer?' : 'Are you the signer?'}
      </h3>
      <RadioGroup
        name="are-you-signer"
        onChange={areYouSignerHandler}
        options={areYouSignerOptions}
        defaultChecked={props.areYouSigner ? 'YES' : 'NO'}
      />
      {!props.areYouSigner && !props.user && (
        <>
          <p>
            <b>Where should we send a copy? </b> <br />
            <span style={{ color: 'gray' }}>
              (please enter your name and email to continue)
            </span>
          </p>
          <CustomInput
            type="text"
            value={userFullNameController.value}
            placeholder="Enter Name"
            onChange={userFullNameController.handleChange}
            error={
              allowFormValidation &&
              (userFullNameController.isInvalid ||
                userFullNameController.value.length > 50)
            }
            errorMessage={
              userFullNameController.value.length > 50
                ? 'Invalid email: The name shouldn’t be longer than 50 characters.'
                : 'Invalid email'
            }
          />
          <CustomInput
            type="email"
            value={userEmailController.value}
            placeholder="Enter Email"
            onChange={userEmailController.handleChange}
            error={allowFormValidation && userEmailController.isInvalid}
            errorMessage="Invalid email"
          />
        </>
      )}
      <div className="esign-modal__footer">
        <button
          className="esign-button esign-button--light"
          onClick={() => {
            props.goToPrevStepEmitter();
          }}
        >
          Back
        </button>
        <button
          className="esign-button esign-button--blue"
          disabled={
            !props.areYouSigner &&
            (userEmailController.isInvalid || userFullNameController.isInvalid)
          }
          onClick={goToNextStep}
        >
          Next
        </button>
      </div>
    </div>
    // </Modal>
  );
};

AskAreYouSignerStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const { areYouSigner, receiveCopy, isMultipleSigners } = createTemplate;

  return { user, areYouSigner, receiveCopy, isMultipleSigners };
};

const mapDispatchToProps = {
  setAreYouSignerOnly,
  setAreYouSigner,
  setReceiveCopy,
  setSenderEmail,
  setSenderFullName,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskAreYouSignerStep);
