import React, { useState, useEffect } from 'react';
import ImgsViewer from 'react-images-viewer';

import Loader from '../Loader';

import './style.scss';

const Avatar = props => {
  const [showLoader, setShowLoader] = useState(false);
  const [isImgViewerOpen, setIsImgViewerOpen] = useState(false);

  useEffect(() => {
    if (props.logoUrl) {
      setShowLoader(true);
    }
  }, []);

  const getUserInitialsFromFullName = (fullName = '') => {
    let firstCharacterOfFirstName = '';
    if (fullName.length > 0) {
      firstCharacterOfFirstName = fullName[0].toUpperCase();
    }

    let firstCharacterOfLastName = '';
    const lastName = fullName.split(' ')[1];
    if (lastName) {
      firstCharacterOfLastName = lastName[0].toUpperCase();
    }

    return firstCharacterOfFirstName + firstCharacterOfLastName;
  };

  return (
    <>
      <div
        className={props?.className}
        style={{ background: props.logoUrl ? '#B0B0B0' : props.bacgroundColor }}
      >
        {showLoader && (
          <div
            className="spinner"
            style={{ position: 'absolute', width: 'fit-content' }}
          >
            <Loader size={15} />
          </div>
        )}
        {props.logoUrl ? (
          <img
            onLoad={() => setShowLoader(false)}
            onClick={() =>
              props.showPreviewImgByClick && setIsImgViewerOpen(true)
            }
            src={props.logoUrl}
            loading="eager"
            alt="avatar not found"
            // style={{
            //   cursor: props.showPreviewImgByClick ? 'pointer' : 'default'
            // }}
          />
        ) : (
          <div
            style={{
              background: props.bacgroundColor
                ? props.bacgroundColor
                : '#B0B0B0',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                transform: 'translate(3%, 5%)',
              }}
            >
              {getUserInitialsFromFullName(props?.fullName)}
            </p>
          </div>
        )}
      </div>

      {isImgViewerOpen && (
        <ImgsViewer
          imgs={[{ src: props.logoUrl }]}
          isOpen={isImgViewerOpen}
          onClose={() => setIsImgViewerOpen(false)}
          backdropCloseable
          spinnerDisabled
          showImgCount={false}
          enableKeyboardInput={false}
        />
      )}
    </>
  );
};

export default Avatar;
