import { useState } from 'react';
import { validateEmail, validateFullName } from '../../utils';

export function useRoles(initialRoles = []) {
  const [roles, setRoles] = useState(initialRoles);

  function addRole(role) {
    setRoles([...roles, role]);
  }

  function removeRole(signerIndex) {
    const nextRoles = roles.filter((s, index) => index !== signerIndex);
    setRoles(nextRoles);
  }

  function setRoleName(roleName, signerIndex) {
    const nextRoles = roles.map((s, ind) => {
      if (ind === signerIndex) {
        const isRoleNameValid = roleName.trim() !== '';
        return { ...s, roleName, isRoleNameValid, isRoleNameTouched: true };
      }
      return s;
    });

    setRoles(nextRoles);
  }

  function setPrefillName(prefillName, signerIndex) {
    const nextRoles = roles.map((s, ind) => {
      if (ind === signerIndex) {
        const isPrefillNameValid = prefillName.trim() !== '';
        return {
          ...s,
          prefillName,
          isPrefillNameValid,
          isPrefillNameTouched: true,
        };
      }
      return s;
    });

    setRoles(nextRoles);
  }

  function setPrefillEmail(email, signerIndex) {
    const nextRoles = roles.map((s, ind) => {
      if (ind === signerIndex) {
        const isPrefillEmailValid = validateEmail(email);
        return {
          ...s,
          prefillEmail: email,
          isPrefillEmailValid,
          isPrefillEmailTouched: true,
        };
      }
      return s;
    });
    setRoles(nextRoles);
  }

  function setPrefillNameEmail(prefillName, prefillEmail, signerIndex) {
    const nextRoles = roles.map((s, ind) => {
      if (ind === signerIndex) {
        const isPrefillNameValid = prefillName.trim() !== '';
        const isPrefillEmailValid = validateEmail(prefillEmail);
        return {
          ...s,
          prefillName,
          isContactNameTouched: false,
          isPrefillNameValid,
          isPrefillNameTouched: true,
          prefillEmail,
          isPrefillEmailValid,
          isPrefillEmailTouched: true,
        };
      }
      return s;
    });

    setRoles(nextRoles);
  }

  function updateRoles(updatedList) {
    setRoles(updatedList);
  }

  return {
    roles,
    addRole,
    removeRole,
    setRoleName,
    setPrefillName,
    setPrefillEmail,
    setPrefillNameEmail,
    updateRoles,
  };
}

export function getRolesValidationStatus(roles = []) {
  const notEmptyRoles = roles.filter(s => s.roleName?.length > 0);
  const hasInvalidRole = notEmptyRoles.some(s => {
    return (
      s.hasPrefill &&
      (validateEmail(s.prefillEmail) === false ||
        s.prefillName.trim() === '' ||
        s.prefillName.length > 50 ||
        !validateFullName(s.prefillName))
    );
  });

  return {
    hasInvalidRole,
    notEmptyRoleAmount: notEmptyRoles.length,
  };
}
