import {
  SET_IS_DOCUMENT_CONTROLLER_OPEN,
  TOGGLE_IS_DOCUMENT_DRAGGING,
  TOGGLE_UPLOAD_DOCUMENT_MODAL,
  TOGGLE_CREATE_FOLDER_MODAL,
  TOGGLE_UPLOAD_FOLDER_MODAL,
  TOGGLE_UPLOAD_DOCUMENT_SIGN_NOTARIZE_MODAL,
  SET_SEARCH_TEXT,
  TOGGLE_NEW_TEMPLATE_MODAL,
  TOGGLE_SHOW_CONTACT_MODAL,
  SET_DETAIL_MAIL_BOX,
} from '../actions';

export const setIsDocumentControllerOpen = isOpen => ({
  type: SET_IS_DOCUMENT_CONTROLLER_OPEN,
  payload: isOpen,
});

export const toggleIsDocumentDragging = isDragging => ({
  type: TOGGLE_IS_DOCUMENT_DRAGGING,
  payload: isDragging,
});

export const toggleUploadDocumentModal = isOpen => ({
  type: TOGGLE_UPLOAD_DOCUMENT_MODAL,
  payload: isOpen,
});

export const toggleCreateFolderModal = isOpen => ({
  type: TOGGLE_CREATE_FOLDER_MODAL,
  payload: isOpen,
});

export const toggleUploadFolderModal = isOpen => ({
  type: TOGGLE_UPLOAD_FOLDER_MODAL,
  payload: isOpen,
});

export const toggleUploadDocumentSignNotarizeModal = isOpen => ({
  type: TOGGLE_UPLOAD_DOCUMENT_SIGN_NOTARIZE_MODAL,
  payload: isOpen,
});

export const setSearchText = text => ({
  type: SET_SEARCH_TEXT,
  payload: text,
});

export const toggleNewTemplateModal = isOpen => ({
  type: TOGGLE_NEW_TEMPLATE_MODAL,
  payload: isOpen,
});

export const toggleShowContactModal = isOpen => ({
  type: TOGGLE_SHOW_CONTACT_MODAL,
  payload: isOpen,
});

export const setDetailMailbox = detailMailbox => ({
  type: SET_DETAIL_MAIL_BOX,
  payload: detailMailbox,
});
