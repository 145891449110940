import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setOrdered, setRoles } from '../../redux/createTemplate/actions';

import { useRoles } from './helpers';

import { useFormInput } from '../../components/TemplatesProcess/helpers';

import GlobalLoader from '../GlobalLoader';
import { v4 as uuidV4 } from 'uuid';
import { RECIPIENTS_TYPE } from '../../static';
import { validateFullName, generateRandomColor } from '../../utils';
import RolesFormLink from '../RolesFormLink';
import { parseJwt } from '../../pages/SigningProcess/helper';
import { getTokenFromCookie } from '../../api';
import { getSignatureRequest } from '../../api/signature';
import { isFileSigned } from '../../api/file';
import { setDataToSessionStorage } from '../../utils';

export const ROLE_DEFAULT = {
  roleName: '',
  prefillName: '',
  prefillEmail: '',
  isPrefillEmailValid: false,
  isPrefillNameValid: false,
  isRoleNameValid: false,
  isPrefillEmailTouched: false,
  isPrefillNameTouched: false,
  isRoleNameTouched: false,
  hasPrefill: false,
  type: RECIPIENTS_TYPE.SIGNER,
  isDisabled: false,
};

const RolesEditLink = props => {
  let initialSigners = [];
  const [showLoader, setShowLoader] = useState(false);
  const colors = useRef(['#89bfff', '#4aefba', '#e9927b']);
  const [checkedOption, setCheckedOption] = useState('yes');
  const roleController = useFormInput(
    '',
    value => value.trim() === '' || !validateFullName(value.trim()),
  );
  const [selectedFilter, setSelectedFilter] = useState({});
  const [showError, setShowError] = useState(false);
  const [step, setStep] = useState(1);
  const [token, setToken] = useState(getTokenFromCookie());

  if (props.signers.length > 0) {
    initialSigners = props.signers;
  }

  if (initialSigners.length === 0) {
    initialSigners.push({
      role: uuidV4(),
      ...ROLE_DEFAULT,
    });
  }

  const { roles } = useRoles(initialSigners);

  // next step sibling
  function nextStep() {
    setShowError(true);
    if (!roleController.isInvalid) {
      setStep(2);
    }
  }

  // next step parent
  function goToNextStep() {
    const updated = roles.map((role, ix) => {
      if (ix === 0) {
        if (Object.keys(selectedFilter).length > 0) {
          return {
            ...role,
            role: selectedFilter.role,
            roleName: roleController.value,
            color: selectedFilter.color,
            prefillName: selectedFilter.name,
            prefillEmail: selectedFilter.email,
          };
        }
        return {
          ...role,
          roleName: roleController.value,
          color: colors.current[0],
        };
      }
      return role;
    });

    props.setRoles(updated);
    props.goToNextStepEmitter(updated);
    const parsedToken = parseJwt(token);

    setDataToSessionStorage(
      {
        [`${props.activeFileId}`]: updated,
        userId: parsedToken?.userId,
      },
      'roles',
    );
    return;
  }

  return (
    <>
      {showLoader && <GlobalLoader />}

      <div
        className={`esign-modal template-step-modal ${props.animationState}`}
      >
        <RolesFormLink
          step={step}
          role={roleController}
          colors={colors}
          signatureRequestId={props.signatureRequestId}
          checkedOption={checkedOption}
          setCheckedOption={setCheckedOption}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          showError={showError}
        />
        <div className="esign-modal__footer template-step-modal__footer">
          {props?.enableBack && (
            <button
              className="esign-button esign-button--light"
              onClick={() => {
                props.goToPrevStepEmitter();
              }}
            >
              Back
            </button>
          )}
          <button
            className="esign-button esign-button--blue"
            onClick={() => {
              if (
                step === 2 ||
                (!props.signatureRequestId && props.activeFileId)
              ) {
                goToNextStep();
                return;
              }
              nextStep();
            }}
            disabled={roleController.isInvalid}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

RolesEditLink.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;

  const { signers, roles, templateType, ordered } = createTemplate;

  return {
    user,
    roles,
    signers,
    templateType,
    ordered,
  };
};

const mapDispatchToProps = {
  setRoles,
  setOrdered,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesEditLink);
