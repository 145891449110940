import { SET_CONTACT, SET_CONTACTS, SET_LOADING_CONTACTS } from '../actions';

const INIT_STATE = {
  contacts: [],
  loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      return { ...state, contacts: action.payload };
    case SET_LOADING_CONTACTS:
      return { ...state, loading: action.payload };
    default:
      return { ...state };
  }
};
