import React, { useEffect, useState } from 'react';
import Modal from '../../custom/Modal';
import ContactInput from '../../custom/ContactInput';
import CustomContact from '../../custom/CustomContact';
import { useFormInput } from '../../SigningProcess/helpers';
import './style.scss';

const ContactListModal = props => {
  const contactController = useFormInput('', value => value.trim() === '');
  const [selected, setSelected] = useState(null);
  const [nameSortOrder, setNameSortOrder] = useState('desc');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (selected) {
      const value = getActiveName(selected, props.options);
      const e = { target: { value } };
      contactController.handleChange(e);
    }
  }, [selected, props.options]);

  const getActiveName = (selected, options) => {
    return options.find(f => f.value === selected)?.name;
  };

  return (
    <Modal toggle={props.closeModalEmitter}>
      <div className="esign-modal contact-list-element-modal">
        <h1 className="esign-modal__title">Contacts</h1>
        <CustomContact
          wrapperClassName="esign-custom-input-contact"
          value={props.searchContact}
          loading={props.loadingContact}
          activeRoles={props.activeRoles}
          nameSortOrder={nameSortOrder}
          onChange={e => {
            const value = e.target.value;
            setSearchValue(value);
            props.setSearchContact(value, nameSortOrder);
          }}
          onChangeOrder={order => {
            setNameSortOrder(order);
            props.setSearchContact(searchValue, order);
          }}
          placeholder="Search contacts"
          onSelected={props.onSelectedContact}
          selected={props.selectedContact}
          options={props.options}
        />
        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={props.closeModalEmitter}
          >
            Cancel
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={props.nextBtnEmitter}
          >
            Apply Selected
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactListModal;
