import {
  SET_TEMPLATE_ID,
  SET_TEMPLATE_TYPE,
  SET_TEMPLATE_TOKEN,
  SET_TEMPLATE_IFRAME,
  SET_USER_PROFILE_COMPLETED_STATUS,
  SET_USER_COMPLETE_TOKEN,
} from '../actions';

export const setTemplateId = id => ({
  type: SET_TEMPLATE_ID,
  payload: id,
});

/**
 * @typedef {Object} templateType
 * @property {('GET_IFRAME_URL_BY_FILE_ID'|'GET_IFRAME_URL_BY_TEMPLATE_ID'|'GET_IFRAME_URL_BY_PUBLIC_TOKEN')} type
 * @property {string} iframeUrlAccessKey
 */

/**
 * @param {templateType} templateType
 */
export const setTemplateType = templateType => ({
  type: SET_TEMPLATE_TYPE,
  payload: templateType,
});

export const setTemplateToken = token => ({
  type: SET_TEMPLATE_TOKEN,
  payload: token,
});

export const setTemplateIframe = url => ({
  type: SET_TEMPLATE_IFRAME,
  payload: url,
});

export const setUserProfileCompletedStatus = isCompleted => ({
  type: SET_USER_PROFILE_COMPLETED_STATUS,
  payload: isCompleted,
});

export const setUserCompleteToken = token => ({
  type: SET_USER_COMPLETE_TOKEN,
  payload: token,
});
