import {
  ATTACHMENT,
  AUTO_DATE,
  CHECKBOX,
  COMPANY_NAME,
  DATE,
  DEFAULT_DATE_FORMAT,
  EMAIL,
  FULL_NAME,
  INITIALS,
  RADIO_BUTTON,
  RADIO_GROUP,
  SIGNATURE,
  TEXT,
  TITLE,
} from '../PrepareForm/constants';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment/moment';
import 'moment-timezone';
import { isIosDevice, isMobileBrowser } from '../../utils';

const DEFAULT_FONT_SIZE = 12;
export const hasValue = field => {
  switch (field?.type) {
    case FULL_NAME:
    case TEXT:
    case COMPANY_NAME:
    case TITLE:
    case EMAIL:
    case DATE:
    case AUTO_DATE:
      return !!field?.fieldValue;
    case CHECKBOX:
    case RADIO_BUTTON:
      return !!field?.checked;
    case RADIO_GROUP:
      return field?.fields?.some(({ checked }) => checked);
    case SIGNATURE:
    case INITIALS:
      return !!field?.image;
    case ATTACHMENT:
      return !!field?.files;
    default:
      return false;
  }
};

export const convertFieldsFromResponse = fields => fields.map(mapField);

export const mapField = ({ borders, ...attributes }) => ({
  ...attributes,
  isRequired: attributes.required,
  posX: isMobileBrowser() ? borders.first.x - 2 : borders.first.x,
  posY:
    isMobileBrowser() && attributes.type !== RADIO_GROUP
      ? borders.first.y + (borders.second.y - borders.first.y) / 2 - 1
      : borders.first.y,
  width: borders.second.x - borders.first.x,
  height: borders.second.y - borders.first.y,
  fontSize:
    attributes?.metadata?.fontSize || (isIosDevice() ? 8 : DEFAULT_FONT_SIZE),
  dateFormat: attributes?.metadata?.dateFormat,
  multiLine:
    attributes?.multiLine ||
    (attributes?.type === TEXT && /\r?\n/g.test(attributes?.value)),
});

export const parseJwt = token => {
  if (token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
      return JSON.parse(jsonPayload);
    } catch {}
  }
  return null;
};

export const linearFields = fields => {
  const linearFields = [];
  fields.forEach(field => {
    if (field.group && field?.fields) {
      linearFields.concat(field.fields);
    } else {
      linearFields.push(field);
    }
  });
  return linearFields;
};

export const convertFieldsToPayload = (fields, scale) =>
  fields
    .map((field, idx) => ({
      id: field?.id || uuidV4(),
      role: field.role,
      required: !!field.required,
      type: field.type,
      page: field.page,
      name: field.name ? field.name : `${field.type}${idx + 1}`,
      value: getFieldValue(field),
      metadata: getFieldMetadata(field, scale),
    }))
    .filter(
      field => ![SIGNATURE, INITIALS].includes(field.type) || field.value,
    );

export const getFieldValue = field => {
  switch (field?.type) {
    case CHECKBOX:
    case RADIO_BUTTON:
      return field?.checked;
    case SIGNATURE:
    case INITIALS:
      return stripeBase64String(field?.image);
    case ATTACHMENT:
      return field?.files;
    default:
      return field?.fieldValue;
  }
};

export const getFieldMetadata = (field, scale) => {
  switch (field?.type) {
    case DATE:
    case AUTO_DATE:
      return {
        fontSize: scale > 0 ? field?.fontSize / scale : field?.fontSize,
        dateFormat: field?.dateFormat,
      };
    case TEXT:
    case FULL_NAME:
    case EMAIL:
    case COMPANY_NAME:
    case TITLE:
      return {
        fontSize: scale > 0 ? field?.fontSize / scale : field?.fontSize,
      };
    default:
      return undefined;
  }
};

export const stripeBase64String = base64 =>
  base64 ? base64.split(',')[1] : '';

export const sortByPosition = (a, b) => {
  if (a.posY > b.posY) return 1;
  if (a.posY < b.posY) return -1;
  if (a.posX > b.posX) return 1;
  if (a.posX < b.posX) return -1;
  return 0;
};

export const getInitialFieldValue = (field, myInfo, companyName, jobTitle) => {
  if (field?.value) {
    return field.value;
  }
  if (field?.type) {
    const { type } = field;
    switch (type) {
      case AUTO_DATE:
        return moment().format(field?.dateFormat || DEFAULT_DATE_FORMAT);
      case FULL_NAME:
        return myInfo?.role === field.role ? myInfo?.name : field?.value;
      case EMAIL:
        return myInfo?.role === field.role ? myInfo?.email : field?.value;
      case COMPANY_NAME:
        return myInfo?.role === field.role ? companyName : field?.value;
      case TITLE:
        return myInfo?.role === field.role ? jobTitle : field?.value;
      default:
        return field?.value;
    }
  }
  return field?.value;
};
