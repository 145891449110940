import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import CustomInput from '../custom/CustomInput';
import RadioButton from '../custom/RadioGroup/RadioButton';

import { recipientsExample } from '../../static';
import { getSignatureRequest } from '../../api/signature';
import './style.scss';

const RolesFormLink = props => {
  const [recipientsOption, setRecipientsOption] = useState([]);

  useEffect(() => {
    if (props.signatureRequestId) {
      getSignatureRequest(props.signatureRequestId).then(res => {
        if (res) {
          setRecipientsOption(
            res.signers.map(s => {
              return {
                ...s,
                value: s.name,
                label: s.name,
                role: s.role,
              };
            }),
          );
        }
      });
    }
  }, [props.signatureRequestId]);

  const {
    checkedOption,
    setCheckedOption,
    role,
    selectedFilter,
    setSelectedFilter,
    showError,
  } = props;

  const selectedFilterValue =
    Object.keys(selectedFilter).length === 0 ? null : selectedFilter;

  return (
    <div className="roles-form-link">
      {props.step === 1 ? (
        <>
          <h1 className="esign-modal__title">Enter Role Name</h1>
          <p className="esign-modal__subtitle">
            Enter the role this document's signer will represent.
          </p>
          <p className="esign-modal__subtitle">
            Examples include “Client,” “Employee,” “Customer,” or something more
            generic like “Recipient.”
          </p>
          <CustomInput
            value={role.value}
            onChange={role.handleChange}
            type="text"
            placeholder="Role (e.g., Client, Employee)"
            error={showError && role.isInvalid}
            errorMessage="Invalid name"
          />
        </>
      ) : (
        <>
          <h1 className="esign-modal__title">Assign Existing Fields?</h1>
          <p className="esign-modal__subtitle">
            This document contains fields from one (1) or more recipients.
          </p>
          <p className="esign-modal__subtitle">
            Would you like to assign existing fields to&nbsp;
            <b>{role.value}</b>?
          </p>
          <div className="radio radio-yes">
            <RadioButton
              name={'yes'}
              checked={checkedOption === 'yes'}
              onChange={answer => setCheckedOption(answer)}
            />
            <div className="radio-label">
              <span>Yes, assign fields from the following recipient:</span>
              <Select
                className={
                  checkedOption === 'yes' ? 'esign-select-list' : 'disabled'
                }
                placeholder={'Select recipient'}
                classNamePrefix="esign-select-list"
                isDisabled={checkedOption === 'no'}
                isSearchable={false}
                options={recipientsOption}
                onChange={setSelectedFilter}
                value={selectedFilterValue}
              />
            </div>
          </div>
          <div className="radio">
            <RadioButton
              name={'no'}
              checked={checkedOption === 'no'}
              onChange={answer => setCheckedOption(answer)}
            />
            <span>No, I’ll add fields myself (ignore existing fields)</span>
          </div>
        </>
      )}
    </div>
  );
};

export default RolesFormLink;
