import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { getWpDocuments, uploadNamedFilesByUrl } from '../../../api/file';

import { DOMAIN_URL } from '../../../static/config';

import { ReactComponent as DownloadIcon } from '../../../assets/img/download-2.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/edit.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';

import Modal from '../../custom/Modal';
import SearchInput from '../../../components/custom/SearchInput';
import Loader from '../../Loader';
import PreviewWpDocumentModal from './PreviewWpDocumentModal';
import NotFound from '../../../pages/NotFound';
import NotificationManager from '../../Notifications/NotificationManager';

import './style.scss';

const WpDocumentsLibraryModal = props => {
  const [showLoader, setShowLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResultDocuments, setSearchResultDocuments] = useState([]);
  const [showPreviewWpDocumentModal, setShowPreviewWpDocumentModal] = useState(
    false,
  );
  const [fileForPreview, setFileForPreview] = useState(null);

  const history = useHistory();

  useEffect(() => {
    onGetWpDocumentsHandler();
  }, []);

  useEffect(() => {
    if (searchResultDocuments.length) {
      onSearchDocumentsHandler();
    }
  }, [searchValue]);

  const onGetWpDocumentsHandler = () => {
    setShowLoader(true);

    getWpDocuments().then(res => {
      const result = [
        {
          id: 0,
          title: 'All',
          active: true,
          children: Object.values(res)
            .map(item => Object.values(item.children))
            .flat(1)
            .map(item => {
              return {
                id: item.ID,
                name: item.post_title?.replaceAll(' ', '-') + '.pdf',
                title: item.post_title,
                description: item.post_content + '...',
                url: item.adobe_pdf,
                imgUrl: item.image,
                isMatchWithSearchParams: true,
              };
            }),
        },
        ...Object.values(res).map((item, index) => {
          return {
            id: item.ID,
            title: item.post_title,
            children:
              Object.values(item.children).map(item => {
                return {
                  id: item.ID,
                  name: item.post_title.replaceAll(' ', '-') + '.pdf',
                  title: item.post_title,
                  description: item.post_content + '...',
                  url: item.adobe_pdf,
                  imgUrl: item.image,
                  isMatchWithSearchParams: true,
                };
              }) || [],
            active: false,
          };
        }),
      ];

      setSearchResultDocuments(result);

      setShowLoader(false);
    });
  };

  const onSearchDocumentsHandler = () => {
    setShowLoader(true);

    setTimeout(() => {
      const changeActiveTab = searchResultDocuments.map(item => {
        item.active = item.id === 0;
        return item;
      });

      setSearchResultDocuments(
        changeActiveTab.map(item => {
          return item.active
            ? {
                ...item,
                children: item.children.map(item => ({
                  ...item,
                  isMatchWithSearchParams: !!item.title
                    .toLowerCase()
                    .toLowerCase()
                    .match(searchValue.toLowerCase()),
                })),
              }
            : item;
        }),
      );

      setShowLoader(false);
    }, 200);
  };

  const onFillNowHandler = (url, id) => {
    const encodeUrl = btoa(url);
    const encodeId = btoa(id);

    history.push(
      `/editor?hash=${encodeUrl}&guide=true&id=${encodeId}&redirectUrl=${DOMAIN_URL}/documents`,
    );
  };

  const onSaveDocumentToAccount = (url, name) => {
    uploadNamedFilesByUrl(
      [{ url, name }],
      null,
      null,
      props.user.rootFolderId,
    ).then(res => {
      if (res?.success?.length) {
        NotificationManager.success(
          `${name} has been successfully saved`,
          '',
          3000,
          null,
          null,
          'filled',
        );
      } else {
        NotificationManager.error(
          'Something went wrong. Please, try again later.',
          '',
          3000,
          null,
          null,
          'filled',
        );
      }
    });
  };

  return (
    <>
      <Modal showCloseButton={false} toggle={props.closeModalEmitter}>
        <div className="esign-modal wp-documents-library-modal">
          <div className="wp-documents-library-modal--header">
            <div className="title">Document Library</div>
            <div className="search-bar">
              <SearchInput
                placeholder="Search..."
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                onSearchClick={() => setSearchValue(searchValue)}
                isClearable={true}
                onClear={() => setSearchValue('')}
              />
            </div>
            <div className="close-btn" onClick={props.closeModalEmitter}>
              <CloseIcon />
            </div>
          </div>
          <div className="wp-documents-library-modal--containers">
            <div className="wp-documents-library-modal--containers__left-container">
              {searchResultDocuments?.map((item, index) => (
                <div
                  key={index}
                  className={`item ${item.active ? 'item--active' : ''}`}
                  onClick={() => {
                    setSearchValue('');
                    setSearchResultDocuments(
                      searchResultDocuments.map(i => {
                        return {
                          ...i,
                          active: item.id === i.id,
                          isMatchWithSearchParams: true,
                        };
                      }),
                    );
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 500);
                  }}
                >
                  <p>{item.title}</p>
                </div>
              ))}
            </div>
            <div className="wp-documents-library-modal--containers__right-container">
              {showLoader ? (
                <Loader size={45} />
              ) : searchResultDocuments?.find(item => item.active)?.children
                  ?.length ? (
                searchResultDocuments
                  ?.find(item => item.active)
                  ?.children?.map(
                    (item, index) =>
                      item.isMatchWithSearchParams && (
                        <div key={index} className="item">
                          <div
                            className="item--img"
                            onClick={() => {
                              setFileForPreview(item);
                              setShowPreviewWpDocumentModal(true);
                            }}
                          >
                            <img src={item.imgUrl} alt={item.title} />
                          </div>
                          <div className="item--title-description">
                            <div
                              className="item--title-description__title"
                              onClick={() => {
                                setFileForPreview(item);
                                setShowPreviewWpDocumentModal(true);
                              }}
                            >
                              {item.title}
                            </div>
                            <div className="item--title-description__description">
                              {item.description}
                            </div>
                            <div className="item--title-description__buttons">
                              <button
                                className="esign-button esign-button--blue"
                                onClick={() =>
                                  onFillNowHandler(item.url, item.id)
                                }
                              >
                                <EditIcon /> Fill Now
                              </button>
                              <button
                                className="esign-button esign-button--light"
                                onClick={() =>
                                  onSaveDocumentToAccount(item.url, item.name)
                                }
                              >
                                <DownloadIcon /> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      ),
                  )
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {showPreviewWpDocumentModal && (
        <PreviewWpDocumentModal
          file={fileForPreview}
          onFillNowHandler={onFillNowHandler}
          onSaveDocumentToAccount={onSaveDocumentToAccount}
          closeModalEmitter={() => {
            setFileForPreview(null);
            setShowPreviewWpDocumentModal(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;

  return {
    user,
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WpDocumentsLibraryModal),
);
