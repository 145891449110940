import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleOneTapLogin from 'react-google-one-tap-login';

import {
  service_loginWithEmailPassword,
  service_loginWithLoginToken,
  service_loginWithGoogleFacebookApple,
} from '../../services';
import {
  loginUserFacebook,
  loginUserGoogle,
  loginUserApple,
  verifyHuman,
} from '../../api/auth';
import {
  loginUserError,
  setIs2FAModalOpen,
  loginUserSuccess,
} from '../../redux/auth/actions';

import { validateEmail, googleOneTapOptions } from '../../utils';

import { useFormInput } from '../SigningProcess/helpers';

import GlobalLoader from '../GlobalLoader';
import Checkbox from '../custom/Checkbox';
import CustomInput from '../custom/CustomInput';
import ExternalSignInSignUp from './externaSignInSignUp';
import NotificationManager from '../Notifications/NotificationManager';
import { animationStateEnumeration } from '../SigningProcess';
import { RECAPTCHA_SITE_KEY } from '../../static/config';
import ReCAPTCHA from 'react-google-recaptcha';

const ACTIVE_SIGN_IN_TAB = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  ENTER_PASSWORD: 'ENTER_PASSWORD',
};

const SignIn = props => {
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(ACTIVE_SIGN_IN_TAB.ENTER_EMAIL);
  const [animationState, setAnimationState] = useState(
    animationStateEnumeration.STATIC,
  );
  const recaptchaRef = useRef(null);
  const notificationRef = useRef(null);

  const history = useHistory();

  const urlParams = new URLSearchParams(history.location.search);
  const initEmail = urlParams.get('email') || '';
  const emailController = useFormInput(
    initEmail,
    value => !validateEmail(value),
  );

  const passwordController = useFormInput('', value => value.trim().length < 1);

  useEffect(() => {
    if (props.errorLogin) {
      setShowLoader(false);
    }
  }, [props.errorLogin]);

  useEffect(() => {
    if (props.show2FALoginModal) {
      setShowLoader(false);
    }
  }, [props.show2FALoginModal]);

  useEffect(() => {
    if (urlParams.get('loginToken')) {
      if (urlParams.get('provider') && urlParams.get('provider') === 'GOOGLE') {
        externalLoginHandler(
          { tokenId: urlParams.get('loginToken') },
          'GOOGLE',
        );
      } else {
        service_loginWithLoginToken({
          loginToken: urlParams.get('loginToken'),
          setShowLoader,
          history,
          loginUserError: props.loginUserError,
          loginUserSuccess: props.loginUserSuccess,
          setIs2FAModalOpen: props.setIs2FAModalOpen,
        });
      }
    }

    return () => {
      props.loginUserError(null);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', onEnterKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, [activeTab, emailController.value, passwordController.value]);

  function onEnterKeyDownHandler(e) {
    if (e?.key === 'Enter') {
      // if (notificationRef?.current) {
      //   NotificationManager.remove(notificationRef.current);
      //   notificationRef.current = null;
      // }

      if (activeTab === ACTIVE_SIGN_IN_TAB.ENTER_EMAIL) {
        if (emailController.isInvalid) {
          if (notificationRef?.current) {
            NotificationManager.remove({ id: notificationRef.current });
            notificationRef.current = null;
          }
          notificationRef.current = NotificationManager.error(
            'Please enter correct email',
            '',
            3000,
            null,
            null,
            'filled',
          );
        } else {
          setActiveTab(ACTIVE_SIGN_IN_TAB.ENTER_PASSWORD);
        }
      } else {
        if (passwordController.isInvalid) {
          if (notificationRef?.current) {
            NotificationManager.remove({ id: notificationRef.current });
            notificationRef.current = null;
          }
          notificationRef.current = NotificationManager.error(
            'Please enter correct password',
            '',
            3000,
            null,
            null,
            'filled',
          );
        } else {
          loginUserHandler(e);
        }
      }
    }
  }

  async function loginUserHandler(e) {
    e.preventDefault();
    props.loginUserError(null);

    if (emailController.isInvalid || passwordController.isInvalid) {
      if (notificationRef?.current) {
        NotificationManager.remove({ id: notificationRef.current });
        notificationRef.current = null;
      }
      notificationRef.current = NotificationManager.error(
        'Please enter email and password',
        '',
        3000,
        null,
        null,
        'filled',
      );
    } else {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      // call login function
      service_loginWithEmailPassword({
        email: emailController.value,
        password: passwordController.value,
        setShowLoader,
        history,
        loginUserError: props.loginUserError,
        loginUserSuccess: props.loginUserSuccess,
        setIs2FAModalOpen: props.setIs2FAModalOpen,
        recaptchaToken: token,
      });
    }
  }

  function externalLoginHandler(data, provider) {
    setShowLoader(true);

    let token = null;
    let loginFunction = null;

    if (provider === 'GOOGLE') {
      token = data?.tokenId;
      loginFunction = loginUserGoogle;
    }
    if (provider === 'FACEBOOK') {
      token = data?.accessToken;
      loginFunction = loginUserFacebook;
    }
    if (provider === 'APPLE') {
      data.hasOwnProperty('user')
        ? (token = {
            idToken: data?.authorization?.id_token,
            user: data?.user?.name,
          })
        : (token = {
            idToken: data?.authorization?.id_token,
          });
      loginFunction = loginUserApple;
    }

    if (token) {
      // call login function
      service_loginWithGoogleFacebookApple({
        loginFunction,
        token,
        setShowLoader,
        history,
        loginUserError: props.loginUserError,
        loginUserSuccess: props.loginUserSuccess,
        setIs2FAModalOpen: props.setIs2FAModalOpen,
      });
    } else {
      NotificationManager.error(
        'Something went wrong. Please, try again later.',
        '',
        3000,
        null,
        null,
        'filled',
      );

      setShowLoader(false);
    }
  }

  return (
    <div className={`${props.animationState}`}>
      {showLoader && <GlobalLoader />}

      <GoogleOneTapLogin
        disabled={props.user}
        googleAccountConfigs={{
          client_id: googleOneTapOptions.client_id,
          state_cookie_domain: googleOneTapOptions.state_cookie_domain,
          context: 'signin',
          auto_select: false,
          callback: (...arg) =>
            externalLoginHandler({ tokenId: arg[0].credential }, 'GOOGLE'),
        }}
      />

      <div
        className={
          props.errorLogin
            ? 'enter-to-app__form with_error'
            : 'enter-to-app__form'
        }
      >
        <h1 className="title">
          {activeTab === ACTIVE_SIGN_IN_TAB.ENTER_PASSWORD && (
            <button
              onClick={() => {
                passwordController.handleChange({ target: { value: '' } });
                setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT);
                setActiveTab(ACTIVE_SIGN_IN_TAB.ENTER_EMAIL);
              }}
            >
              <svg
                width="10px"
                height="18px"
                viewBox="0 0 10 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Symbols"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Icons/General/Back-arrow"
                    transform="translate(-4.000000, 0.000000)"
                    fill="#000000"
                    fill-rule="nonzero"
                  >
                    <g
                      id="np_arrow-down_2424963_000000"
                      transform="translate(9.000000, 9.000000) rotate(90.000000) translate(-9.000000, -9.000000) translate(-0.000000, 4.000000)"
                    >
                      <path
                        d="M1.08882791,0.000881521619 C0.810882445,0.000881521619 0.528527845,0.107847606 0.317116707,0.317385294 C-0.105705569,0.736464423 -0.105705569,1.42881525 0.317116707,1.84714415 L8.22346181,9.68349623 C8.42747394,9.88570228 8.71577046,10 8.99517302,10 C9.27236156,10 9.548093,9.90035992 9.76688423,9.68349623 L17.6630108,1.85727227 C18.1050402,1.44479518 18.1109632,0.735582901 17.6881428,0.317254004 C17.2653205,-0.101825125 16.5667857,-0.101825125 16.1447204,0.317254004 L9.01006755,7.40262468 L1.86065455,0.316503773 C1.64924342,0.106964208 1.36686989,0 1.08894335,0 L1.08882791,0.000881521619 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          )}
          Log in
        </h1>
        {activeTab === ACTIVE_SIGN_IN_TAB.ENTER_EMAIL ? (
          <div className={animationState}>
            <ExternalSignInSignUp
              onSuccessCallback={externalLoginHandler}
              googleButtonText="Continue with Google"
              facebookButtonText="Continue with Facebook"
              appleButtonText="Continue with Apple"
            />
            <p className="esign-modal__separator">
              <span>or sign in using e-mail</span>
            </p>
            <CustomInput
              type="email"
              autoFocus={true}
              placeholder="Enter your email"
              value={emailController.value}
              onChange={e => {
                props.loginUserError(null);
                emailController.handleChange(e);
              }}
            />
            {!!props.errorLogin && (
              <span className="error_message">{props.errorLogin}</span>
            )}
          </div>
        ) : (
          <div className={animationState}>
            <div className="enter-to-app__form--current-email">
              <b>Email:&nbsp;</b>
              <span>{emailController.value}</span>
              <b
                onClick={() => {
                  passwordController.handleChange({ target: { value: '' } });
                  setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT);
                  setActiveTab(ACTIVE_SIGN_IN_TAB.ENTER_EMAIL);
                }}
                className="enter-to-app__form--edit-email"
              >
                Edit email
              </b>
            </div>
            <CustomInput
              type="password"
              autoFocus={true}
              placeholder="Enter your password"
              value={passwordController.value}
              onChange={e => {
                props.loginUserError(null);
                passwordController.handleChange(e);
              }}
            />
            {!!props.errorLogin ? (
              <>
                <span className="error_message">{props.errorLogin}</span>
                <br />
                <br />
              </>
            ) : (
              <br />
            )}
            <div className="flex-row justify-between">
              <Checkbox label="Remember me" />
              <button
                type="button"
                className="enter-to-app__btn"
                onClick={e =>
                  props.onForgotPasswordBtnClick(e, emailController.value)
                }
              >
                Forgot Password?
              </button>
            </div>
          </div>
        )}
        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          size={'invisible'}
          ref={recaptchaRef}
        />
        <button
          className="esign-button esign-button--blue"
          disabled={
            activeTab === ACTIVE_SIGN_IN_TAB.ENTER_EMAIL
              ? emailController.isInvalid
              : passwordController.isInvalid
          }
          onClick={e => {
            props.loginUserError(null);

            if (activeTab === ACTIVE_SIGN_IN_TAB.ENTER_EMAIL) {
              setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT);
              setActiveTab(ACTIVE_SIGN_IN_TAB.ENTER_PASSWORD);
            } else {
              loginUserHandler(e);
            }
          }}
        >
          {activeTab === ACTIVE_SIGN_IN_TAB.ENTER_PASSWORD
            ? 'Log in'
            : 'Continue'}
        </button>
        {activeTab === ACTIVE_SIGN_IN_TAB.ENTER_EMAIL && (
          <div className="enter-to-app__footer">
            Don't have an account?
            <button
              className="enter-to-app__btn"
              onClick={props.onDontHaveAccountBtnClick}
            >
              Sign up now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

SignIn.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  onForgotPasswordBtnClick: PropTypes.func.isRequired,
  onDontHaveAccountBtnClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser }) => {
  const { user, errorLogin, show2FALoginModal } = authUser;

  return { user, errorLogin, show2FALoginModal };
};

const mapDispatchToProps = {
  loginUserError,
  setIs2FAModalOpen,
  loginUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
