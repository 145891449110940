import { SET_CONTACT, SET_CONTACTS, SET_LOADING_CONTACTS } from '../actions';

export const setContact = contact => ({
  type: SET_CONTACT,
  payload: contact,
});

export const setContacts = contacts => ({
  type: SET_CONTACTS,
  payload: contacts,
});

export const setLoadingContacts = loading => ({
  type: SET_LOADING_CONTACTS,
  payload: loading,
});
