import { api } from './index';
import { BASE_URL_V2 } from '../static/config';

export const createSignatureRequest = payload =>
  api.post({
    path: `/signature-request`,
    body: payload,
    serverUrl: BASE_URL_V2,
  });

export const createRequestDraft = payload =>
  api.post({
    path: `/signature-request/draft`,
    body: payload,
    serverUrl: BASE_URL_V2,
  });

export const createRequestDraftPublicTemplate = payload =>
  api.post({
    path: `/signature-request/template/draft`,
    body: payload,
  });

export const createRequestDraftPublic = payload =>
  api.post({
    path: `/signature-request/draft/public`,
    body: payload,
    serverUrl: BASE_URL_V2,
  });

export const createSignatureRequestFromDraft = (payload, requestId) =>
  api.post({
    path: `/signature-request/${requestId}/from-draft`,
    body: payload,
    serverUrl: BASE_URL_V2,
  });

export const createSignatureRequestFromDraftPublic = payload =>
  api.post({
    path: `/signature-request/from-draft/public`,
    body: payload,
    serverUrl: BASE_URL_V2,
  });

export const createSignatureRequestFromTemplateLink = payload =>
  api.post({
    path: `/signature-request/template/link`,
    body: payload,
  });

export const createSignatureRequestFromTemplateLinkPublic = payload =>
  api.post({
    path: `/signature-request/template/link/public`,
    body: payload,
  });

export const createSignatureReRequest = (payload, requestId) =>
  api.post({
    path: `/signature-request/${requestId}/re-request`,
    body: payload,
  });

export const createSignatureReRequestPublic = (payload, requestId) =>
  api.post({
    path: `/signature-request/${requestId}/re-request/public`,
    body: payload,
  });

export const getSignatureRequest = requestId =>
  api.get({
    path: `/signature-request/${requestId}`,
  });

export const getSignatureRequestByToken = sigToken =>
  api.get({
    path: `/signature-request/public?signatureToken=${sigToken}`,
  });

export const getSignFieldsByToken = sigToken =>
  api.get({
    path: `/signature-request/fields/public?signatureToken=${sigToken}`,
  });

export const fillFieldsByToken = payload =>
  api.post({
    path: '/signature-request/fields/public',
    body: payload,
  });

export const voidByFileId = payload =>
  api.post({
    path: '/signature-request/void',
    body: payload,
  });

export const voidByToken = payload =>
  api.post({
    path: '/signature-request/void/public',
    body: payload,
  });

export const getSignatureImages = type =>
  api.get({
    path: `/signature-request/image?type=${type}`,
  });

export const uploadSignatureImage = payload =>
  api.post({
    path: '/signature-request/image/upload',
    body: payload,
  });

export const deleteSignatureImage = sigId =>
  api.delete({
    path: `/signature-request/image/${sigId}`,
  });

export const makeDefaultSignatureImage = sigId =>
  api.post({
    path: `/signature-request/image/${sigId}/default`,
  });

export const getSignatureRequestHistory = fileId =>
  api.get({
    path: `/signature-request/file/${fileId}/history`,
  });

export const getSignatureRequestHistoryPublic = signatureToken =>
  api.get({
    path: `/signature-request/history/public?signatureToken=${signatureToken}`,
  });

export const remindPendingSigners = (fileId, users) =>
  api.post({
    path: `/signature-request/file/${fileId}/remind`,
    body: {
      emailsToRemind: users,
    },
  });

export const getFreeSignatureStatusExceeded = email => {
  return api.get({
    path: `/signature-request/email/is-exceeded?email=${email}`,
  });
};

export const getFreeSignatureStatusV2 = () => {
  return api.get({
    path: `/signature-request/free-statistics`,
  });
};

export const getSignatureRequestFields = requestId =>
  api.get({
    path: `/signature-request/${requestId}/fields`,
  });

export const createRequestFromTemplate = payload =>
  api.post({
    path: `/signature-request/template`,
    body: payload,
  });

export const prepareSigners = (sigId, payload) =>
  api.post({
    path: `/signature-request/${sigId}/re-request/prepare-signers`,
    body: payload,
  });

export const prepareSignersPublic = (sigId, payload) =>
  api.post({
    path: `/signature-request/${sigId}/re-request/prepare-signers/public`,
    body: payload,
  });

export const selfSign = payload =>
  api.post({
    path: `/signature-request/self-sign`,
    body: payload,
  });

export const selfSignPublic = payload =>
  api.post({
    path: `/signature-request/self-sign/public`,
    body: payload,
  });

export const reassignRecipient = (fileId, payload) =>
  api.post({
    path: `/signature-request/file/${fileId}/reassign-signer`,
    body: payload,
  });

export const deleteSigner = (fileId, payload) =>
  api.post({
    path: `/signature-request/file/${fileId}/delete-signer`,
    body: payload,
  });

export const getSignFieldsTemplates = requestId =>
  api.get({
    path: `/signature-request/${requestId}/fields/template`,
  });

export const saveAsDraft = payload =>
  api.put({
    path: `/signature-request/draft/public`,
    body: payload,
  });

export const getSignatureRequestDraftDetail = draftRequestId =>
  api.get({
    path: `/signature-request/draft/${draftRequestId}`,
  });

export const getFormsFromLastSession = fileId =>
  api.get({
    path: `/editor/file/${fileId}/forms`,
  });

export const getPremiumLimits = fileId =>
  api.get({
    path: `/signature-request/limits`,
  });
