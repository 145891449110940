import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setAreYouSignerOnly,
  setIsMultipleSigners,
  setAreYouSigner,
} from '../../redux/createTemplate/actions';

import Modal from '../custom/Modal';
import RadioGroup from '../custom/RadioGroup';

const whoSignOptions = [
  { value: 'ONLY_ONE', label: '1 signer' },
  { value: 'MULTIPLE', label: '2+ signers' },
];

const AskSignersAmountStep = props => {
  function whoSignHandler(event) {
    props.setAreYouSignerOnly(event.value === 'ONLY_ONE');
    props.setIsMultipleSigners(event.value === 'MULTIPLE');
    // When change signers' amount, set I am a signer as default
    props.setAreYouSigner(true);
  }

  function goToNextStep() {
    if (props.areYouSigner !== null) {
      props.goToNextStepEmitter();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, []);

  const onEnterKeyDownHandler = e => {
    if (e.keyCode === 13) {
      props.setAreYouSigner(true);
      goToNextStep();
    }
  };

  return (
    // <Modal
    //   toggle={props.closeModalEmitter}
    //   disableBackdropClick={true}
    //   freezeBodyScroll={false}
    //   className="without-animation"
    // >
    <div className={`esign-modal signing-step-modal ${props.animationState}`}>
      <h3 className="esign-modal__title">How many signers?</h3>
      <RadioGroup
        name="who-is-signing"
        onChange={whoSignHandler}
        options={whoSignOptions}
        defaultChecked={props.isMultipleSigners ? 'MULTIPLE' : 'ONLY_ONE'}
      />
      <div className="esign-modal__footer">
        {props.showBackButton && (
          <button
            className="esign-button esign-button--light"
            onClick={props.goToPrevStepEmitter}
          >
            Back
          </button>
        )}
        <button
          className="esign-button esign-button--blue"
          onClick={() => {
            goToNextStep();
          }}
        >
          Next
        </button>
      </div>
    </div>
    // </Modal>
  );
};

AskSignersAmountStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  goToPrevStepEmitter: PropTypes.func,
};

AskSignersAmountStep.defaultProps = {
  showBackButton: false,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const { areYouSignerOnly, isMultipleSigners } = createTemplate;

  return { user, areYouSignerOnly, isMultipleSigners };
};

const mapDispatchToProps = {
  setAreYouSignerOnly,
  setIsMultipleSigners,
  setAreYouSigner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskSignersAmountStep);
