import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useClickAway from '../../../hook/useClickAway';
import { ReactComponent as ErrorIcon } from '../../../assets/img/exclamation-mark.svg';
import './style.scss';

const ContactInput = props => {
  const {
    wrapperClassName,
    value,
    label,
    error,
    errorMessage,
    placeholder,
    type,
    autocomplete,
    options,
    selected,
    onSelected,
    valid,
    ...rest
  } = props;
  const [isEmpty, setIsEmpty] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const contactInputRef = useRef(null);

  useClickAway(contactInputRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setIsEmpty(value === '');
    if (value.length > 0) {
      const activeValue = options.find(f => f.value === selected)?.name;
      if (value !== activeValue) {
        setIsOpen(true);
      } else if (selected) {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  }, [value, selected]);

  useEffect(() => {
    if (selected) {
      setIsOpen(false);
    }
  }, [selected]);

  const valueSearch = value.toLowerCase().replace(/\s/g, '');
  const filteredOption = options.filter(
    f =>
      f.name
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(valueSearch) ||
      f.email.replace(/\s/g, '').includes(valueSearch),
  );

  return (
    <div ref={contactInputRef} className={`esign-contact-input`}>
      <div
        className={`esign-input-wrapper ${wrapperClassName} ${
          valid ? `esign-input-wrapper--valid` : ``
        } ${error ? 'esign-input-wrapper--error' : ''} ${
          !isEmpty ? 'esign-input-wrapper--focused' : ''
        }`}
      >
        {label && <span className="esign-input__label">{label}</span>}
        {error && errorMessage && (
          <span className="esign-input__error-msg">
            {errorMessage ? (
              <>
                <ErrorIcon />
                {errorMessage}
              </>
            ) : (
              errorMessage
            )}
          </span>
        )}
        <input
          {...rest}
          value={value}
          type={'text'}
          placeholder={placeholder}
          required
        />
        <span className="esign-input__placeholder">{placeholder}</span>
      </div>
      {isOpen && autocomplete && (
        <>
          {filteredOption.length === 0 ? (
            <></>
          ) : (
            <ul className="esign-contact-input-autocomplete">
              {filteredOption.map(op => {
                return (
                  <li
                    onClick={() => {
                      onSelected(op.value);
                    }}
                    className={op.value === selected ? 'active' : ''}
                  >
                    <div className="label">
                      {op.name && (
                        <span className="esign-contact-input__label">
                          {op.name}
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

ContactInput.propTypes = {
  wrapperClassName: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  selected: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

ContactInput.defaultProps = {
  wrapperClassName: '',
  error: false,
  errorMessage: null,
  placeholder: '',
  selected: null,
  options: [],
};

export default ContactInput;
