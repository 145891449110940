import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './style.scss';

const RadioGroup = props => {
  const [checkedOption, setCheckedOption] = useState(props.defaultChecked);
  return (
    <fieldset id={props.name} className="esign-radio-group">
      {props.options.map((option, ind) => (
        <label
          className={cn('esign-radio-button', {
            checked_option: checkedOption === option.value,
          })}
          key={option.toString() + ind}
        >
          <input
            defaultChecked={props.defaultChecked === option.value}
            type="radio"
            value={option.value}
            className="esign-radio-button__input"
            name={props.name}
            onChange={() => {
              props.onChange(option);
              setCheckedOption(option.value);
            }}
          />
          <span className="esign-radio-button__check-mark" />
          <div className="esign-radio-button__label">{option.label}</div>
        </label>
      ))}
    </fieldset>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    }),
  ),
  defaultChecked: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

RadioGroup.defaultProps = {
  options: [],
  defaultChecked: '',
};

export default RadioGroup;
