import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setSigners,
  setSenderEmail,
  setSenderFullName,
  setAreYouSigner,
} from '../../redux/createTemplate/actions';

import {
  useSigners,
  getNotEmptyUniqueSigners,
  getSignersValidationStatus,
} from './helpers';

import {
  getFreeSignatureStatus,
  getFreeSignatureStatusByEmail,
} from '../../api/pandadoc';

import Modal from '../custom/Modal';
import SignersForm from '../SignersForm';
import NotificationManager from '../Notifications/NotificationManager';
import SigningLimitReachedModal from '../../components/Modals/SigningLimitReachedModal';
import GlobalLoader from '../GlobalLoader';

const AddSignersStep = props => {
  let initialSigners = [];
  const [
    showSigningLimitReachedModal,
    setShowSigningLimitReachedModal,
  ] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  if (props.signers.length > 0) {
    initialSigners = props.signers;
  } else {
    if (props.requestType !== 'notarize') {
      initialSigners.push({
        email: '',
        fullName: '',
        isEmailValid: false,
        isNameValid: false,
        isChanged: false,
      });
    }

    if (props.areYouSigner && props.user) {
      initialSigners.unshift({
        email: props.user.email,
        fullName: props.user.fullName,
        isEmailValid: true,
        isNameValid: true,
        isChanged: false,
        isDisabled: true,
      });
    } else {
      initialSigners.push({
        email: '',
        fullName: '',
        isEmailValid: false,
        isNameValid: false,
        isChanged: false,
      });
    }
  }

  const {
    signers,
    addSigner,
    removeSigner,
    setSignerFullName,
    setSignerEmail,
  } = useSigners(initialSigners);

  function isGoToNextStepDisable() {
    const {
      hasInvalidSigner,
      notEmptySignersAmount,
    } = getSignersValidationStatus(signers);

    if (notEmptySignersAmount < 1) {
      NotificationManager.error('Add signer', '', 3000, null, null, 'filled');
      return true;
    }

    if (notEmptySignersAmount < 2 && props.requestType !== 'notarize') {
      NotificationManager.error(
        'Add at least one additional signer',
        '',
        3000,
        null,
        null,
        'filled',
      );
      return true;
    }

    function userExistInSignersList(email) {
      const signerIndex = signers.findIndex(
        s => s.email === email && email !== '',
      );
      if (signerIndex !== -1) {
        NotificationManager.error(
          <>
            You can’t add your info as a signer because
            <br />
            you chose "I am not signer" option on the previous window
          </>,
          '',
          4000,
          null,
          null,
          'filled',
        );
        return true;
      }
    }

    // prevent add sign-in user to signers if 'I am not signer' option is selected
    if (!props.areYouSigner && props.user?.email) {
      const exist = userExistInSignersList(props.user.email);
      if (exist) return exist;
    }

    // prevent add sign-out user to signers if 'I am not signer'option is selected
    // and receive copy option is selected
    if (!props.areYouSigner && props.user === null) {
      const exist = userExistInSignersList(props.senderEmail);
      if (exist) return exist;
    }

    return hasInvalidSigner;
  }

  const [allowFormValidation, setAllowFormValidation] = useState(false);

  function goToNextStep() {
    setAllowFormValidation(true);

    let counts = {};
    signers
      .map(item => item.email)
      .forEach(item => {
        if (counts[item] === undefined) {
          counts[item] = 1;
        } else {
          counts[item] += 1;
        }
      });

    if (Object.values(counts).includes(2)) {
      NotificationManager.error(
        'Please assign only one signer per one email address',
        '',
        4000,
        null,
        null,
        'filled',
      );
    } else {
      if (!isGoToNextStepDisable()) {
        const signersList = [...signers];

        props.setSigners(getNotEmptyUniqueSigners(signersList));

        setShowLoader(true);

        if (props.user) {
          getFreeSignatureStatus().then(res => {
            if (res.exceeded) {
              setShowSigningLimitReachedModal(true);
            } else {
              if (props.areYouSigner) {
                setSenderEmail(props.user.email);
                setSenderFullName(props.user.fullName);

                props.goToNextStepEmitter(signersList);
              } else {
                props.goToNextStepEmitter(signersList);
              }
            }

            setShowLoader(false);
          });
        } else {
          getFreeSignatureStatusByEmail(
            props.senderEmail ? props.senderEmail : signersList[0].email,
          ).then(res => {
            if (res.limitExceeded) {
              setShowSigningLimitReachedModal(true);
            } else {
              if (props.areYouSigner) {
                setSenderEmail(props?.senderEmail || signersList[0]?.email);
                setSenderFullName(
                  props?.senderFullName || signersList[0]?.email,
                );

                props.goToNextStepEmitter(signersList);
              } else {
                props.goToNextStepEmitter(signersList);
              }
            }

            setShowLoader(false);
          });
        }
      }
    }
  }

  return (
    <>
      {showLoader && <GlobalLoader />}

      <div className={`esign-modal signing-step-modal ${props.animationState}`}>
        <h3 className="esign-modal__title">Add signers</h3>
        <SignersForm
          signers={signers}
          addSigner={addSigner}
          removeSigner={removeSigner}
          onFullNameChange={setSignerFullName}
          onEmailChange={setSignerEmail}
          onEnterKeyPress={goToNextStep}
          showErrorMessage={allowFormValidation}
        />
        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={() => {
              props.goToPrevStepEmitter();
            }}
          >
            Back
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={goToNextStep}
          >
            Next
          </button>
        </div>
      </div>

      {showSigningLimitReachedModal && (
        <SigningLimitReachedModal
          emitCloseModal={() => setShowSigningLimitReachedModal(false)}
        />
      )}
    </>
  );
};

AddSignersStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const {
    requestType,
    signers,
    areYouSigner,
    senderEmail,
    receiveCopy,
  } = createTemplate;
  return { user, requestType, signers, areYouSigner, senderEmail, receiveCopy };
};

const mapDispatchToProps = {
  setSigners,
  setSenderEmail,
  setSenderFullName,
  setAreYouSigner,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSignersStep);
