import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { isIosDevice, isMobileBrowser, isMobileSafari } from '../../utils';

import { setTemplateType } from '../../redux/pandaDoc/actions';

import { getFileById, getFilePreviewInfo } from '../../api/file';

import {
  getIframeUrl,
  getIframeUrlByFileId,
  getIframeUrlPublic,
  checkSignTemplateStatus,
  checkSignTemplateStatusPublic,
} from '../../api/pandadoc';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';

import Modal from '../../components/custom/Modal';
import GlobalLoader from '../../components/GlobalLoader';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { NotificationManager } from '../../components/Notifications';

import './style.scss';

class PandaDocIframe extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.timeout = null;
    this.state = {
      isDocumentCompleted: false,
      showCloseButton: false,
      templateUrl: null,
      showConfirmationModal: false,
      showLoader: false,
    };

    this.getIframeUrlMethods = {
      GET_IFRAME_URL_BY_FILE_ID: {
        name: 'GET_IFRAME_URL_BY_FILE_ID',
        getIframeFn: this.getIframeUrlByFileId,
      },
      GET_IFRAME_URL_BY_TEMPLATE_ID: {
        name: 'GET_IFRAME_URL_BY_TEMPLATE_ID',
        getIframeFn: this.getIframeUrlByTemplateId,
      },
      GET_IFRAME_URL_BY_PUBLIC_TOKEN: {
        name: 'GET_IFRAME_URL_BY_PUBLIC_TOKEN',
        getIframeFn: this.getIframeUrlByPublicToken,
      },
    };
  }

  componentDidMount() {
    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[eventMethod](
      messageEvent,
      function(e) {
        if (e && e.data) {
          try {
            const message = JSON.parse(e.data);
            const { event, data } = message;
            if (message && event) {
              if (event === 'session_view.document.loaded') {
                this.setState({ showCloseButton: true });
              }
              if (event === 'session_view.document.completed' && data.uuid) {
                this.setState({ isDocumentCompleted: true });
              }
            }
          } catch (e) {}
        }
      }.bind(this),
    );

    this.getIframeUrlByTemplateType();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getIframeUrlByTemplateType = () => {
    const { type, iframeUrlAccessKey } = this.props.templateType;
    this.getIframeUrlMethods[type].getIframeFn(iframeUrlAccessKey);
  };

  getIframeUrlByFileId = id => {
    getIframeUrlByFileId(id).then(res => {
      if (res?.url) {
        this.setState({ templateUrl: res.url });
      } else {
        if (res?.message && res?.code !== 403) {
          NotificationManager.error(
            res?.message,
            '',
            3000,
            null,
            null,
            'filled',
          );

          this.closePandaDocIframe();
        } else {
          NotificationManager.info(
            res?.message ||
              'Your document is still processing. Please, try again later.',
            '',
            3000,
            null,
            null,
            'filled',
          );

          this.closePandaDocIframe();
        }
      }
    });
  };

  getIframeUrlByTemplateId = id => {
    getIframeUrl(id).then(res => {
      if (res?.url) {
        this.setState({ templateUrl: res.url });
      } else {
        this.closePandaDocIframe();

        NotificationManager.info(
          res?.message ||
            'Your document is still processing, please wait some time, we will notify you by email',
          '',
          5000,
          null,
          null,
          'filled',
        );
      }
    });
  };

  getIframeUrlByPublicToken = token => {
    getIframeUrlPublic(token).then(res => {
      if (res?.url) {
        this.setState({ templateUrl: res.url });
      } else {
        if (res?.code === 400) {
          this.setState({ showRequestHasBeenVoidModal: true });
        } else {
          this.closePandaDocIframe();

          NotificationManager.info(
            res?.message ||
              'Your document is still processing, please wait some time, we will notify you by email',
            '',
            5000,
            null,
            null,
            'filled',
          );
        }
      }
    });
  };

  closePandaDocIframe = () => {
    // const {type} = this.props.templateType;
    // const {name} = this.getIframeUrlMethods.GET_IFRAME_URL_BY_PUBLIC_TOKEN;

    // if(type === name) {
    //   this.props.history.push('/')
    // }

    this.props.onCloseModal();
    this.props.setTemplateType(null);
  };

  onClosePandaDocHandler = () => {
    if (!this.state.isDocumentCompleted) {
      this.setState({ showConfirmationModal: true });
    } else {
      this.setState({
        showLoader: true,
      });

      if (
        this.props.user &&
        this.props.templateType.type !== 'GET_IFRAME_URL_BY_PUBLIC_TOKEN'
      ) {
        this.timer = setInterval(() => {
          checkSignTemplateStatus(
            this.props.templateType.fileId ||
              this.props.templateType.iframeUrlAccessKey,
          ).then(res => {
            if (res?.completed) {
              setTimeout(() => {
                this.setState({
                  showLoader: false,
                });

                this.props.history.push(
                  `/after-signing?fileId=${
                    this.props.templateType.fileId
                  }&type=${
                    res.waitForOthers
                      ? 'SIGN_SIGNED_AND_AWAITING_OTHERS'
                      : 'SIGN_COMPLETED_SELF_SIGNING'
                  }&pandaDoc=true`,
                );

                this.closePandaDocIframe();
              }, 500);

              clearInterval(this.timer);
            }
          });
        }, 1250);
      } else {
        this.timer = setInterval(() => {
          checkSignTemplateStatusPublic(
            this.props.templateToken ||
              this.props.templateType.iframeUrlAccessKey,
          ).then(res => {
            if (res?.completed) {
              setTimeout(() => {
                this.setState({
                  showLoader: false,
                });

                this.props.history.push(
                  `/after-signing?fileId=${this.props.templateType.fileId ||
                    this.props.templateType.iframeUrlAccessKey}${
                    this.props.templateToken ||
                    this.props.templateType.iframeUrlAccessKey
                      ? `&signatureToken=${this.props.templateToken ||
                          this.props.templateType.iframeUrlAccessKey}`
                      : ''
                  }&type=${
                    res.waitForOthers
                      ? 'SIGN_SIGNED_AND_AWAITING_OTHERS'
                      : 'SIGN_COMPLETED_SELF_SIGNING'
                  }&pandaDoc=true`,
                );

                this.closePandaDocIframe();
              }, 500);

              clearInterval(this.timer);
            }
          });
        }, 1250);
      }
    }
  };

  render() {
    if (!this.state.templateUrl || this.state.showLoader) {
      return <GlobalLoader />;
    }

    if (this.state.showRequestHasBeenVoidModal) {
      return (
        <ConfirmationModal
          title="Sorry, this signature request has been void by the creator."
          showCancelButton={false}
          submitButtonTitle="Ok"
          toggleModal={() => {
            this.setState({ showRequestHasBeenVoidModal: false });
            this.closePandaDocIframe();
          }}
          submitAction={() => {
            this.setState({ showRequestHasBeenVoidModal: false });
            this.closePandaDocIframe();
          }}
        />
      );
    }

    if (this.state.templateUrl) {
      return (
        <>
          <Modal
            className="no-scroll-modal panda-doc-iframe"
            toggle={this.onClosePandaDocHandler}
          >
            {this.state.showCloseButton && (
              <div
                className="panda-doc-iframe__close-btn--background"
                onClick={e => e.stopPropagation()}
              >
                <button
                  className="panda-doc-iframe__close-btn--btn"
                  onClick={this.onClosePandaDocHandler}
                >
                  {isMobileBrowser() && isIosDevice()}
                  <CloseIcon />
                </button>
              </div>
            )}
            <iframe
              width="100%"
              height={isMobileBrowser() ? '90%' : '100%'}
              src={this.state.templateUrl}
              // frameBorder="0"
              // allowfullscreen={true}
            />
          </Modal>

          {this.state.showConfirmationModal && (
            <ConfirmationModal
              title="Are you sure you want to close the popup?"
              toggleModal={() =>
                this.setState({ showConfirmationModal: false })
              }
              cancelAction={() =>
                this.setState({ showConfirmationModal: false })
              }
              submitAction={() => this.closePandaDocIframe()}
            />
          )}
        </>
      );
    }
  }
}

PandaDocIframe.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  templateType: PropTypes.shape({
    type: PropTypes.oneOf([
      'GET_IFRAME_URL_BY_FILE_ID',
      'GET_IFRAME_URL_BY_TEMPLATE_ID',
      'GET_IFRAME_URL_BY_PUBLIC_TOKEN',
    ]),
    iframeUrlAccessKey: PropTypes.string,
  }),
};

const mapStateToProps = ({ pandaDoc, authUser }) => {
  const { templateType, templateToken } = pandaDoc;
  const { user } = authUser;
  return { templateType, templateToken, user };
};

const mapDispatchToProps = {
  setTemplateType,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PandaDocIframe),
);
