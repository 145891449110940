import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Loader = props => {
  const { size } = props;

  return (
    <div
      style={{ width: `${+size + 20}px`, height: `${+size + 20}px` }}
      className="esign-loader"
    >
      <div style={{ width: `${size}px`, height: `${size}px` }}></div>
      <div style={{ width: `${size}px`, height: `${size}px` }}></div>
      <div style={{ width: `${size}px`, height: `${size}px` }}></div>
      <div style={{ width: `${size}px`, height: `${size}px` }}></div>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
};

Loader.defaultProps = {
  size: '20px',
};

export default Loader;
