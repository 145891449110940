import React, { useEffect } from 'react';
import { Portal } from 'react-portal';

import Modal from '../custom/Modal';
import Loader from '../Loader';

import './style.scss';

const GlobalLoader = () => {
  useEffect(() => {
    document.body.classList.add('body-stop-scrolling');

    return () => {
      document.body.classList.remove('body-stop-scrolling');
    };
  }, []);

  return (
    <Portal node={document && document.getElementById('root')}>
      <div className="global-loader">
        <Loader size={'65'} />
      </div>
    </Portal>
  );
};

export default GlobalLoader;
