import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setFileId,
  setSigners,
  restoreSigners,
  setUploadedFiles,
  setReceiveCopy,
  setAreYouSigner,
  setAreYouSignerOnly,
  setIsMultipleSigners,
  setIsImmediateTemplate,
} from '../../redux/createTemplate/actions';

import Modal from '../custom/Modal';
import AskSignersAmountStep from './AskSignersAmountStep';
import AskAreYouSignerStep from './AskAreYouSignerStep';
import AddOneSignerStep from './AddOneSignerStep';
import AddSignersStep from './AddSignersStep';
import VrificationEmailStep from './VrificationEmailStep';

import './style.scss';
import { isFileConverted, isFileConvertedTemporary } from '../../api/file';

const signType = {
  IMMEDIATE: 'IMMEDIATE',
  BASIC: 'BASIC',
  EXTERNAL_FILE: 'EXTERNAL_FILE',
};

const steps = {
  SIGNER_AMOUNT: 'SIGNER_AMOUNT',
  ARE_YOU_SIGNER: 'ARE_YOU_SIGNER',
  VERIFICATION_EMAIL: 'VERIFICATION_EMAIL',
  ADD_ONE_SIGNER: 'ADD_ONE_SIGNER',
  ADD_SIGNERS: 'ADD_SIGNERS',
};

export const animationStateEnumeration = {
  STATIC: 'STATIC',
  RIGHT_TO_LEFT: 'RIGHT_TO_LEFT',
  LEFT_TO_RIGHT: 'LEFT_TO_RIGHT',
};

const SigningProcess = props => {
  useEffect(() => {
    document.body.classList.add('body-stop-scrolling');
    return () => {
      document.body.classList.remove('body-stop-scrolling');
    };
  }, []);

  const [step, setStep] = useState(props.initStep);
  const [animationState, setAnimationState] = useState(
    animationStateEnumeration.STATIC,
  );

  function areYouSignerNextStepHandler() {
    const { areYouSigner, areYouSignerOnly, isMultipleSigners, user } = props;

    if (user) {
      if (props.isMultipleSigners) {
        setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT);
        setStep(steps.ADD_SIGNERS);
      } else {
        if (areYouSignerOnly) {
          props.setSigners([
            {
              email: user.email,
              fullName: user.fullName,
            },
          ]);

          goToSignTemplate([
            {
              email: user.email,
              fullName: user.fullName,
            },
          ]);
        } else {
          setStep(steps.ADD_ONE_SIGNER);
        }
      }
    } else {
      setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT);
      if (areYouSignerOnly) {
        setStep(steps.ADD_ONE_SIGNER);
      } else if (isMultipleSigners) {
        if (areYouSigner) {
          setStep(steps.ADD_SIGNERS);
        } else {
          setStep(steps.VERIFICATION_EMAIL);
        }
      } else {
        if (areYouSigner) {
          setStep(steps.ADD_ONE_SIGNER);
        } else {
          setStep(steps.VERIFICATION_EMAIL);
        }
      }
    }
  }

  function verificationCodeNextStepHandler() {
    if (props.isMultipleSigners) {
      setStep(steps.ADD_SIGNERS);
    } else {
      setStep(steps.ADD_ONE_SIGNER);
    }
  }

  function goToSignTemplate(signers) {
    if (props.requestType === 'notarize') {
      props.notarizeFileEmitter(signers);

      setStep('SIGNER_AMOUNT');
      props.closeModalEmitter();
    } else {
      if (props.signType === signType.BASIC) {
        props.setIsImmediateTemplate(false);

        setTimeout(() => {
          props.openSignTemplateEmitter();
        }, 500);
      }

      if (props.signType === signType.IMMEDIATE) {
        props.setIsImmediateTemplate(true);

        setTimeout(() => {
          props.setFileId(props.fileForSign._id);
        }, 500);
      }

      if (props.signType === signType.EXTERNAL_FILE) {
        props.setIsImmediateTemplate(false);

        setTimeout(() => {
          props.setFileId(props.fileForSign._id);
        }, 500);
      }

      setTimeout(() => {
        setStep('SIGNER_AMOUNT');
        props.closeModalEmitter();
      }, 2000);
    }
  }

  function closeModalHandler() {
    props.setUploadedFiles([]);
    props.restoreSigners();

    props.setIsMultipleSigners(false);
    props.setAreYouSigner(true);
    props.setAreYouSignerOnly(true);
    props.setReceiveCopy(true);

    props.closeModalEmitter();
  }

  return (
    <Modal
      toggle={() => closeModalHandler()}
      disableBackdropClick={true}
      freezeBodyScroll={false}
      className="without-animation"
    >
      {step === steps.SIGNER_AMOUNT && (
        <AskSignersAmountStep
          animationState={animationState}
          showBackButton={props.signType === signType.IMMEDIATE}
          goToPrevStepEmitter={() => (
            setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
            props.goToPrevStepEmitter()
          )}
          goToNextStepEmitter={() => (
            setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT),
            setStep(steps.ARE_YOU_SIGNER)
          )}
        />
      )}
      {step === steps.ARE_YOU_SIGNER && (
        <AskAreYouSignerStep
          animationState={animationState}
          goToPrevStepEmitter={() => (
            setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
            setStep(steps.SIGNER_AMOUNT)
          )}
          goToNextStepEmitter={() => areYouSignerNextStepHandler()}
        />
      )}
      {step === steps.VERIFICATION_EMAIL && (
        <VrificationEmailStep
          animationState={animationState}
          goToPrevStepEmitter={() => (
            setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
            setStep(steps.ARE_YOU_SIGNER)
          )}
          goToNextStepEmitter={() => (
            setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT),
            verificationCodeNextStepHandler()
          )}
        />
      )}
      {step === steps.ADD_ONE_SIGNER && (
        <AddOneSignerStep
          animationState={animationState}
          goToPrevStepEmitter={() => (
            setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
            setStep(steps.ARE_YOU_SIGNER)
          )}
          goToNextStepEmitter={signers => (
            setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT),
            goToSignTemplate(signers)
          )}
        />
      )}
      {step === steps.ADD_SIGNERS && (
        <AddSignersStep
          animationState={animationState}
          goToPrevStepEmitter={() => (
            setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
            props.requestType === 'notarize'
              ? closeModalHandler()
              : setStep(steps.ARE_YOU_SIGNER)
          )}
          goToNextStepEmitter={signers => (
            setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT),
            goToSignTemplate(signers)
          )}
        />
      )}
    </Modal>
  );
};

function fileForSignPropsValidator(props, propName, componentName) {
  if (props['signType'] === 'IMMEDIATE' && props[propName] === undefined) {
    return new Error(
      `Provide a ${propName} for ${componentName} component with prop signType=${props['signType']}`,
    );
  }
  // Need call PropTypes.checkPropTypes to save condition validation write above
  // and save object validation
  PropTypes.checkPropTypes(
    {
      [propName]: PropTypes.shape({
        id: PropTypes.string,
      }),
    },
    props,
    propName,
    componentName,
  );
}

function openSignTemplateEmitterPropsValidator(props, propName, componentName) {
  if (
    props['signType'] === 'BASIC' &&
    (props[propName] === undefined || typeof props[propName] !== 'function')
  ) {
    return new Error(
      `Provide a ${propName} function for ${componentName} component with prop signType=${props['signType']}`,
    );
  }
}

SigningProcess.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func,
  signType: PropTypes.oneOf(Object.keys(signType)).isRequired,
  initStep: PropTypes.oneOf(Object.keys(steps)),
  fileForSign: fileForSignPropsValidator,
  openSignTemplateEmitter: openSignTemplateEmitterPropsValidator,
};

SigningProcess.defaultProps = {
  initStep: steps.SIGNER_AMOUNT,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const {
    requestType,
    areYouSigner,
    areYouSignerOnly,
    isMultipleSigners,
    signers,
  } = createTemplate;

  return {
    user,
    requestType,
    areYouSigner,
    areYouSignerOnly,
    isMultipleSigners,
    signers,
  };
};

const mapDispatchToProps = {
  setFileId,
  setIsImmediateTemplate,
  setUploadedFiles,
  setSigners,
  restoreSigners,
  setAreYouSignerOnly,
  setIsMultipleSigners,
  setAreYouSigner,
  setReceiveCopy,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigningProcess);
