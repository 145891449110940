import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const RadioButton = ({
  disabled,
  name,
  checked,
  onChange,
  label,
  ...props
}) => {
  return (
    <div className="esign-radio-group">
      <label
        className={`esign-radio-button ${
          disabled ? 'esign-radio-button--disabled' : ''
        }`}
      >
        <input
          name={name}
          type="checkbox"
          checked={checked}
          className="esign-radio-button__input"
          onChange={event => onChange(name, event)}
          {...props}
        />
        <span className="esign-radio-button__check-mark" />
        {label && <span className="esign-radio-button__label">{label}</span>}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default RadioButton;
