import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useFormInput } from './helpers';
import { validateEmail, validateFullName } from '../../utils';

import {
  setSigners,
  setSenderEmail,
  setSenderFullName,
  setAreYouSigner,
} from '../../redux/createTemplate/actions';

import {
  getFreeSignatureStatus,
  getFreeSignatureStatusByEmail,
} from '../../api/pandadoc';

import Modal from '../custom/Modal';
import CustomInput from '../custom/CustomInput';
import SigningLimitReachedModal from '../../components/Modals/SigningLimitReachedModal';
import GlobalLoader from '../GlobalLoader';

const AddOneSignerStep = props => {
  const userFullNameController = useFormInput(
    '',
    value => value.trim() === '' || !validateFullName(value),
  );
  const userEmailController = useFormInput('', value => !validateEmail(value));

  const [allowFormValidation, setAllowFormValidation] = useState(false);
  const [
    showSigningLimitReachedModal,
    setShowSigningLimitReachedModal,
  ] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  function isGoToNextStepDisable() {
    return (
      userFullNameController.isInvalid ||
      userFullNameController.value.length > 50 ||
      userEmailController.isInvalid
    );
  }

  function goToNextStep() {
    setAllowFormValidation(true);

    if (!isGoToNextStepDisable()) {
      const { value: email } = userEmailController;
      const { value: fullName } = userFullNameController;

      props.setSigners([{ email, fullName }]);

      if (props.user) {
        if (props.areYouSignerOnly) {
          props.setSenderEmail(props.user.email);
          props.setSenderFullName(props.user.fullName);

          props.goToNextStepEmitter([{ email, fullName }]);
        } else {
          setShowLoader(true);

          getFreeSignatureStatus().then(res => {
            if (res.exceeded) {
              setShowSigningLimitReachedModal(true);
            } else {
              if (props.areYouSigner) {
                props.setSenderEmail(props.user.email);
                props.setSenderFullName(props.user.fullName);

                props.goToNextStepEmitter([{ email, fullName }]);
              } else {
                props.goToNextStepEmitter([{ email, fullName }]);
              }
            }

            setShowLoader(false);
          });
        }
      } else {
        if (props.areYouSignerOnly) {
          props.setSenderEmail(email);
          props.setSenderFullName(fullName);

          props.goToNextStepEmitter([{ email, fullName }]);
        } else {
          setShowLoader(true);

          getFreeSignatureStatusByEmail(
            props.senderEmail ? props.senderEmail : props.signers[0].email,
          ).then(res => {
            if (res.limitExceeded) {
              setShowSigningLimitReachedModal(true);
            } else {
              if (props.areYouSigner) {
                props.setSenderEmail(email);
                props.setSenderFullName(fullName);

                props.goToNextStepEmitter([{ email, fullName }]);
              } else {
                props.goToNextStepEmitter([{ email, fullName }]);
              }
            }

            setShowLoader(false);
          });
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, [userFullNameController.value, userEmailController.value]);

  const onEnterKeyDownHandler = e => {
    if (e.keyCode === 13) {
      if (!userFullNameController.isInvalid && !userEmailController.isInvalid) {
        goToNextStep();
      }
    }
  };

  return (
    <>
      {showLoader && <GlobalLoader />}

      <div className={`esign-modal signing-step-modal ${props.animationState}`}>
        <h3 className="esign-modal__title">
          {props.areYouSigner
            ? 'Enter your name and email'
            : 'Enter the signer’s name and email'}
        </h3>
        <CustomInput
          type="text"
          value={userFullNameController.value}
          placeholder={props.areYouSigner ? 'Your name' : 'Signer’s name'}
          onChange={userFullNameController.handleChange}
          error={
            allowFormValidation &&
            (userFullNameController.isInvalid ||
              userFullNameController.value.length > 50)
          }
          errorMessage={
            userFullNameController.value.length > 50
              ? 'Invalid name: The name shouldn’t be longer than 50 characters.'
              : 'Invalid name'
          }
        />
        <CustomInput
          type="email"
          value={userEmailController.value}
          placeholder={
            props.areYouSigner ? 'Your email address' : 'Signer’s email address'
          }
          onChange={userEmailController.handleChange}
          error={allowFormValidation && userEmailController.isInvalid}
          errorMessage="Invalid email"
        />
        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={() => {
              props.goToPrevStepEmitter();
            }}
          >
            Back
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={goToNextStep}
          >
            Next
          </button>
        </div>
      </div>

      {showSigningLimitReachedModal && (
        <SigningLimitReachedModal
          emitCloseModal={() => setShowSigningLimitReachedModal(false)}
        />
      )}
    </>
  );
};

AddOneSignerStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const {
    areYouSigner,
    receiveCopy,
    senderEmail,
    signers,
    areYouSignerOnly,
  } = createTemplate;

  return {
    user,
    areYouSigner,
    receiveCopy,
    senderEmail,
    signers,
    areYouSignerOnly,
  };
};

const mapDispatchToProps = {
  setSenderEmail,
  setSenderFullName,
  setSigners,
  setAreYouSigner,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOneSignerStep);
