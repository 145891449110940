import {
  TOGGLE_SIDEBAR,
  RESIZE_DEVICE_WIDTH,
  HIDE_COOKIE_NOTICE_MESSAGE,
} from '../actions';

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const resizeDeviceWidth = width => ({
  type: RESIZE_DEVICE_WIDTH,
  payload: width,
});
