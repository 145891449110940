import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../../assets/img/search.svg';
import SearchIconImg from '../../../assets/img/search_18x18.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';

import './style.scss';

const SearchInput = props => {
  const {
    wrapperClassName,
    onSearchClick,
    hasStaticWidth,
    isClearable,
    onClear,
    type,
    error,
    value,
    ...rest
  } = props;

  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  function onSearchClickHandler() {
    inputRef.current.focus();

    if (typeof onSearchClick === 'function') {
      onSearchClick();
    }
  }

  function onClearClickHandler() {
    if (typeof onClear === 'function') {
      onClear();
    }
  }

  return (
    <div
      className={`esign-search-wrapper ${wrapperClassName} ${
        type === `contact`
          ? `esign-input-contact-wrapper esign-input-wrapper ${wrapperClassName} ${
              error ? 'esign-input-wrapper--error' : ''
            } ${!(value === '') ? 'esign-input-wrapper--focused' : ''}`
          : ``
      }`}
    >
      {type === 'contact' || type === 'license' ? (
        <>
          <div
            className={`esign-input-group ${props.wrapperClassNameSearch}`}
            ref={wrapperRef}
          >
            <span className="esign-input__icon">
              <img src={SearchIconImg} alt="Search" />
            </span>
            <div>
              <input
                {...rest}
                value={props.value}
                onMouseOver={e => {
                  if (wrapperRef?.current) {
                    wrapperRef.current.classList.add('active');
                  }
                }}
                onMouseLeave={e => {
                  if (wrapperRef?.current) {
                    wrapperRef.current.classList.remove('active');
                  }
                }}
                type={'text'}
                placeholder={props.placeholder}
                required
              />
              <span className="esign-input__placeholder">
                {props.placeholder}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <input
            ref={inputRef}
            className={`esign-search__input ${
              hasStaticWidth ? 'esign-search__input--static-width' : ''
            }`}
            {...rest}
          />
          {isClearable ? (
            rest.value ? (
              <span
                className="esign-search__icon"
                onClick={onClearClickHandler}
              >
                <CloseIcon />
              </span>
            ) : (
              <span
                className="esign-search__icon"
                onClick={onSearchClickHandler}
              >
                <SearchIcon />
              </span>
            )
          ) : (
            <span className="esign-search__icon" onClick={onSearchClickHandler}>
              <SearchIcon />
            </span>
          )}
        </>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  wrapperClassName: PropTypes.string,
  onSearchClick: PropTypes.func,
  hasStaticWidth: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func,
};

SearchInput.defaultProps = {
  wrapperClassName: '',
  hasStaticWidth: true,
  isClearable: false,
};

export default SearchInput;
