import React, { useState, useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import './style.scss';
import Highlighter from 'react-highlight-words';
import cn from 'classnames';
import {
  chooseIconByExtension,
  getFileExtensionHandler,
  isMobileBrowser,
  PageDetectionMethod,
} from '../../../utils';

// Icons
import { ReactComponent as SearchIcon } from '../../../assets/img/search.svg';
import { ReactComponent as ClearIcon } from '../../../assets/img/new_icons/times-icon.svg';
import Loader from '../../../components/Loader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getContact, searchContact } from '../../../api/contact';
import Avatar from '../../../components/Avatar';
import moment from 'moment/moment';
// import 'moment-timezone';
import { setSearchText } from '../../../redux/documentController/actions';
import { searchUserFilesWithFilters } from '../../../api/folder';
import { findAllTemplate } from '../../../api/template';
import { DEFAULT_DATETIME_FORMAT } from '../../../static';

const EMPTY_RESULTS = {
  contacts: [],
  documents: [],
  count: 0,
};

const HeaderSearchBar = props => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [results, setResults] = useState({ ...EMPTY_RESULTS });
  const [hasFocus, setHasFocus] = useState(false);

  const inputRef = useRef(null);
  const disableSuggestion = useRef(false);
  const abortController = new window.AbortController();
  const { history } = props;

  let manualFocus = true;

  const setFocus = () => {
    manualFocus = false;
    inputRef?.current && inputRef.current.focus();
    manualFocus = true;
  };

  // ToDo: manually set focus when initial page loading
  // useEffect(() => {
  //   setFocus()
  // }, []);

  useEffect(() => {
    const handleDocumentClick = () => {
      // eraseResults();
      setOpenResults(false);
      setHasFocus(false);
    };

    document.addEventListener('click', handleDocumentClick);

    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  useEffect(() => {
    if (props.location.search.includes('contactId')) {
      const queryParams = new URLSearchParams(props.location.search);
      const contactId = queryParams.get('contactId');
      fetchInitialSearchValue(contactId);
    }
  }, []);

  // Reset search input when route is changed
  useEffect(() => {
    setInputValue('');
  }, [props.location.pathname]);

  // ToDo: When focus again with input string
  // useEffect(() => {
  //   if (inputValue && hasFocus) {
  //     console.log(abortController.signal)
  //     debouncedSave(inputValue);
  //   }
  //   // console.log(hasFocus);
  //   // if (results.count > 0 && !openResults) {
  //   //   setOpenResults(true);
  //   // }
  // }, [hasFocus]);

  const debouncedSave = useCallback(
    debounce(newValue => handleOnSearch(newValue), 500),
    [],
  );

  const fetchInitialSearchValue = contactId => {
    getContact(contactId).then(resp => {
      if (resp) {
        setInputValue(`${resp?.fullName} (${resp?.email})`);
      }
    });
  };

  const eraseResults = () => {
    setResults({ ...EMPTY_RESULTS });
  };

  const handleOnSearch = value => {
    if (!value) {
      onClear(null);
    } else {
      setLoading(true);
      setOpenResults(false);
      const regex = /^.* \((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})\)$/i;
      let contactSearchValue = value;
      if (regex.test(value)) {
        contactSearchValue = value
          .replace(
            / \((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})\)$/i,
            '',
          )
          .trim();
      }

      const contactsQueryParams = new URLSearchParams({
        order: 'asc',
        query: contactSearchValue,
        sortBy: 'fullName',
        perPage: 10,
        page: 1,
      }).toString();

      let typePage = 'filesystem';
      if (PageDetectionMethod.Shared()) {
        typePage = 'filesystem/shared';
      } else if (PageDetectionMethod.Starred()) {
        typePage = 'filesystem/bookmarks';
      } else if (PageDetectionMethod.Templates()) {
        typePage = 'template';
      } else if (PageDetectionMethod.Trash()) {
        typePage = 'filesystem/trashed';
      }

      let mode = '';
      if (PageDetectionMethod.Inbox()) {
        mode = 'inbox';
      } else if (PageDetectionMethod.Sent()) {
        mode = 'sent';
      }

      const getFileService = PageDetectionMethod.Templates()
        ? findAllTemplate(
            new URLSearchParams(
              {
                order: 'desc',
                perPage: 10,
                page: 1,
                query: contactSearchValue,
                sortBy: 'updatedAt',
              },
              abortController.signal,
            ),
          )
        : searchUserFilesWithFilters(
            typePage,
            contactSearchValue,
            1,
            10,
            'desc',
            'updatedAt',
            '',
            abortController.signal,
            '',
            mode,
          );

      const contactSearchService = PageDetectionMethod.Documents()
        ? searchContact(contactsQueryParams, abortController.signal)
        : null;

      Promise.all([
        contactSearchService,
        // fileSystemQuery(fileQueryParams),
        getFileService,
      ])
        .then(([contactsResp, docResp]) => {
          if (!disableSuggestion.current) {
            const searchResults = { ...EMPTY_RESULTS };
            let count = 0;

            if (docResp && docResp?.results) {
              searchResults.documents = docResp?.results;
              count += docResp?.results?.length;
            }

            if (contactsResp && contactsResp?.contacts) {
              searchResults.contacts = contactsResp?.contacts;
              count += contactsResp?.contacts?.length;
            }

            searchResults.count = count;
            setOpenResults(true);
            setResults(searchResults);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setResults({ ...EMPTY_RESULTS });
        });
    }
  };

  const handleClickListItem = (e, item) => {
    e.preventDefault();
    if (isMobileBrowser()) {
      props.onSearchBarFocus(false);
    }
    setInputValue('');
    eraseResults();
    if (!PageDetectionMethod.Trash()) {
      let baseUrl = '/documents';
      if (PageDetectionMethod.Shared()) {
        baseUrl = '/shared';
      } else if (PageDetectionMethod.Starred()) {
        baseUrl = '/starred';
      } else if (PageDetectionMethod.Templates()) {
        baseUrl = '/templates';
      }
      const id = PageDetectionMethod.Templates() ? item.id : item._id;
      if (item.type !== 'folder') {
        history.push(`${baseUrl}?preview=${id}`);
      } else {
        history.push(`${baseUrl}/${id}`);
      }
    }
  };

  const handleClickContactItem = (e, contact) => {
    e.preventDefault();
    if (isMobileBrowser()) {
      props.onSearchBarFocus(false);
    }
    eraseResults();
    history.push(`/documents?contactId=${contact.id}`);
    setInputValue(`${contact.fullName} (${contact.email})`);
  };

  const handleMouseDownResult = e => {
    e.preventDefault();
  };

  const updateValue = newValue => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  const handleFocus = e => {
    if (isMobileBrowser()) {
      props.onSearchBarFocus(true);
    }
    manualFocus && setHasFocus(true);
  };

  const handleBlur = e => {
    if (isMobileBrowser()) {
      props.onSearchBarFocus(false);
    }
    eraseResults();
  };

  const onClear = e => {
    setResults({ ...EMPTY_RESULTS });
    setInputValue('');
    disableSuggestion.current = false;
    setLoading(false);
    props.setSearchText('');
    if (props.location.search.includes('contactId')) {
      const queryParams = new URLSearchParams(props.location.search);
      if (queryParams.has('contactId')) {
        queryParams.delete('contactId');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    if (isMobileBrowser()) {
      props.onSearchBarFocus(false);
    }
    setFocus();
  };

  const onKeyDownHandler = e => {
    disableSuggestion.current = false;
    if (e.keyCode === 13) {
      // Enter
      e.preventDefault();
      disableSuggestion.current = true;
      abortController.abort('duplicate');
      setLoading(false);
      setOpenResults(false);
      setResults({ ...EMPTY_RESULTS });
      props.setSearchText(inputValue);
    }
  };

  return (
    <div className={cn('header-search-bar', { active: props.isFocused })}>
      <div className={'header-search-bar--wrapper'}>
        <div className={'header-search-bar--input'}>
          {isMobileBrowser() ? (
            inputValue ? (
              loading ? (
                <button className={'header-search-bar--search-icon-btn'}>
                  <div className={'spinner'}>
                    <Loader size={'24'} />
                  </div>
                </button>
              ) : (
                <button
                  className={'header-search-bar--search-icon-btn'}
                  onClick={onClear}
                >
                  <ClearIcon className={'clear-icon'} />
                </button>
              )
            ) : (
              <button
                className={'header-search-bar--search-icon-btn'}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <SearchIcon />
              </button>
            )
          ) : (
            <button
              className={'header-search-bar--search-icon-btn'}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <SearchIcon />
            </button>
          )}
          <input
            type="text"
            ref={inputRef}
            value={inputValue}
            onChange={input => updateValue(input.target.value)}
            placeholder={'Search'}
            {...(!isMobileBrowser() ? { autoFocus: true } : {})}
            onFocus={handleFocus}
            onKeyDown={onKeyDownHandler}
            onBlur={handleBlur}
          />
          {!isMobileBrowser() ? (
            inputValue ? (
              loading ? (
                <div className={'spinner'}>
                  <Loader size={'24'} />
                </div>
              ) : (
                <ClearIcon className={'clear-icon'} onClick={onClear} />
              )
            ) : null
          ) : null}
        </div>
        <div className={'header-search-bar--suggest-wrapper'}>
          {results?.count > 0 && !loading && openResults && (
            <>
              <div className="line" />
              <div className={'header-search-bar--suggest-container'}>
                {results?.contacts?.length > 0 ? (
                  <ul className={'header-search-bar--list'}>
                    {results?.contacts?.map((contact, index) => (
                      <li
                        key={`${contact.id}-${index}`}
                        className={'header-search-bar--list-item'}
                        onClick={e => handleClickContactItem(e, contact)}
                        onMouseDown={handleMouseDownResult}
                      >
                        <Avatar
                          className={'avatar'}
                          fullName={contact?.fullName || contact?.email}
                          bacgroundColor={contact.profileColor}
                          logoUrl={contact.logoUrl}
                          showPreviewImgByClick={true}
                        />
                        <div>
                          <Highlighter
                            className={'header-search-bar--list-item--fullname'}
                            searchWords={[inputValue]}
                            autoEscape={true}
                            textToHighlight={contact?.fullName}
                            highlightStyle={{
                              backgroundColor: 'rgba(50,132,255,0.2)',
                            }}
                          />
                          <Highlighter
                            className={'header-search-bar--list-item--email'}
                            searchWords={[inputValue]}
                            autoEscape={true}
                            textToHighlight={` (${contact?.email})`}
                            highlightStyle={{
                              backgroundColor: 'rgba(50,132,255,0.2)',
                            }}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null}
                {results?.contacts?.length > 0 &&
                  results?.documents?.length > 0 && <div className="line" />}
                {results?.documents?.length > 0 ? (
                  <ul className={'header-search-bar--list'}>
                    {results?.documents?.map((document, index) => (
                      <li
                        className={'header-search-bar--list-item'}
                        key={`${document._id}-${index}`}
                        onClick={e => handleClickListItem(e, document)}
                        onMouseDown={handleMouseDownResult}
                      >
                        {chooseIconByExtension(
                          getFileExtensionHandler(document),
                        )}
                        <div>
                          <Highlighter
                            className={'header-search-bar--list-item--doc-name'}
                            searchWords={[inputValue]}
                            autoEscape={true}
                            textToHighlight={document?.name}
                            highlightStyle={{
                              backgroundColor: 'rgba(50,132,255,0.2)',
                            }}
                          />
                          <span
                            className={
                              'header-search-bar--list-item--created-at'
                            }
                          >
                            {moment(document.updatedAt)
                              .tz(
                                props?.user?.settings?.regional?.timeZone
                                  ?.timeZone || moment.tz.guess(),
                              )
                              .format(
                                props?.user?.settings?.regional?.dateFormat ||
                                  DEFAULT_DATETIME_FORMAT,
                              )}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ documentController, authUser }) => {
  const { searchText } = documentController;
  const { user } = authUser;
  return {
    user,
    searchText,
  };
};

const mapDispatchToProps = {
  setSearchText,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderSearchBar),
);
