import { api } from './index';

/**
 * @return {Promise<{countries: Array<string>}>}
 */
export const getCountries = () => {
  return api.get({
    path: '/user/settings/country',
  });
};

/**
 * @return {Promise<{states: Array<string>}>}
 */
export const getStates = () => {
  return api.get({
    path: '/user/settings/state',
  });
};

export const getDateFormats = () => {
  return api.get({
    path: '/user/settings/date-format',
  });
};

export const getTimezones = () => {
  return api.get({
    path: '/user/settings/timezone',
  });
};

/**
 * @typedef {Object} RegionalSettingsType
 * @property {string} country
 * @property {string} dateFormat
 * @property {string} timeZone
 */

/**
 * @param {RegionalSettingsType} regionalSettings
 */
export const saveRegionalSettings = regionalSettings => {
  return api.put({
    path: '/user/settings/regional',
    body: regionalSettings,
  });
};

/**
 * @typedef {Object} BullingSettingsType
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} companyName
 * @property {string} address1
 * @property {string} address2
 * @property {string} country
 * @property {string} state
 * @property {string} city
 * @property {string} zipCode
 */

/**
 * @param {BullingSettingsType} billingSettings
 */
export const updateBillingSettings = billingSettings => {
  return api.put({
    path: '/user/settings/billing',
    body: billingSettings,
  });
};

/**
 * @return {Promise<BullingSettingsType>}
 */
export const getBillingSettings = () => {
  return api.get({
    path: '/user/settings/billing',
  });
};

export const getSyncInit = () => {
  return api.get({
    path: '/filesystem/sync',
  });
};

export const postSyncInit = (provider, accessToken, refreshToken) => {
  return api.post({
    path: '/filesystem/sync',
    body: { provider, accessToken, refreshToken },
  });
};

export const deleteSyncInit = provider => {
  return api.delete({
    path: '/filesystem/sync',
    body: { provider },
  });
};

export const getSyncInitLogin = provider => {
  return api.get({
    path: `/filesystem/sync/login?provider=${provider}`,
  });
};

export const getListExternalFolder = provider => {
  return api.get({
    path: `/filesystem/sync/${provider}/folder`,
  });
};

export const setSyncFolder = (provider, syncFolderName) => {
  return api.post({
    path: `/filesystem/sync/${provider}/folder`,
    body: { syncFolderName },
  });
};

export const updateEmail = (newEmail, password, code) => {
  return api.post({
    path: `/user/settings/email`,
    body: {
      email: newEmail,
      password,
      code,
    },
  });
};

export const updateTemplateSettings = payload => {
  return api.put({
    path: `/user/settings/template`,
    body: payload,
  });
};
