import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useFormInput } from '../SigningProcess/helpers';
import { setNewPassword } from '../../api/auth';

import CustomInput from '../custom/CustomInput';
import NotificationManager from '../Notifications/NotificationManager';

const SetNewPassword = props => {
  const history = useHistory();

  const passwordController = useFormInput('', value => value.trim().length < 6);
  const confirmedPasswordController = useFormInput('');

  function setNewPasswordHandler(e) {
    e.preventDefault();

    const urlParams = new URLSearchParams(history.location.search);

    const email = urlParams.get('email');
    const resetToken = urlParams.get('resetToken');
    const password = passwordController.value;

    setNewPassword({ email, password, resetToken }).then(res => {
      if (res && res?.message) {
        NotificationManager.error(res?.message, '', 3000, null, null, 'filled');
      } else {
        history.push('/');
        props.setNewPasswordCallback();

        NotificationManager.success(
          'Password was updated successfully.',
          '',
          4000,
          null,
          null,
          'filled',
        );
      }
    });
  }

  function isSubmitButtonDisabled() {
    const { value: newPassword, isInvalid } = passwordController;
    const { value: confirmedNewPassword } = confirmedPasswordController;

    return isInvalid || newPassword !== confirmedNewPassword;
  }

  return (
    <form className="enter-to-app__form">
      <p className="enter-to-app__text">
        *Password should be at least 6 characters
      </p>
      <CustomInput
        type="password"
        placeholder="New password"
        value={passwordController.value}
        onChange={passwordController.handleChange}
      />
      <CustomInput
        type="password"
        placeholder="Confirm password"
        value={confirmedPasswordController.value}
        onChange={confirmedPasswordController.handleChange}
      />
      <button
        type="submit"
        className="esign-button esign-button--blue"
        disabled={isSubmitButtonDisabled()}
        onClick={setNewPasswordHandler}
      >
        Set new password
      </button>
    </form>
  );
};

SetNewPassword.propTypes = {
  setNewPasswordCallback: PropTypes.func.isRequired,
};

export default SetNewPassword;
