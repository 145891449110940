import React from 'react';
import { ReactComponent as TrashIcon } from '../../assets/img/trash-filled_18x18.svg';
import { ReactComponent as StarIcon } from '../../assets/img/star-filled_18x18.svg';
import { ReactComponent as ShareIcon } from '../../assets/img/share-filled_18x18.svg';
import { ReactComponent as SentIcon } from '../../assets/img/sent-filled_18x18.svg';
import { ReactComponent as InboxIcon } from '../../assets/img/inbox-filled_18x18.svg';
import { ReactComponent as DocumentIcon } from '../../assets/img/document-filled_18x18.svg';
import { ReactComponent as TemplateIcon } from '../../assets/img/template-filled_18x18.svg';
import { ReactComponent as NoSearchResultIcon } from '../../assets/img/no-search-result_18x18.svg';

import './style.scss';
import { PAGE_NAMES } from '../../static';

const NotFound = ({ type }) => {
  const notFoundTemplate = () => {
    switch (type) {
      case PAGE_NAMES.trash:
        return (
          <div className="not-found">
            <TrashIcon width={200} height={200} className={'not-found__img'} />
            <p className="not-found__headline">No items in the trash</p>
            <p className="not-found__text">
              Deleted files and folders will appear here.
            </p>
          </div>
        );
      case PAGE_NAMES.starred:
        return (
          <div className="not-found">
            <StarIcon width={200} height={200} className={'not-found__img'} />
            <p className="not-found__headline">No starred items</p>
            <p className="not-found__text">
              Starred files and folders will appear here for easy access.
            </p>
          </div>
        );
      case PAGE_NAMES.shared:
        return (
          <div className="not-found">
            <ShareIcon
              width={200}
              height={200}
              className={'not-found__img share--icon'}
            />
            <p className="not-found__headline">No shared items</p>
            <p className="not-found__text">
              Files and folders that others share with you will appear here.
            </p>
          </div>
        );
      case PAGE_NAMES.inbox:
        return (
          <div className="not-found">
            <InboxIcon width={200} height={200} className={'not-found__img'} />
            <p className="not-found__headline">No inbox items</p>
            <p className="not-found__text">
              Sign requests received and completed forms will appear here.
            </p>
          </div>
        );
      case PAGE_NAMES.sent:
        return (
          <div className="not-found">
            <SentIcon width={200} height={200} className={'not-found__img'} />
            <p className="not-found__headline">No sent items</p>
            <p className="not-found__text">
              Sign and notarization requests you have sent to others will appear
              here.
            </p>
          </div>
        );
      case PAGE_NAMES.documents:
        return (
          <div className="not-found">
            <DocumentIcon
              width={200}
              height={200}
              className={'not-found__img'}
            />
            <p className="not-found__headline">No files</p>
            <p className="not-found__text">
              All uploaded and received files and folders will appear here.
            </p>
          </div>
        );
      case PAGE_NAMES.templates:
        return (
          <div className="not-found">
            <TemplateIcon
              width={200}
              height={200}
              className={'not-found__img'}
            />
            <p className="not-found__headline">No templates</p>
            <p className="not-found__text">
              Create reusable forms for frequently-signed documents.
            </p>
            <div className="not-found-action">
              {/* <button
                className="esign-button esign-button--light esign-button-templates-not-found"
                onClick={() => {}}
              >
                How to Use Templates
              </button> */}
            </div>
          </div>
        );
      default:
        return (
          <div className="not-found">
            <NoSearchResultIcon
              width={200}
              height={200}
              className={'not-found__img'}
            />
            <p className="not-found__headline">No results found</p>
          </div>
        );
    }
  };
  return notFoundTemplate();
};

export default NotFound;
