import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '../../../assets/img/arrow-down_10x10.svg';
import './style.scss';
import SearchInput from '../SearchInput';
import Loader from '../../Loader';
import { ReactComponent as OrderIcon } from '../../../assets/img/arrow-down_10x10.svg';
import cn from 'classnames';

const CustomContact = props => {
  const {
    wrapperClassName,
    value,
    error,
    errorMessage,
    placeholder,
    selected,
    onSelected,
    onChangeOrder,
    onChange,
    options,
    loading = false,
    activeRoles = [],
    nameSortOrder,
    ...rest
  } = props;

  const newOptions = (options || []).filter(
    op => !activeRoles.includes(op.email),
  );
  const valueSearch = value.toLowerCase().replace(/\s/g, '');
  const activeOptions =
    valueSearch.length > 0
      ? (newOptions || []).filter(
          f =>
            f.name
              .toLowerCase()
              .replace(/\s/g, '')
              .includes(valueSearch) ||
            f.email.replace(/\s/g, '').includes(valueSearch),
        )
      : newOptions;

  const onToggleSortOrder = () => {
    console.log('toggle');
    if (onChangeOrder) {
      onChangeOrder(nameSortOrder === 'desc' ? 'asc' : 'desc');
    }
  };

  return (
    <div
      className={`esign-input-contact-wrapper esign-input-wrapper ${wrapperClassName} ${
        error ? 'esign-input-wrapper--error' : ''
      } ${!(value === '') ? 'esign-input-wrapper--focused' : ''}`}
    >
      <div className="esign-input-group-wrapper">
        <SearchInput
          {...rest}
          value={value}
          wrapperClassNameSearch="esign-input-contact-list"
          placeholder={placeholder}
          type={'contact'}
          error={error}
          onChange={onChange}
        />
        {loading ? (
          <div
            className="spinner"
            style={{ position: 'relative', width: '100%' }}
          >
            <Loader size={'15'} />
          </div>
        ) : (
          <ul className="esign-input__options">
            {activeOptions.length > 0 && (
              <li className="esign-input__options-header">
                <div className="label" onClick={onToggleSortOrder}>
                  Name
                  <OrderIcon className={cn('order-arrow', nameSortOrder)} />
                </div>
                <div className="email">Email</div>
              </li>
            )}
            {activeOptions.map(op => {
              return (
                <li className={op.value === selected ? 'active' : ''}>
                  <div className="label">
                    <label className={`esign-radio-button`}>
                      <input
                        name={'frank'}
                        type="checkbox"
                        checked={op.value === selected}
                        className="esign-radio-button__input"
                        onChange={onSelected(op.value)}
                      />
                      <span className="esign-radio-button__check-mark" />
                      {op.name && (
                        <span className="esign-radio-button__label">
                          {op.name}
                        </span>
                      )}
                    </label>
                  </div>
                  <div className="email">{op.email}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

CustomContact.propTypes = {
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  selected: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

CustomContact.defaultProps = {
  wrapperClassName: '',
  placeholder: '',
  selected: null,
  options: [],
};

export default CustomContact;
