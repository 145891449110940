import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  setOrdered,
  setRoles,
  setRoleContact,
} from '../../redux/createTemplate/actions';

import { setContacts, setLoadingContacts } from '../../redux/contact/actions';

import {
  toggleShowContactModal,
  toggleNewTemplateModal,
} from '../../redux/documentController/actions';

import { getRolesValidationStatus, useRoles } from './helpers';

import { parseJwt } from '../../pages/SigningProcess/helper';
import { getTokenFromCookie } from '../../api';
import { setDataToSessionStorage } from '../../utils';

import GlobalLoader from '../GlobalLoader';
import { v4 as uuidV4 } from 'uuid';
import Switch from '../custom/Switch';
import { RECIPIENTS_TYPE } from '../../static';
import RolesForm from '../RolesForm';
import NotificationManager from '../../components/Notifications/NotificationManager';

export const ROLE_DEFAULT = {
  roleName: '',
  prefillName: '',
  prefillEmail: '',
  isPrefillEmailValid: false,
  isPrefillNameValid: false,
  isRoleNameValid: false,
  isPrefillEmailTouched: false,
  isPrefillNameTouched: false,
  isRoleNameTouched: false,
  hasPrefill: false,
  type: RECIPIENTS_TYPE.SIGNER,
  isDisabled: false,
};

const RolesEdit = props => {
  let initialSigners = [];
  const [showLoader, setShowLoader] = useState(false);
  const colors = useRef(['#89bfff', '#4aefba', '#e9927b']);
  const [showError, setShowError] = useState(false);
  const [token, setToken] = useState(getTokenFromCookie());
  const location = useLocation();
  const typeTemplate = new URLSearchParams(location.search).get('type');

  if (props.signers.length > 0) {
    initialSigners = props.signers;
  }

  if (initialSigners.length === 0) {
    initialSigners.push({
      role: uuidV4(),
      ...ROLE_DEFAULT,
    });
  }

  const {
    roles,
    addRole,
    updateRoles,
    removeRole,
    setRoleName,
    setPrefillName,
    setPrefillEmail,
    setPrefillNameEmail,
  } = useRoles(initialSigners);

  useEffect(() => {
    if (props.activeRoleContact || props.activeRoleContactParent) {
      updateRoles(
        props.roles.map(f => (f.roleName ? { ...f } : { ...f, new: true })),
      );
      if (
        props.activeRoleContactParent &&
        props.roles.every(r => r.isRoleNameValid === false)
      ) {
        setShowError(true);
      }
    } else {
      updateRoles(initialSigners);
    }
  }, [props.activeRoleContact, props.activeRoleContactParent, props.roles]);
  // const disableNext = useMemo(() => {
  //   const {
  //     hasInvalidSigner,
  //     notEmptySignersAmount,
  //   } = getSignersValidationStatus(signers);
  //   return notEmptySignersAmount < signers.length || hasInvalidSigner;
  // }, [signers]);

  const [allowFormValidation, setAllowFormValidation] = useState(false);

  function goToNextStep() {
    setShowError(true);

    const { hasInvalidRole, notEmptyRoleAmount } = getRolesValidationStatus(
      roles,
    );

    if (notEmptyRoleAmount === roles.length && !hasInvalidRole) {
      const rolesList = roles.map((role, idx) => {
        if (!role.hasPrefill && role.prefillEmail && role.prefillName) {
          return {
            ...role,
            hasPrefill: false,
            prefillEmail: '',
            prefillName: '',
            color: role.color ? role.color : colors.current[idx],
          };
        }
        return {
          ...role,
          color: role.color ? role.color : colors.current[idx],
        };
      });
      // const nonDuplicateList = getNotEmptyUniqueSigners(rolesList);
      props.setRoles(rolesList);
      props.goToNextStepEmitter(rolesList);
      const parsedToken = parseJwt(token);
      setDataToSessionStorage(
        {
          [`${props.activeFileId}`]: rolesList,
          userId: parsedToken?.userId,
        },
        'roles',
      );
    }
  }

  const setColors = col => {
    colors.current = col;
  };

  return (
    <>
      {showLoader && <GlobalLoader />}

      <div
        className={`esign-modal template-step-modal ${props.animationState}`}
      >
        <div className={'template-step-modal__header'}>
          <h3 className="esign-modal__title">
            {props.afterSigning ? <>Add Recipients</> : <>Add Roles</>}
          </h3>
          {props.requestType !== 'notarize' && (
            <div className={'template-step-modal__order-switch'}>
              <Switch
                checked={props.ordered}
                onChange={e => {
                  const emails = roles.map(item => item.prefillEmail).filter(email => email !== "");
                  const duplicates = emails.filter((email, index, self) => self.indexOf(email) !== index);
                  const hasDuplicates = duplicates.length > 0;
                  if(hasDuplicates){
                    props.setOrdered(true);
                    NotificationManager.error(
                      'Cannot have duplicate recipients unless the signing order is toggled ON',
                      '',
                      4000,
                      null,
                      null,
                      'filled',
                    );
                    return
                  }
                  props.setOrdered(e.target.checked)
                }}
              />{' '}
              Set signing order
            </div>
          )}
        </div>
        <RolesForm
          isNotarizing={props.requestType === 'notarize'}
          signingOrder={props.ordered}
          setOrdered={props.setOrdered}
          toggleShowContactModal={props.toggleShowContactModal}
          toggleNewTemplateModal={props.toggleNewTemplateModal}
          setRoleContact={props.setRoleContact}
          afterSigning={props.afterSigning}
          roles={roles}
          updatedRemoved={props.updatedRemoved}
          removeRoleConfirm={props.removeRoleConfirm}
          contacts={props.contacts}
          setContacts={props.setContacts}
          setLoadingContacts={props.setLoadingContacts}
          addRole={addRole}
          updateRoles={updateRoles}
          setRoles={props.setRoles}
          removeRole={removeRole}
          onRoleNameChange={setRoleName}
          onPrefillNameChange={setPrefillName}
          onPrefillEmailChange={setPrefillEmail}
          onPrefillNameEmailChange={setPrefillNameEmail}
          onEnterKeyPress={goToNextStep}
          showErrorMessage={allowFormValidation}
          user={props.user}
          colors={colors}
          setColors={setColors}
          showError={showError}
        />
        <div
          className={`esign-modal__footer ${
            props.afterSigning ? `template-step-modal__footer-signing` : ``
          } template-step-modal__footer`}
        >
          {props?.enableBack && (
            <button
              className="esign-button esign-button--light"
              onClick={() => {
                props.goToPrevStepEmitter();
              }}
            >
              {props.afterSigning ? <>Cancel</> : <>Back</>}
            </button>
          )}
          {props.afterSigning && (
            <button
              className="esign-button esign-button--quick-send esign-button--light"
              onClick={() => {
                const validForm =
                  roles.filter(
                    f => f.isPrefillEmailValid && f.isPrefillNameValid,
                  ).length > 0;
                if (validForm) {
                  props.makeSignatureFromTemplate(roles);
                } else {
                  setShowError(true);
                }
              }}
              // disabled={disableNext}
            >
              Quick Send
            </button>
          )}
          <button
            className="esign-button esign-button--blue"
            onClick={goToNextStep}
            // disabled={disableNext}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

RolesEdit.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser, createTemplate, contact }) => {
  const { user } = authUser;
  const { contacts } = contact;
  const {
    signers,
    roles,
    templateType,
    ordered,
    activeRoleContact: activeRoleContactParent,
    activeSignerContact,
  } = createTemplate;

  return {
    contacts,
    user,
    roles,
    signers,
    templateType,
    ordered,
    activeRoleContactParent,
  };
};

const mapDispatchToProps = {
  setRoles,
  setOrdered,
  toggleShowContactModal,
  toggleNewTemplateModal,
  setRoleContact,
  setContacts,
  setLoadingContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesEdit);
