import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import MouseBackEnd from 'react-dnd-mouse-backend';

import TopNav from './Header';
import Footer from './Footer';
import SideBar from './SideBar';

import { toggleSidebar } from '../redux/navbar/actions';

import { isMobileBrowser, PageDetectionMethod } from '../utils';

import './style.scss';

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgreedAcceptCookie: false,
    };
  }

  componentDidMount() {
    const cookie = document.cookie.split('; ').reduce((prev, current) => {
      const [name, ...value] = current.split('=');
      prev[name] = value.join('=');
      return prev;
    }, {});

    this.setState({
      isAgreedAcceptCookie: !!cookie.cookie_notice_accepted,
    });

    if (this.props.user && !this.props.user.isEmailVerified) {
      this.props.history.push('/confirm-email');
    }

    window.scrollTo(0, 0);
  }

  render() {
    const { pathname } = window.location;
    const homepageUrls = [
      '/',
      '/login',
      '/login/',
      '/sign-up',
      '/sign-up/',
      '/reminder-unsubscribe',
      '/reminder-off',
      '/complete-profile',
      '/sign/v2',
      'template/v2/',
      '/sign',
      '/create-signature',
      '/password-reset',
      '/email-verified',
      '/sent-request',
      '/security-check',
      '/account-deleted',
      '/signing-process',
      '/prepare-form',
      '/template-form',
      '/license-invite',
    ];
    const isHomePage =
      homepageUrls.includes(pathname) || pathname.includes('/preview');
    const isCommon =
      window.location.href.search('/pricing') > 0 ||
      window.location.href.search('/confirm-email') > 0 ||
      window.location.href.search('/settings/billing/plans/checkout') > 0;

    return (
      <div className="app-wrapper">
        {isHomePage ? (
          <div className="home-page-main-content-wrapper">
            <TopNav isHomePage={isHomePage} />
            <div
              style={{
                padding:
                  isHomePage && this.props.deviceWidth > 576
                    ? '10px 30px 70px 30px'
                    : '10px 10px 70px 10px',
              }}
              className="pages-top-nav-wrapper js-pages-top-nav-wrapper"
            >
              <div className="main-container">{this.props.children}</div>
            </div>
          </div>
        ) : (
          <>
            <TopNav isHomePage={isHomePage} />
            <div
              className={`
                pages-top-nav-wrapper 
                js-pages-top-nav-wrapper 
                ${isMobileBrowser() && 'pages-top-nav-wrapper--is-mobile'} 
                ${isCommon && 'is-common'}
              `}
              style={{
                background:
                  window.location.href.search(
                    '/settings/billing/plans/checkout',
                  ) > 0
                    ? 'rgb(242, 242, 247)'
                    : 'white',
              }}
            >
              {isCommon ? (
                <div className="main-container">{this.props.children}</div>
              ) : PageDetectionMethod.Documents() ||
                PageDetectionMethod.Shared() ||
                PageDetectionMethod.Starred() ||
                PageDetectionMethod.Trash() ||
                PageDetectionMethod.Inbox() ||
                PageDetectionMethod.Sent() ||
                PageDetectionMethod.Templates() ? (
                <DndProvider backend={MouseBackEnd}>
                  <SideBar
                    toggleSidebar={this.props.toggleSidebar}
                    showSidebar={this.props.showSidebar}
                  />
                  <div className="main-container">{this.props.children}</div>
                </DndProvider>
              ) : (
                <>
                  <DndProvider backend={MouseBackEnd}>
                    <SideBar
                      toggleSidebar={this.props.toggleSidebar}
                      showSidebar={this.props.showSidebar}
                    />
                  </DndProvider>
                  <div className="main-container">{this.props.children}</div>
                </>
              )}

              {/* <SideBar toggleSidebar={this.props.toggleSidebar} showSidebar={this.props.showSidebar}/>
              {PageDetectionMethod.Documents() || PageDetectionMethod.Shared() || PageDetectionMethod.Starred() || PageDetectionMethod.Trash()
                ? <DndProvider backend={MouseBackEnd}>
                    <div className="main-container">{this.props.children}</div>
                  </DndProvider>
                : <div className="main-container">{this.props.children}</div>
              } */}
            </div>
          </>
        )}
        {/* Info: Removed manual cookie manager by integrating cookieyes (Third-Party) */}
        {/*{!this.state.isAgreedAcceptCookie && (*/}
        {/*  <div className="cookie-notice">*/}
        {/*    <p className="text">*/}
        {/*      By using the website, you agree to our use of cookies to analyze*/}
        {/*      website traffic and improve your experience on our website.*/}
        {/*    </p>*/}
        {/*    <button*/}
        {/*      className="esign-button esign-button--white"*/}
        {/*      onClick={() => (*/}
        {/*        (document.cookie = 'cookie_notice_accepted=true;'),*/}
        {/*        this.setState({ isAgreedAcceptCookie: true })*/}
        {/*      )}*/}
        {/*    >*/}
        {/*      OK*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*)}*/}
        {pathname === '/' && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = ({ navBar, authUser }) => {
  const { deviceWidth, showSidebar } = navBar;
  const { user } = authUser;
  return { deviceWidth, showSidebar, user };
};
const mapDispatchToProps = {
  toggleSidebar,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLayout),
);
