import { api, getTokenFromCookie } from './index';
import { BASE_URL_V1, BASE_URL_V2 } from '../static/config';

export const findOrCreateFolderSharedLink = folderId =>
  api.post({
    path: `/link/shared/active`,
    body: { folderId },
  });

export const getFolderSharedRecipients = folderId =>
  api.get({
    path: `/filesystem/folder/${folderId}/share/user`,
  });

export const createFolder = body =>
  api.post({
    path: `/folder`,
    body,
  });

export const getFolders = () => {
  return api.get({
    path: '/folder',
  });
};

export const getFoldersStatistics = () => {
  return api.get({
    path: '/folder/statistics',
  });
};

export const initDownloadFolder = folderId =>
  api.post({
    path: `/download`,
    body: {
      folderId,
    },
  });

export const downloadFolder = id => {
  return fetch(`${BASE_URL_V1}/download/${id}/download`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getTokenFromCookie()}`,
    }),
  });
};

/**
 * @param {String} folderId
 * @param {('public'|'invited')} publicity
 */
export const createFolderShareLink = (folderId, publicity) =>
  api.post({
    path: '/link/shared',
    body: { folderId, publicity },
  });

export const getFolderShareLink = folderId =>
  api.get({
    path: `/link/shared/active/folder/${folderId}`,
  });

/**
 * @param {String} folderId
 * @param {{email: String, permission: ('admin'|'editor'|'viewer')}} recipient
 */
export const shareFolder = (folderId, recipient) =>
  api.post({
    path: `/folder/${folderId}/share`,
    body: recipient,
  });

export const removeRecipientFromFolder = (id, email) => {
  return api.post({
    path: `/folder/${id}/share/remove`,
    body: { email },
  });
};

export const changeFolderTitle = (id, name) => {
  return api.put({
    path: `/folder/${id}`,
    body: { name },
  });
};

export const getUserFolders = () =>
  api.get({
    path: `/folder`,
  });

export const getUserSharingFolders = () =>
  api.get({
    path: `/folder/shared`,
  });

export const getFolderInfoById = id =>
  api.get({
    path: `/folder/${id}`,
  });

export const getFolderFilesById = (id, page, perPage, order, sortBy) =>
  api.get({
    path: `/folder/${id}/listing?page=${page}&perPage=${perPage}&order=${order}&sortBy=${sortBy}`,
  });

export const getRootFolderFiles = (page, perPage) =>
  api.get({
    path: `/folder/main?page=${page}&perPage=${perPage}`,
  });

export const trashFolder = id => {
  return api.post({
    path: `/folder/${id}/remove`,
  });
};

export const searchUserFilesWithFilters = (
  typePage,
  query,
  page,
  perPage,
  order,
  sortBy,
  filter,
  signal,
  contactId = undefined,
  mode = '',
) => {
  return api.get({
    path: `/${typePage}?query=${query}&perPage=${perPage}&page=${page}&order=${order}&sortBy=${sortBy}${
      filter
        ? typePage === 'filesystem/shared'
          ? '&type=' + filter
          : '&filter=' + filter
        : typePage === 'filesystem/shared' ? '&toFromMe=toMe' : ''
    }${contactId ? `&contactId=${contactId}` : ''}${
      mode ? `&mode=${mode}` : ''
    }`,
    signal,
    serverUrl: typePage.includes('shared') ? BASE_URL_V2 : BASE_URL_V1
  });
};
