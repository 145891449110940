import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setFileId,
  setSigners,
  restoreSigners,
  setUploadedFiles,
  setReceiveCopy,
  setAreYouSigner,
  setAreYouSignerOnly,
  setIsMultipleSigners,
  setIsImmediateTemplate,
  setWhoNeedsToSign,
} from '../../redux/createTemplate/actions';

import { setUserTemplateTipSettings } from '../../redux/auth/actions';

import Modal from '../custom/Modal';
import AskWhoNeedsSignStep from './AskWhoNeedsSignStep';
import AddRecipientsStep from './AddRecipientsStep';

import './style.scss';
import WhereSendCopyStep from './WhereSendCopyStep';
import { signType, steps } from './constants';
import { RECIPIENTS_TYPE, animationStateEnumeration } from '../../static';
import {
  createRequestDraftPublic,
  getFreeSignatureStatusExceeded,
  getSignatureRequest,
  prepareSigners,
  prepareSignersPublic,
} from '../../api/signature';
import { NotificationManager } from '../Notifications';
import GlobalLoader from '../GlobalLoader';
import { resetState } from '../../redux/createTemplate/actions';
import SigningLimitReachedModal from '../Modals/SigningLimitReachedModal';
import { isMobileBrowser } from '../../utils';
import TemplateTipModal from '../Modals/TemplateTipModal';
import { updateTemplateSettings } from '../../api/settings';

const SigningProcessV2 = props => {
  const [
    showSigningLimitReachedModal,
    setShowSigningLimitReachedModal,
  ] = useState(false);
  const [templateTipModal, toggleTemplateTipModal] = useState(false);
  useEffect(() => {
    document.body.classList.add('body-stop-scrolling');
    return () => {
      document.body.classList.remove('body-stop-scrolling');
    };
  }, []);

  useEffect(() => {
    if (props?.initWhoNeedsToSign) {
      props.setWhoNeedsToSign(props?.initWhoNeedsToSign);
      setStep(props?.initStep);
    }
  }, []);

  const amISigner = recipients => {
    return recipients.findIndex(signer => !!signer?.isMe) > -1;
  };

  const [step, setStep] = useState(
    props?.initWhoNeedsToSign
      ? steps.WHO_NEEDS_TO_SIGN
      : props?.initStep || steps.WHO_NEEDS_TO_SIGN,
  );
  const [animationState, setAnimationState] = useState(
    animationStateEnumeration.STATIC,
  );
  const [isLoading, setLoading] = useState(false);

  function closeModalHandler() {
    props.resetState();
    props.closeModalEmitter();
  }

  function goNext(signers, senderInfo) {
    // Create Signature Draft payload
    const payload = {
      fileId: props.fileForSign._id,
      signers: signers.map((signer, idx) => ({
        email: signer.email,
        role: signer.role,
        name: signer.fullName,
        type: signer.type ? signer.type : RECIPIENTS_TYPE.SIGNER,
        color: signer.color,
        order: idx + 1,
      })),
      ordered: props.ordered,
    };
    console.log(props.fileForSign);

    if (!props.user) {
      const amSigner = signers.find(signer => !!signer?.isMe);
      if (!amSigner) {
        payload.senderEmail = senderInfo?.senderEmail;
        payload.senderName = senderInfo?.senderName;
      } else {
        payload.senderEmail = amSigner?.email;
        payload.senderName = amSigner?.fullName;
      }
    } else {
      payload.senderEmail = props.user.email;
      payload.senderName = props.user.fullName;
    }

    if (props.requestType === 'notarize') {
      props.notarizeFileEmitter(signers, {
        email: payload.senderEmail,
        fullName: payload.senderName,
      });

      closeModalHandler();
    } else {
      setLoading(true);
      if (props.isSigned) {
        props.resetState();
        props?.openPrepareForm(payload);
        setLoading(false);
      } else {
        // Check signature limit exceed
        const isOnlyMe =
          signers.length === 1 && signers[0].email === payload.senderEmail;
        if (isOnlyMe) {
          createRequestDraft(payload);
        } else {
          getFreeSignatureStatusExceeded(payload.senderEmail).then(resp => {
            if (resp?.limitExceeded) {
              // Free signature limit exceed
              setShowSigningLimitReachedModal(true);
              setLoading(false);
            } else {
              createRequestDraft(payload);
            }
          });
        }
      }
    }
  }

  const createRequestDraft = payload => {
    if (props.fileForSign?.completedAt) {
      if (props.fileForSign?.signatureRequest) {
        // If logged-out, this case will not be triggered.
        // const prepareSignersService = props?.user
        //   ? prepareSigners
        //   : prepareSignersPublic;
        const sigId = props.fileForSign.signatureRequest.id;
        prepareSigners(sigId, { signers: payload?.signers || [] })
          .then(resp => {
            if (resp?.success) {
              return getSignatureRequest(sigId);
            } else {
              NotificationManager.error(
                resp?.message || 'Some problem with signature request',
                '',
                3000,
                null,
                null,
                'filled',
              );
            }
          })
          .then(resp => {
            if (resp) {
              props.resetState();
              props?.openPrepareForm(resp?.token);
            } else {
              NotificationManager.error(
                resp?.message || 'Some problem with signature request',
                '',
                3000,
                null,
                null,
                'filled',
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      createRequestDraftPublic(payload)
        .then(resp => {
          if (resp?.success) {
            props.resetState();
            props?.openPrepareForm(resp?.token);
          } else {
            NotificationManager.error(
              resp?.message || 'Some problem with signature request',
              '',
              3000,
              null,
              null,
              'filled',
            );
          }
        })
        // .catch(() => {
        //   NotificationManager.error(
        //     'Something went wrong',
        //     '',
        //     3000,
        //     null,
        //     null,
        //     'filled',
        //   );
        // })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const rejectTip = () => {
    toggleTemplateTipModal(false);
    updateTemplateSettings({ skipTipModal: true }).then(resp => {
      // Skip checking response
      props.setUserTemplateTipSettings(true);
      setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT);
      setStep(steps.ADD_RECIPIENTS);
    });
  };

  const saveAsTemplate = () => {
    toggleTemplateTipModal(false);
    if (props?.saveAsTemplate) {
      // closeModalHandler();
      props.saveAsTemplate();
    }
  };

  return (
    <>
      {isLoading && <GlobalLoader />}
      <Modal
        toggle={() => closeModalHandler()}
        disableBackdropClick={true}
        freezeBodyScroll={false}
        className="without-animation"
      >
        {step === steps.WHO_NEEDS_TO_SIGN && (
          <AskWhoNeedsSignStep
            animationState={animationState}
            showBackButton={props.signType === signType.IMMEDIATE}
            goToPrevStepEmitter={() => (
              setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
              props.goToPrevStepEmitter()
            )}
            goToNextStepEmitter={() =>
              // Self Signing flow is different
              props.whoNeedsToSign === 'only_me' &&
              !isMobileBrowser() &&
              props.requestType !== 'notarize'
                ? props?.openSelfSigning()
                : props?.user &&
                  !props.user?.settings?.templates?.skipTipModal &&
                  props.whoNeedsToSign !== 'only_me'
                ? toggleTemplateTipModal(true)
                : (setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT),
                  setStep(steps.ADD_RECIPIENTS))
            }
          />
        )}
        {step === steps.ADD_RECIPIENTS && (
          <AddRecipientsStep
            animationState={animationState}
            enableMeSigner={!!props?.enableMeSigner}
            enableBack={!props?.initWhoNeedsToSign}
            goToPrevStepEmitter={() =>
              props.requestType === 'notarize'
                ? closeModalHandler()
                : (setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
                  setStep(steps.WHO_NEEDS_TO_SIGN))
            }
            goToNextStepEmitter={signers =>
              props?.user || amISigner(signers) || props?.isSigned
                ? goNext(signers)
                : (setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT),
                  setStep(steps.WHERE_TO_SEND))
            }
          />
        )}
        {step === steps.WHERE_TO_SEND && (
          <WhereSendCopyStep
            animationState={animationState}
            goToPrevStepEmitter={() => (
              setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT),
              setStep(steps.ADD_RECIPIENTS)
            )}
            goToNextStepEmitter={(signers, senderInfo) =>
              goNext(signers, senderInfo)
            }
            signUpForFree={e => props.onCreateAccountBtnClick(e)}
          />
        )}
      </Modal>
      {showSigningLimitReachedModal && (
        <SigningLimitReachedModal
          emitCloseModal={() => setShowSigningLimitReachedModal(false)}
        />
      )}
      {templateTipModal && (
        <TemplateTipModal
          closeModalEmitter={() => toggleTemplateTipModal(false)}
          rejectTip={rejectTip}
          saveAsTemplate={saveAsTemplate}
        />
      )}
    </>
  );
};

function fileForSignPropsValidator(props, propName, componentName) {
  if (props['signType'] === 'IMMEDIATE' && props[propName] === undefined) {
    return new Error(
      `Provide a ${propName} for ${componentName} component with prop signType=${props['signType']}`,
    );
  }
  // Need call PropTypes.checkPropTypes to save condition validation write above
  // and save object validation
  PropTypes.checkPropTypes(
    {
      [propName]: PropTypes.shape({
        id: PropTypes.string,
      }),
    },
    props,
    propName,
    componentName,
  );
}

function openSignTemplateEmitterPropsValidator(props, propName, componentName) {
  if (
    props['signType'] === 'BASIC' &&
    (props[propName] === undefined || typeof props[propName] !== 'function')
  ) {
    return new Error(
      `Provide a ${propName} function for ${componentName} component with prop signType=${props['signType']}`,
    );
  }
}

SigningProcessV2.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func,
  signType: PropTypes.oneOf(Object.keys(signType)).isRequired,
  initStep: PropTypes.oneOf(Object.keys(steps)),
  fileForSign: fileForSignPropsValidator,
  openSignTemplateEmitter: openSignTemplateEmitterPropsValidator,
};

SigningProcessV2.defaultProps = {
  initStep: steps.WHO_NEEDS_TO_SIGN,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const {
    requestType,
    areYouSigner,
    areYouSignerOnly,
    isMultipleSigners,
    signers,
    whoNeedsToSign,
    ordered,
  } = createTemplate;

  return {
    user,
    requestType,
    areYouSigner,
    areYouSignerOnly,
    isMultipleSigners,
    signers,
    whoNeedsToSign,
    ordered,
  };
};

const mapDispatchToProps = {
  setFileId,
  setIsImmediateTemplate,
  setUploadedFiles,
  setSigners,
  restoreSigners,
  setAreYouSignerOnly,
  setIsMultipleSigners,
  setAreYouSigner,
  setReceiveCopy,
  resetState,
  setWhoNeedsToSign,
  setUserTemplateTipSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigningProcessV2);
