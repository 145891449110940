import React from 'react';
import './style.scss';

const Switch = ({ checked, onChange, disabled = false }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className={disabled ? 'switch__lever disabled' : 'switch__lever'} />
    </label>
  );
};

export default Switch;
