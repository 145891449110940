import {
  SET_FILE_ID,
  SET_UPLOADED_FILES,
  SET_IS_IMMEDIATE_TEMPLATE,
  SET_REQUEST_TYPE,
  SET_SENDER_EMAIL,
  SET_SENDER_FULL_NAME,
  SET_SIGNERS,
  RESTORE_SIGNERS,
  SET_IS_MULTIPLE_SIGNERS,
  SET_ARE_YOU_SIGNER,
  SET_ARE_YOU_SIGNER_ONLY,
  SET_RECEIVE_COPY,
  SET_VERIFICATION_CODE,
  SET_WHO_NEEDS_TO_SIGN,
  SET_RECIPIENTS,
  SET_EMPTY_FIELDS,
  RESET_TEMPLATE_STATE,
  SET_ORDERED,
  SET_DOC_TEMPLATE_TYPE,
  SET_ROLES,
  SET_ROLE_CONTACT,
  SET_SIGNER_CONTACT,
  SET_PROCESS_TEMPLATE,
  SET_ACTIVE_FILEID,
  SET_ACTIVE_ROLE_CONTACT,
  SET_ACTIVE_SIGNER_CONTACT,
  SET_ACTIVE_FIELDS,
  SET_LOADING_TEMPLATE,
  SET_SIGNATURE_REQUEST_ID_TEMPLATE,
  SET_SHOW_TEMPLATE_TIP,
} from '../actions';

const INIT_STATE = {
  fileId: '',
  uploadedFiles: [],
  isImmediateTemplate: false,
  requestType: 'signature', // notarize
  senderEmail: '',
  senderFullName: '',
  signers: [],
  isMultipleSigners: false,
  areYouSigner: true,
  areYouSignerOnly: true,
  receiveCopy: true,
  verificationCode: null,
  // V2 Properties
  whoNeedsToSign: '',
  recipients: [],
  emptyFields: [],
  ordered: false,
  templateType: '',
  roles: [],
  roleContact: {},
  signerContact: {},
  processTemplate: null,
  activeFileId: '',
  activeRoleContact: false,
  activeSignerContact: false,
  activeFields: [],
  loadingTemplate: false,
  signatureRequestId: '',
  showTemplateTip: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILE_ID:
      return { ...state, fileId: action.payload };
    case SET_UPLOADED_FILES:
      return { ...state, uploadedFiles: action.payload };
    case SET_IS_IMMEDIATE_TEMPLATE:
      return { ...state, isImmediateTemplate: action.payload };
    case SET_REQUEST_TYPE:
      return { ...state, requestType: action.payload };
    case SET_SENDER_EMAIL:
      return { ...state, senderEmail: action.payload };
    case SET_SENDER_FULL_NAME:
      return { ...state, senderFullName: action.payload };
    case SET_SIGNERS:
      return { ...state, signers: action.payload };
    case RESTORE_SIGNERS:
      return { ...state, signers: [], subject: '', message: '' };
    case SET_IS_MULTIPLE_SIGNERS:
      return { ...state, isMultipleSigners: action.payload };
    case SET_ARE_YOU_SIGNER:
      return { ...state, areYouSigner: action.payload };
    case SET_ARE_YOU_SIGNER_ONLY:
      return { ...state, areYouSignerOnly: action.payload };
    case SET_VERIFICATION_CODE:
      return { ...state, verificationCode: action.payload };
    case SET_RECEIVE_COPY:
      return { ...state, receiveCopy: action.payload };
    case SET_WHO_NEEDS_TO_SIGN:
      return { ...state, whoNeedsToSign: action.payload };
    case SET_EMPTY_FIELDS:
      return { ...state, emptyFields: action.payload };
    case RESET_TEMPLATE_STATE:
      return { ...state, ...INIT_STATE };
    case SET_ORDERED:
      return { ...state, ordered: action.payload };
    case SET_DOC_TEMPLATE_TYPE:
      return { ...state, templateType: action.payload };
    case SET_ROLES:
      return { ...state, roles: action.payload };
    case SET_ROLE_CONTACT:
      return { ...state, roleContact: action.payload };
    case SET_SIGNER_CONTACT:
      return { ...state, signerContact: action.payload };
    case SET_PROCESS_TEMPLATE:
      return { ...state, processTemplate: action.payload };
    case SET_ACTIVE_FILEID:
      return { ...state, activeFileId: action.payload };
    case SET_ACTIVE_ROLE_CONTACT:
      return { ...state, activeRoleContact: action.payload };
    case SET_ACTIVE_SIGNER_CONTACT:
      return { ...state, activeSignerContact: action.payload };
    case SET_ACTIVE_FIELDS:
      return { ...state, activeFields: action.payload };
    case SET_LOADING_TEMPLATE:
      return { ...state, loadingTemplate: action.payload };
    case SET_SIGNATURE_REQUEST_ID_TEMPLATE:
      return { ...state, signatureRequestId: action.payload };
    case SET_SHOW_TEMPLATE_TIP:
      return { ...state, showTemplateTip: action.payload };
    default:
      return { ...state };
  }
};
