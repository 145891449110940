import React from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';

import Modal from '../../custom/Modal';

import { ReactComponent as WarningIcon } from '../../../assets/img/warning.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/edit.svg';
import { ReactComponent as SharedIcon } from '../../../assets/img/shared.svg';
import { ReactComponent as SyncIcon } from '../../../assets/img/sync.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/img/google-drive-small.svg';
import { ReactComponent as DropBoxIcon } from '../../../assets/img/one-drive-small.svg';
import { ReactComponent as OneDriveIcon } from '../../../assets/img/drop-box-small.svg';
import { ReactComponent as BalanceIcon } from '../../../assets/img/balance.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/img/phone.svg';
import { ReactComponent as LockIcon } from '../../../assets/img/lock.svg';

import './style.scss';

const SigningLimitReachedModal = props => {
  const history = useHistory();

  return (
    <Modal toggle={props.emitCloseModal}>
      <div className="esign-modal signing-limit-reached">
        <div className="signing-limit-reached__title">
          eSigning Limit Reached (3/3)
        </div>

        <p
          className="signing-limit-reached__subtitle"
          style={{ fontWeight: 'bold' }}
        >
          <WarningIcon />
          You have reached the maximum number of three (3) monthly sign requests
          included in the Free plan.
        </p>

        <p
          className="signing-limit-reached__subtitle"
          style={{ display: 'block' }}
        >
          In addition to <b>unlimited</b> sign requests, continue enjoying the{' '}
          <br /> following free features after upgrading:
        </p>

        <div className="signing-limit-reached__description">
          <p>
            <EditIcon /> Unlimited PDF editing
          </p>
          <p>
            <SharedIcon /> Unlimited document and folder sharing
          </p>
          {props.deviceWidth > 768 ? (
            <p>
              <SyncIcon /> Sync with <GoogleIcon /> Google Drive <DropBoxIcon />{' '}
              Dropbox, and <OneDriveIcon /> OneDrive
            </p>
          ) : (
            <p>
              <SyncIcon /> Sync with <GoogleIcon />, <DropBoxIcon />, and{' '}
              <OneDriveIcon />
            </p>
          )}
          <p>
            <BalanceIcon /> UETA / ESIGN Compliant
          </p>
          <p>
            <PhoneIcon /> Sign on the go with our Apple and Android apps
          </p>
          <p>
            <LockIcon /> 2-Factor Security
          </p>
        </div>

        <button
          onClick={() =>
            props.user
              ? history.push('/settings/billing/plans')
              : (history.push('/pricing?plan=premium'), props.emitCloseModal())
          }
          className="esign-button esign-button--blue"
        >
          Unlock Unlimited eSigning
        </button>

        <p className="signing-limit-reached__footer">$9.99/mo per user</p>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ authUser, navBar }) => {
  const { user } = authUser;
  const { deviceWidth } = navBar;
  return { user, deviceWidth };
};

export default withRouter(
  connect(mapStateToProps, null)(SigningLimitReachedModal),
);
