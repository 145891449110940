import { RESIZE_DEVICE_WIDTH, TOGGLE_SIDEBAR } from '../actions';

const INIT_STATE = {
  showSidebar: false,
  deviceWidth: window.innerWidth,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, showSidebar: !state.showSidebar };
    case RESIZE_DEVICE_WIDTH:
      return { ...state, deviceWidth: action.payload };
    default:
      return { ...state };
  }
};
