import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { resetPassword } from '../../api/auth';

import { validateEmail } from '../../utils';
import { useFormInput } from '../SigningProcess/helpers';

import CustomInput from '../custom/CustomInput';
import NotificationManager from '../Notifications/NotificationManager';

const ResetPassword = props => {
  const [requestHasBeenSending, setRequestHasBeenSenging] = useState(false);
  const emailController = useFormInput(
    props.initEmail || '',
    value => !validateEmail(value),
  );

  function resetPasswordHandler(e) {
    e.preventDefault();
    setRequestHasBeenSenging(true);

    const email = emailController.value;

    resetPassword({ email }).then(res => {
      NotificationManager.success(
        'Email with reset instructions was sent.',
        '',
        3000,
        null,
        null,
        'filled',
      );
    });
  }

  return requestHasBeenSending ? (
    <form className={`enter-to-app__form ${props.animationState}`}>
      <h1 className="title">Instructions sent</h1>
      <p className="enter-to-app__text">
        If there is an account with the email {emailController.value}, password
        reset instructions will be delivered within a few minutes.
        <br />
        <br />
        If you don’t receive an email, check your spam folder and confirm your
        email address is correct.
      </p>
      <button
        type="submit"
        className="esign-button esign-button--blue"
        onClick={props.goToSignInTemplateEmitter}
      >
        Return to log in
      </button>
    </form>
  ) : (
    <form className={`enter-to-app__form ${props.animationState}`}>
      <h1 className="title">Forgot your password?</h1>
      <p className="enter-to-app__text" style={{ maxWidth: '100%' }}>
        Enter your email address to reset your password.
      </p>
      <CustomInput
        type="email"
        placeholder="Email address"
        wrapperClassName={'logging-in'}
        value={emailController.value}
        onChange={emailController.handleChange}
      />
      <button
        type="submit"
        className="esign-button esign-button--blue"
        disabled={emailController.isInvalid || !emailController.value.length}
        onClick={resetPasswordHandler}
      >
        Reset password
      </button>
      <div className="enter-to-app__footer">
        <button
          type="button"
          className="enter-to-app__btn"
          onClick={props.goToSignInTemplateEmitter}
        >
          Return to log in
        </button>
      </div>
    </form>
  );
};

ResetPassword.propTypes = {
  goToSignInTemplateEmitter: PropTypes.func.isRequired,
};

export default ResetPassword;
