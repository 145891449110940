import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setWhoNeedsToSign } from '../../redux/createTemplate/actions';

import RadioGroup from '../custom/RadioGroup';

// Icons
import { ReactComponent as MeIcon } from '../../assets/img/new_icons/signing-me.svg';
import { ReactComponent as MeOthersIcon } from '../../assets/img/new_icons/signing-me-others.svg';
import { ReactComponent as OthersIcon } from '../../assets/img/new_icons/signing-others.svg';

const whoSignOptions = [
  {
    value: 'only_me',
    label: (
      <div className={'esign-radio-button__label-custom'}>
        <span>Me Only</span> <MeIcon />
      </div>
    ),
  },
  {
    value: 'me_others',
    label: (
      <div className={'esign-radio-button__label-custom'}>
        <span>Me + Others</span> <MeOthersIcon />
      </div>
    ),
  },
  {
    value: 'others',
    label: (
      <div className={'esign-radio-button__label-custom'}>
        <span>Others Only</span> <OthersIcon />
      </div>
    ),
  },
];

const AskWhoNeedsSignStep = props => {
  function whoSignHandler(event) {
    props.setWhoNeedsToSign(event.value);
  }

  function goToNextStep() {
    if (props.whoNeedsToSign) {
      props.goToNextStepEmitter();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, []);

  const onEnterKeyDownHandler = e => {
    if (e.keyCode === 13) {
      goToNextStep();
    }
  };

  return (
    <div
      className={`esign-modal signing-step-modal signing-step-modal__amount-step ${props.animationState}`}
    >
      <h3 className="esign-modal__title">Who needs to sign?</h3>
      <RadioGroup
        name="who-is-signing"
        onChange={whoSignHandler}
        options={whoSignOptions}
        defaultChecked={props.whoNeedsToSign}
      />
      <div className="esign-modal__footer">
        {props.showBackButton && (
          <button
            className="esign-button esign-button--light"
            onClick={props.goToPrevStepEmitter}
          >
            Back
          </button>
        )}
        <button
          className="esign-button esign-button--blue"
          disabled={!props?.whoNeedsToSign}
          onClick={goToNextStep}
        >
          Next
        </button>
      </div>
    </div>
    // </Modal>
  );
};

AskWhoNeedsSignStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  goToPrevStepEmitter: PropTypes.func,
};

AskWhoNeedsSignStep.defaultProps = {
  showBackButton: false,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const { whoNeedsToSign } = createTemplate;

  return { user, whoNeedsToSign };
};

const mapDispatchToProps = {
  setWhoNeedsToSign,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskWhoNeedsSignStep);
