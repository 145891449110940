export const signType = {
  IMMEDIATE: 'IMMEDIATE',
  BASIC: 'BASIC',
  EXTERNAL_FILE: 'EXTERNAL_FILE',
};

export const steps = {
  WHO_NEEDS_TO_SIGN: 'WHO_NEEDS_TO_SIGN',
  ADD_RECIPIENTS: 'ADD_RECIPIENTS',
  WHERE_TO_SEND: 'WHERE_TO_SEND',
};

export const animationStateEnumeration = {
  STATIC: 'STATIC',
  RIGHT_TO_LEFT: 'RIGHT_TO_LEFT',
  LEFT_TO_RIGHT: 'LEFT_TO_RIGHT',
};

export const SIGNERS_LIST_TYPE = {
  ONLY_ME: 'only_me',
  ME_OTHERS: 'me_others',
  OTHERS: 'others',
};

export const RECIPIENT_COLOR = '#c7c7cc';
