import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { LIBRARY_URL } from '../../static/config';

import { ReactComponent as LinkedInIcon } from '../../assets/img/linkedIn-icon.svg';
import { ReactComponent as FaceBookIcon } from '../../assets/img/face-book-icon.svg';

import EnterToApp from '../../components/EnterToApp';
import AppStoreLink from '../../components/AppStoreLink';
import GoogleStoreLink from '../../components/GoogleStoreLink';

import './style.scss';

const Footer = props => {
  const [showEnterToApp, setShowEnterToApp] = useState(false);

  function closeEnterToApp() {
    setShowEnterToApp(false);
  }

  const [hoveredLinksIcon, setHoveredLinksIcon] = useState({});

  function hoveredLinksMouseEnterHandler(icon) {
    setHoveredLinksIcon({ ...hoveredLinksIcon, [icon]: true });
  }

  function hoveredLinksMouseLeaveHandler() {
    const activeSocialLinksCopy = { ...hoveredLinksIcon };
    Object.keys(activeSocialLinksCopy).forEach(
      link => (activeSocialLinksCopy[link] = false),
    );
    setHoveredLinksIcon(activeSocialLinksCopy);
  }

  const history = useHistory();

  function onPdfEditorClickHandler() {
    history.push('/pdf-editor');
    history.go(0);
  }

  function onMyDocumentsClickHandler() {
    if (props.user) {
      history.push('/documents');
    } else {
      setShowEnterToApp(true);
    }
  }

  function onPricingClickHandler() {
    if (props.user) {
      history.push('/settings/billing/plans');
    } else {
      history.push('/pricing');
    }
  }

  function onIntegrationsClickHandler() {
    history.push('/google-drive-integration/');
    history.go(0);
  }

  const isHomePage = window.location.pathname === '/';

  return (
    <>
      <div
        className={`footer-wrapper ${
          isHomePage ? 'footer-wrapper--home-page' : ''
        }`}
      >
        <footer className="footer">
          <div className="footer__column">
            <a href="">
              <img
                width="119px"
                height="45px"
                src={
                  isHomePage
                    ? '/imgs/esign-logo.svg'
                    : '/imgs/esign-logo-grey.svg'
                }
                alt="eSign logo"
              />
            </a>
            <div className="social-links">
              <a
                href="https://www.linkedin.com/company/esignature-llc/"
                onMouseEnter={e => hoveredLinksMouseEnterHandler('linkedIn')}
                onMouseLeave={hoveredLinksMouseLeaveHandler}
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.facebook.com/eSignApp"
                onMouseEnter={e => hoveredLinksMouseEnterHandler('faceBook')}
                onMouseLeave={hoveredLinksMouseLeaveHandler}
              >
                <FaceBookIcon />
              </a>
            </div>
          </div>
          <div className="footer__column">
            <h3>Product</h3>
            <ul className="footer__link-list">
              <li>
                <button onClick={onPdfEditorClickHandler}>PDF Editor</button>
              </li>
              <li>
                <button onClick={onMyDocumentsClickHandler}>
                  My Documents
                </button>
              </li>
              <li>
                <button onClick={onPricingClickHandler}>Pricing</button>
              </li>
              <li>
                <button onClick={onIntegrationsClickHandler}>
                  Integrations
                </button>
              </li>
            </ul>
          </div>
          <div className="footer__column">
            <h3>Company</h3>
            <ul className="footer__link-list">
              <li>
                <a href={`${LIBRARY_URL}/support`}>Support</a>
              </li>
              <li>
                <a href={`${LIBRARY_URL}/terms-of-use/`}>Terms of Use</a>
              </li>
              <li>
                <a href={`${LIBRARY_URL}/privacy-policy/`}>Privacy Policy</a>
              </li>
              <li>
                <a href={`${LIBRARY_URL}/ccpa/`}>CCPA</a>
              </li>
            </ul>
          </div>
          {/*<div className="footer__column">*/}
          {/*  <h3>Popular Forms</h3>*/}
          {/*  <ul className="footer__link-list">*/}
          {/*    <li><a href={`${LIBRARY_URL}/rental-lease/`}>Lease Agreements</a></li>*/}
          {/*    <li><a href={`${LIBRARY_URL}/residential-purchase-agreements/`}>Purchase Agreements</a></li>*/}
          {/*    <li><a href={`${LIBRARY_URL}/power-of-attorney/`}>Power of Attorney</a></li>*/}
          {/*    <li><a href={`${LIBRARY_URL}/bill-of-sale/`}>Bill of Sale</a></li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          <div className="footer__column">
            <h3>Get the App</h3>
            <AppStoreLink />
            <div style={{ height: '20px' }} />
            <GoogleStoreLink />
          </div>
        </footer>
        <div className="copyright-block">
          &copy; {new Date().getFullYear()}
          <a href={LIBRARY_URL}>eSign.com.</a>All Rights Reserved.
        </div>
      </div>
      {showEnterToApp && (
        <EnterToApp initTemplate="SIGN_IN" emitCloseModal={closeEnterToApp} />
      )}
    </>
  );
};

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps, {})(Footer);
