import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '../custom/Modal';

import './style.scss';
import { TEMPLATE_FLOW_STEPS } from './constants';

import { animationStateEnumeration } from '../../static';
import GlobalLoader from '../GlobalLoader';
import {
  resetState,
  setProcessTemplate,
  setDocTemplateType,
  setActiveRoleContact as setActiveRoleContactParent,
  setRoles,
  setSigners,
  setUploadedFiles,
  setOrdered,
} from '../../redux/createTemplate/actions';
import {
  toggleUploadDocumentSignNotarizeModal,
  toggleNewTemplateModal,
} from '../../redux/documentController/actions';
import { setDataToSessionStorage } from '../../utils';
import TemplateType from './TemplateType';
import RolesEdit from './RolesEdit';
import RolesEditLink from './RolesEditLink';

const TemplateFlow = props => {
  const history = useHistory();

  useEffect(() => {
    document.body.classList.add('body-stop-scrolling');
    return () => {
      document.body.classList.remove('body-stop-scrolling');
    };
  }, []);

  const amISigner = recipients => {
    return recipients.findIndex(signer => !!signer?.isMe) > -1;
  };
  const [isLoading, setLoading] = useState(false);

  function closeModalHandler(type) {
    if (type === 'close') {
      props.setOrdered(false);
      props.setUploadedFiles([]);
      props.toggleUploadDocumentSignNotarizeModal(false);
    }
    props.setStep(TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE);
    props.setActiveTemplateId('');
    props.setActiveRoleContact(false);
    props.setDocTemplateType('');
    props.resetState();
    props.setRoles([]);
    props.setSigners([]);
    props.closeModalEmitter();

    if (type === 'back') {
      const { setActiveRoleContactParent } = props;
      if (!props.global) {
        history.replace(`/templates`);
      }
      setActiveRoleContactParent(false);
      if (!props.afterSigning && !props.global) {
        props.toggleUploadDocumentSignNotarizeModal(true);
      }
      return;
    }
  }

  const onOpenTemplateForm = roles => {
    setDataToSessionStorage(
      {
        [`${props.activeFileId}`]: roles,
        userId: props.user?._id,
      },
      'roles',
    );
    console.log('on open template form');
    props?.openTemplateForm({
      type: props.templateType,
      roles: roles,
      signatureRequestId: props.signatureRequestId,
    });
    props.closeModalEmitter();
  };

  return (
    <>
      {isLoading && <GlobalLoader />}
      <Modal
        toggle={() => closeModalHandler('close')}
        disableBackdropClick={true}
        freezeBodyScroll={false}
        className="without-animation"
      >
        {props.step === TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE && (
          <TemplateType
            animationState={props.animationState}
            goToPrevStepEmitter={() => {
              closeModalHandler('back');
            }}
            goToNextStepEmitter={type => {
              // props.setAnimationState(animationStateEnumeration.RIGHT_TO_LEFT);
              if (type === 'template_standard') {
                if (!props.global) {
                  history.push(`/templates?template_type=template_standard`);
                }
                // this step for choosing template step first then upload
                // props.toggleNewTemplateModal(false);
                // props.toggleUploadDocumentSignNotarizeModal(true);
                props.setStep(TEMPLATE_FLOW_STEPS.ROLES);
              } else if (type === 'template_link') {
                if (!props.global) {
                  history.push(`/templates?template_type=template_link`);
                }
                // this step for choosing template step first then upload
                // props.toggleUploadDocumentSignNotarizeModal(true);
                // props.toggleNewTemplateModal(false);
                props.setStep(TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE_LINK);
                setTimeout(() => {
                  // props.toggleNewTemplateModal(false);
                  // this step for choosing template step first then upload
                  // props.toggleUploadDocumentSignNotarizeModal(true);
                }, 1000);
              }
            }}
          />
        )}
        {props.step === TEMPLATE_FLOW_STEPS.ROLES && (
          <RolesEdit
            removeRoleConfirm={props.removeRoleConfirm}
            activeFileId={props.activeFileId}
            afterSigning={props.afterSigning}
            ordered={props.ordered}
            animationState={props.animationState}
            activeRoleContact={props.activeRoleContact}
            updatedRemoved={props.updatedRemoved}
            makeSignatureFromTemplate={props.makeSignatureFromTemplate}
            enableBack={!props?.initWhoNeedsToSign}
            goToPrevStepEmitter={() => {
              if (props.afterSigning) {
                closeModalHandler('back');
                return;
              }
              props.setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT);
              props.setStep(TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE);
            }}
            goToNextStepEmitter={onOpenTemplateForm}
          />
        )}
        {props.step === TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE_LINK && (
          <RolesEditLink
            activeFileId={props.activeFileId}
            signatureRequestId={props.signatureRequestId}
            animationState={props.animationState}
            activeRoleContact={props.activeRoleContact}
            enableBack={!props?.initWhoNeedsToSign}
            goToPrevStepEmitter={() => {
              props.setAnimationState(animationStateEnumeration.LEFT_TO_RIGHT);
              props.setStep(TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE);
            }}
            goToNextStepEmitter={onOpenTemplateForm}
          />
        )}
      </Modal>
    </>
  );
};

TemplateFlow.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func,
};

TemplateFlow.defaultProps = {
  // initStep: steps.WHO_NEEDS_TO_SIGN,
};

const mapStateToProps = ({ authUser, createTemplate, documentController }) => {
  const { showUploadDocumentSignNotarizeModal } = documentController;
  const { user } = authUser;
  const {
    requestType,
    areYouSigner,
    areYouSignerOnly,
    isMultipleSigners,
    signers,
    whoNeedsToSign,
    ordered,
    templateType,
    signatureRequestId,
  } = createTemplate;

  return {
    user,
    requestType,
    areYouSigner,
    areYouSignerOnly,
    isMultipleSigners,
    signers,
    whoNeedsToSign,
    ordered,
    templateType,
    signatureRequestId,
    showUploadDocumentSignNotarizeModal,
  };
};

const mapDispatchToProps = {
  toggleUploadDocumentSignNotarizeModal,
  toggleNewTemplateModal,
  setDocTemplateType,
  setProcessTemplate,
  setActiveRoleContactParent,
  setRoles,
  setSigners,
  setUploadedFiles,
  setOrdered,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateFlow);
