import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleOneTapLogin from 'react-google-one-tap-login';

import {
  validateEmail,
  googleOneTapOptions,
  validateFullName,
} from '../../utils';
import { useFormInput } from '../SigningProcess/helpers';

import {
  registerUser,
  completeProfile,
  registerUserGoogle,
  registerUserFacebook,
  loginUserGoogle,
  loginUserFacebook,
  loginUserApple,
  registerUserApple,
} from '../../api/auth';

import {
  loginUserExternally,
  loginUserError,
  loginUserSuccess,
  setIs2FAModalOpen,
} from '../../redux/auth/actions';
import { setUserCompleteToken } from '../../redux/pandaDoc/actions';
import {
  setSenderEmail,
  setSenderFullName,
} from '../../redux/createTemplate/actions';
import {
  service_loginWithEmailPassword,
  service_loginWithGoogleFacebookApple,
} from '../../services';

import CustomInput from '../custom/CustomInput';
import ExternalSignInSignUp from './externaSignInSignUp';
import NotificationManager from '../Notifications/NotificationManager';
import GlobalLoader from '../GlobalLoader';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../static/config';
import moment from 'moment/moment';
import 'moment-timezone';

const ACTIVE_SIGN_UP_TAB = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  ENTER_PASSWORD: 'ENTER_PASSWORD',
};

const SignUp = props => {
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(ACTIVE_SIGN_UP_TAB.ENTER_EMAIL);
  const recaptchaRef = useRef(null);
  const notificationRef = useRef(null);
  const history = useHistory();

  const isComplete = history.location.pathname === '/complete-profile';

  const urlParams = new URLSearchParams(history.location.search);

  let completeToken = urlParams.get('completeToken');
  let initEmail = urlParams.get('email') || '';
  let initFullName = urlParams.get('fullName') || '';

  if (props.userCompleteToken) {
    completeToken = props.userCompleteToken;
    initEmail = props.senderEmail;
    initFullName = props.senderFullName;
  }

  if (props.initData) {
    initEmail = props.initData.email;
  }

  const emailController = useFormInput(
    initEmail,
    value => !validateEmail(value),
  );
  const fullNameController = useFormInput(
    initFullName,
    value => value.trim().length < 1 || !validateFullName(value),
  );
  const passwordController = useFormInput('', value => value.trim().length < 6);

  useEffect(() => {
    window.addEventListener('keydown', onEnterKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, [
    activeTab,
    fullNameController.value,
    emailController.value,
    passwordController.value,
  ]);

  function onEnterKeyDownHandler(e) {
    if (e?.key === 'Enter') {
      if (notificationRef?.current) {
        NotificationManager.remove({ id: notificationRef.current });
        notificationRef.current = null;
      }
      activeTab === ACTIVE_SIGN_UP_TAB.ENTER_EMAIL
        ? emailController.isInvalid
          ? (notificationRef.current = NotificationManager.error(
              'Please enter correct email',
              '',
              3000,
              null,
              null,
              'filled',
            ))
          : setActiveTab(ACTIVE_SIGN_UP_TAB.ENTER_PASSWORD)
        : fullNameController.isInvalid || passwordController.isInvalid
        ? (notificationRef.current = NotificationManager.error(
            'Please enter correct password and full name',
            '',
            3000,
            null,
            null,
            'filled',
          ))
        : registerUserHandler(e);
    }
  }

  function registerUserHandler(e) {
    e.preventDefault();

    removeSenderDataFromRedux();

    const registerData = {
      email: emailController.value,
      password: passwordController.value,
      fullName: fullNameController.value,
      withActivationEmail: true,
      timeZone: moment.tz.guess(),
    };

    let registerUserMethod = registerUser;

    if (isComplete || props.userCompleteToken) {
      registerUserMethod = completeProfile;
      registerData.completeToken = completeToken;
    }

    const isUserDataValid = validateUserData();

    if (isUserDataValid) {
      setShowLoader(true);

      // call sign up + login function
      registerUserMethod(registerData).then(async res => {
        if (res?.message) {
          if (res.errorCode) {
            history.push(`/confirm-email?email=${registerData.email}`);
          } else {
            NotificationManager.error(
              res?.message,
              '',
              3000,
              null,
              null,
              'filled',
            );
          }

          setShowLoader(false);
        } else {
          let recaptchaToken;
          try {
            recaptchaToken = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
          } catch {}

          service_loginWithEmailPassword({
            email: registerData.email,
            password: registerData.password,
            setShowLoader,
            history,
            loginUserError: props.loginUserError,
            loginUserSuccess: props.loginUserSuccess,
            setIs2FAModalOpen: props.setIs2FAModalOpen,
            recaptchaToken,
          });

          window.uipe('track', 'Lead', {
            keys: {
              email: registerData.email,
            },
            lead: {
              fullName: registerData.fullName,
            },
          });

          NotificationManager.success(
            'User created success',
            '',
            4000,
            null,
            null,
            'filled',
          );
        }
      });
    }
  }

  function removeSenderDataFromRedux() {
    props.setSenderEmail('');
    props.setSenderFullName('');
    props.setUserCompleteToken('');
  }

  function validateUserData() {
    let isValid = true;
    if (notificationRef?.current) {
      NotificationManager.remove({ id: notificationRef.current });
      notificationRef.current = null;
    }
    if (emailController.isInvalid) {
      isValid = false;
      notificationRef.current = NotificationManager.error(
        'Please enter email',
        '',
        3000,
        null,
        null,
        'filled',
      );
    } else if (passwordController.isInvalid) {
      isValid = false;
      notificationRef.current = NotificationManager.error(
        'The password mast be at least 6 characters long.',
        '',
        3000,
        null,
        null,
        'filled',
      );
    } else if (fullNameController.isInvalid) {
      isValid = false;
      notificationRef.current = NotificationManager.error(
        'Please enter full name',
        '',
        3000,
        null,
        null,
        'filled',
      );
    }

    return isValid;
  }

  function externalRegistrationHandler(data, provider) {
    setShowLoader(true);

    let token = null;
    let loginFunction = null;
    let registrationFunction = null;

    if (provider === 'GOOGLE') {
      token = data?.tokenId;
      loginFunction = loginUserGoogle;
      registrationFunction = registerUserGoogle;
    }
    if (provider === 'FACEBOOK') {
      token = data?.accessToken;
      loginFunction = loginUserFacebook;
      registrationFunction = registerUserFacebook;
    }
    if (provider === 'APPLE') {
      data.hasOwnProperty('user')
        ? (token = {
            idToken: data?.authorization?.id_token,
            user: data?.user?.name,
          })
        : (token = {
            idToken: data?.authorization?.id_token,
          });
      loginFunction = loginUserApple;
      registrationFunction = registerUserApple;
    }

    if (token) {
      // call sign up + login function
      registrationFunction(token).then(res => {
        if (res?.message) {
          NotificationManager.error(
            res?.message,
            '',
            3000,
            null,
            null,
            'filled',
          );

          setShowLoader(false);
        } else {
          service_loginWithGoogleFacebookApple({
            loginFunction,
            token,
            setShowLoader,
            history,
            loginUserError: props.loginUserError,
            loginUserSuccess: props.loginUserSuccess,
            setIs2FAModalOpen: props.setIs2FAModalOpen,
          });

          if (res.newUser) {
            NotificationManager.success(
              `User created success`,
              '',
              4000,
              null,
              null,
              'filled',
            );
          }
        }

        setShowLoader(false);
      });
    } else {
      NotificationManager.error(
        'Something went wrong. Please, try again later.',
        '',
        3000,
        null,
        null,
        'filled',
      );

      setShowLoader(false);
    }
  }

  return (
    <div className={`${props.animationState}`}>
      {showLoader && <GlobalLoader />}

      <GoogleOneTapLogin
        disabled={props.user}
        googleAccountConfigs={{
          client_id: googleOneTapOptions.client_id,
          state_cookie_domain: googleOneTapOptions.state_cookie_domain,
          context: 'signup',
          auto_select: false,
          callback: (...arg) =>
            externalRegistrationHandler(
              { tokenId: arg[0].credential },
              'GOOGLE',
            ),
        }}
      />

      <div className="enter-to-app__form">
        <h1
          className="title"
          style={{
            fontSize: ACTIVE_SIGN_UP_TAB.ENTER_PASSWORD ? '24px' : '32px',
          }}
        >
          {activeTab === ACTIVE_SIGN_UP_TAB.ENTER_PASSWORD && (
            <button
              onClick={() => {
                passwordController.handleChange({ target: { value: '' } });
                setActiveTab(ACTIVE_SIGN_UP_TAB.ENTER_EMAIL);
              }}
            >
              <svg
                width="10px"
                height="18px"
                viewBox="0 0 10 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Symbols"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Icons/General/Back-arrow"
                    transform="translate(-4.000000, 0.000000)"
                    fill="#000000"
                    fill-rule="nonzero"
                  >
                    <g
                      id="np_arrow-down_2424963_000000"
                      transform="translate(9.000000, 9.000000) rotate(90.000000) translate(-9.000000, -9.000000) translate(-0.000000, 4.000000)"
                    >
                      <path
                        d="M1.08882791,0.000881521619 C0.810882445,0.000881521619 0.528527845,0.107847606 0.317116707,0.317385294 C-0.105705569,0.736464423 -0.105705569,1.42881525 0.317116707,1.84714415 L8.22346181,9.68349623 C8.42747394,9.88570228 8.71577046,10 8.99517302,10 C9.27236156,10 9.548093,9.90035992 9.76688423,9.68349623 L17.6630108,1.85727227 C18.1050402,1.44479518 18.1109632,0.735582901 17.6881428,0.317254004 C17.2653205,-0.101825125 16.5667857,-0.101825125 16.1447204,0.317254004 L9.01006755,7.40262468 L1.86065455,0.316503773 C1.64924342,0.106964208 1.36686989,0 1.08894335,0 L1.08882791,0.000881521619 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          )}
          {activeTab === ACTIVE_SIGN_UP_TAB.ENTER_EMAIL
            ? 'Sign up for free'
            : 'Finish signing up'}
        </h1>
        {activeTab === ACTIVE_SIGN_UP_TAB.ENTER_EMAIL ? (
          <>
            <ExternalSignInSignUp
              onSuccessCallback={externalRegistrationHandler}
              googleButtonText="Continue with Google"
              facebookButtonText="Continue with Facebook"
              appleButtonText="Continue with Apple"
            />
            <p className="esign-modal__separator">
              <span>or sign up using e-mail</span>
            </p>
            <CustomInput
              type="email"
              autoFocus={true}
              placeholder="Enter your email"
              autocomplete="new-email"
              disabled={isComplete}
              value={emailController.value}
              onChange={emailController.handleChange}
            />
          </>
        ) : (
          <>
            <div className="enter-to-app__form--current-email">
              <b>Email:&nbsp;</b>
              <span>{emailController.value}</span>
              <b
                onClick={() => {
                  passwordController.handleChange({ target: { value: '' } });
                  setActiveTab(ACTIVE_SIGN_UP_TAB.ENTER_EMAIL);
                }}
                className="enter-to-app__form--edit-email"
              >
                Edit email
              </b>
            </div>
            <CustomInput
              type="text"
              placeholder="Enter full name"
              autocomplete="new-name"
              autoFocus={true}
              value={fullNameController.value}
              onChange={fullNameController.handleChange}
            />
            <CustomInput
              type="password"
              placeholder="Enter your password"
              autocomplete="new-password"
              value={passwordController.value}
              onChange={passwordController.handleChange}
            />
          </>
        )}
        <button
          type="submit"
          className="esign-button esign-button--blue"
          disabled={
            activeTab === ACTIVE_SIGN_UP_TAB.ENTER_EMAIL
              ? emailController.isInvalid
              : fullNameController.isInvalid || passwordController.isInvalid
          }
          onClick={e => {
            activeTab === ACTIVE_SIGN_UP_TAB.ENTER_EMAIL
              ? setActiveTab(ACTIVE_SIGN_UP_TAB.ENTER_PASSWORD)
              : registerUserHandler(e);
          }}
        >
          {activeTab === ACTIVE_SIGN_UP_TAB.ENTER_PASSWORD
            ? 'Sign Up'
            : 'Continue'}
        </button>
        {activeTab === ACTIVE_SIGN_UP_TAB.ENTER_EMAIL && (
          <div className="enter-to-app__footer">
            Already have an account?
            <button
              type="button"
              className="enter-to-app__btn"
              onClick={props.goToSignInTemplateEmitter}
            >
              Log in
            </button>
          </div>
        )}
        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          size={'invisible'}
          ref={recaptchaRef}
        />
      </div>
    </div>
  );
};

SignUp.propTypes = {
  goToSignInTemplateEmitter: PropTypes.func.isRequired,
  initData: PropTypes.shape({
    email: PropTypes.string,
  }),
  showSaveDocumentCopyFooter: PropTypes.bool,
  goToAllDonePageEmitter: PropTypes.func,
};

SignUp.defaultProps = {
  showSaveDocumentCopyFooter: false,
};

const mapStateToProps = ({ authUser, createTemplate, pandaDoc }) => {
  const { user } = authUser;
  const { senderEmail, senderFullName } = createTemplate;
  const { userCompleteToken } = pandaDoc;
  return { user, senderEmail, senderFullName, userCompleteToken };
};

const mapDispatchToProps = {
  setSenderEmail,
  setSenderFullName,
  setUserCompleteToken,
  loginUserExternally,
  loginUserError,
  loginUserSuccess,
  setIs2FAModalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
