import React from 'react';

import { ReactComponent as TemplateIcon } from '../../../assets/img/template-filled_18x18.svg';
import { chooseIconByExtension } from '../../../utils';

import Modal from '../../custom/Modal';

import './style.scss';

const MoveElementToTrashModal = props => {
  const activeType = {
    folder: 'folder',
    file: 'file',
    template: 'template',
    template_link: 'template',
  };

  const activeTemplate =
    props?.element?.type === 'template' ||
    props?.element?.type === 'template_link';

  return (
    <Modal toggle={props.closeModalEmitter}>
      <div className="esign-modal move-file-to-folder-modal">
        <h1 className="esign-modal__title">
          Delete {activeType[props?.element?.type] || 'File'}
        </h1>

        <p className="esign-modal__subtitle">
          {activeTemplate ? (
            <TemplateIcon />
          ) : (
            chooseIconByExtension(props.element.extension || props.element.type)
          )}{' '}
          {props.element.name}
        </p>

        <hr />

        <p className="esign-modal__description">
          Are you sure you want to delete this{' '}
          {activeType[props?.element?.type] || 'File'}?
        </p>

        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={props.closeModalEmitter}
          >
            Cancel
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={() => props.onDeleteBtnClick()}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MoveElementToTrashModal;
