export const Notifications = [
  {
    id: 0,
    time: '1 hour ago',
    type: 'viewed',
    name: 'Yaroslav Tsisar',
    description: 'Yaroslav Tsisar viewed the document',
    file: 'Simple Lease Agreement.pdf',
  },
  {
    id: 1,
    time: '14 hours ago',
    type: 'completed',
    name: '',
    description: 'Your sign request has been completed.',
    file: 'Rental Application.pdf',
  },
  {
    id: 2,
    time: '14 hours ago',
    type: 'signed',
    name: 'Kari Granleese',
    description: 'Kari Granleese signed the document',
    file: 'Rental Application.pdf',
  },
  {
    id: 3,
    time: '13 hours ago',
    type: 'signed',
    name: 'James Smith',
    description: 'James Smith signed the document',
    file: 'Rental Application.pdf',
  },
  {
    id: 4,
    time: '2 days ago',
    type: 'voided',
    description: 'Your sign request has been voided.',
    file: 'Frank-C-Florida-Employment-Agreement-Te…',
  },
  {
    id: 5,
    time: '2 days ago',
    type: 'voided-user',
    description: 'Frank Calzone voided the document',
    file: 'Frank-C-Florida-Employment-Agreement-Te…',
  },
];
