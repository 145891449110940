import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropTarget } from 'react-dnd';
import { PageDetectionMethod } from '../../../utils';

const LinkItem = ({
  index,
  id,
  title,
  icon,
  url,
  exact,
  click,
  className,
  activeClassName,
  separatorIcon,
  innerWrapperTag,
  canDrop,
  connectDropTarget,
  isOver,
  isSomeElementDragging,
}) => {
  return connectDropTarget(
    <div
      style={{
        display:
          typeof title === 'string' &&
          innerWrapperTag !== 'p' &&
          PageDetectionMethod.Settings() &&
          !title.includes('Dashboard') &&
          !title.includes('Settings')
            ? `none`
            : `block`,
        width: '100%',
        opacity: isSomeElementDragging && !canDrop ? '0.5' : '1',
      }}
    >
      <NavLink
        key={index}
        to={url}
        exact={exact}
        activeClassName={`${activeClassName} ${
          isOver ? 'item_link--over' : ''
        }`}
        className={`${className} ${isOver ? 'item_link--over' : ''} ${
          icon !== null ? 'item_link--has-icon' : ''
        }`}
        onClick={click}
      >
        {React.createElement(innerWrapperTag, null, separatorIcon, icon, title)}
      </NavLink>
    </div>,
  );
};

const documentTarget = {
  drop(props, monitor) {
    const { sourceId, sourceExtension } = monitor.getItem();
    const { id, onDropElementHandler } = props;

    return onDropElementHandler(sourceId, id, sourceExtension);
  },
  canDrop(props, monitor) {
    const { canDrop } = props;

    return canDrop;
  },
};

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget('document', documentTarget, collectTarget)(LinkItem);
