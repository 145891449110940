import { combineReducers } from 'redux';
import authUser from './auth/reducer';
import myDocs from './myDocuments/reducer';
import navBar from './navbar/reducer';
import createTemplate from './createTemplate/reducer';
import contact from './contact/reducer';
import pandaDoc from './pandaDoc/reducer';
import documentController from './documentController/reducer';

const reducers = combineReducers({
  authUser,
  myDocs,
  navBar,
  createTemplate,
  pandaDoc,
  documentController,
  contact,
});

export default reducers;
