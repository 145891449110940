import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as GoogleStoreIcon } from '../../assets/img/download-in-google-store.svg';

const initBackground = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

const AppStoreLink = props => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.esign.esign"
      target="_blank"
    >
      <GoogleStoreIcon />
    </a>
  );
};

AppStoreLink.propTypes = {
  background: PropTypes.oneOf(Object.keys(initBackground)),
};

AppStoreLink.defaultProps = {
  background: initBackground.DARK,
};

export default AppStoreLink;
