import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './style.scss';
import { setDocTemplateType } from '../../redux/createTemplate/actions';

import RadioGroup from '../custom/RadioGroup';

// Icons
import { ReactComponent as StandardIcon } from '../../assets/img/new_icons/template-standard_18x18.svg';
import { ReactComponent as LinkIcon } from '../../assets/img/new_icons/template-link_18x18.svg';

const typeOptions = [
  {
    value: 'template_standard',
    label: (
      <div className={'esign-radio-button__label-custom'}>
        <div>
          <span>Standard Template</span>
          <span>
            Create a reusable document that can be sent to multiple recipients.
          </span>
        </div>
        <StandardIcon />
      </div>
    ),
  },
  {
    value: 'template_link',
    label: (
      <div className={'esign-radio-button__label-custom'}>
        <div>
          <span>Template Link</span>
          <span>
            Send or publish a URL to collect bulk form submissions. Maximum 1
            recipient.
          </span>
        </div>
        <LinkIcon />
      </div>
    ),
  },
];

const TemplateType = props => {
  function chooseTypeHandler(event) {
    props.setDocTemplateType(event.value);
  }

  function goToNextStep() {
    if (props.templateType) {
      props.goToNextStepEmitter(props.templateType);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyDownHandler);
    };
  }, []);

  const onEnterKeyDownHandler = e => {
    if (e.keyCode === 13) {
      goToNextStep();
    }
  };

  return (
    <div
      className={`esign-modal template-step-modal template-step-modal__type-step ${props.animationState}`}
    >
      <h3 className="esign-modal__title">Select a Template Type</h3>
      <RadioGroup
        name="template-type"
        onChange={chooseTypeHandler}
        options={typeOptions}
        defaultChecked={props.templateType}
      />
      <div className="esign-modal__footer">
        <button
          className="esign-button esign-button--light"
          onClick={props.goToPrevStepEmitter}
        >
          Back
        </button>
        <button
          className="esign-button esign-button--blue"
          disabled={!props?.templateType}
          onClick={goToNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

TemplateType.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func,
};

TemplateType.defaultProps = {
  showBackButton: false,
  closeModalEmitter: () => {},
  goToPrevStepEmitter: () => {},
  goToNextStepEmitter: () => {},
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const { templateType } = createTemplate;

  return { user, templateType };
};

const mapDispatchToProps = {
  setDocTemplateType,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateType);
