import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import {
  get2FASettings,
  loginViaEmailCode,
  request2FAEmailCode,
  loginViaAuthenticatorCode,
} from '../../api/auth';

import { setAuthorization } from '../../api';

import { useFormInput } from '../SigningProcess/helpers';

import { service_getCurrentUserInfo } from '../../services';
import { loginUserSuccess } from '../../redux/auth/actions';

import CustomInput from '../custom/CustomInput';
import NotificationManager from '../Notifications/NotificationManager';
import GlobalLoader from '../GlobalLoader';

const SignIn2FA = props => {
  const [authOption, setAuthOption] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  // const [selectedAuthOption, setSelectedAuthOption] = useState(null);

  const history = useHistory();

  // useEffect(() => {
  //   get2FASettings().then((res) => {
  //     if (res.enabled) {
  //       const authOption = [];
  //       if (res.authenticator !== null) {
  //         authOption.push({ value: 'authenticator', label: 'Authenticator App' });
  //       }
  //       if (res.email !== null) {
  //         authOption.push({ value: 'email', label: 'Email' });
  //       }
  //       setAuthOption(authOption);
  //     }
  //   });
  // }, []);

  const otpTokenController = useFormInput(
    '',
    value => value.trim().length !== 6,
    { maxLength: 6 },
  );

  function onLoginViaAuthenticatorHandler(e) {
    setShowLoader(true);
    e.preventDefault();

    const { value: otpToken } = otpTokenController;
    loginViaAuthenticatorCode(otpToken).then(res => {
      if (res?.message) {
        NotificationManager.error(
          'OTP code is incorrect.',
          '',
          3000,
          null,
          null,
          'filled',
        );
      } else {
        setAuthorization(res.token);

        service_getCurrentUserInfo({
          setShowLoader,
          loginUserSuccess: props.loginUserSuccess,
          history,
        });

        props.onLoginEmitter();
      }

      setShowLoader(false);
    });
  }

  const emailCodeController = useFormInput(
    '',
    value => value.trim().length !== 6,
    { maxLength: 6 },
  );

  function onRequestEmailCodeHandler() {
    request2FAEmailCode().then(res => {
      if (res?.success) {
        NotificationManager.success(
          'Check your email for login code',
          '',
          3000,
          null,
          null,
          'filled',
        );
      }
    });
  }

  function onLoginViaEmailHandler(e) {
    setShowLoader(true);
    e.preventDefault();

    const { value: emailCode } = emailCodeController;
    loginViaEmailCode(emailCode).then(res => {
      if (res?.message) {
        NotificationManager.error(
          'OTP code is incorrect.',
          '',
          3000,
          null,
          null,
          'filled',
        );

        setShowLoader(false);
      } else {
        setAuthorization(res.token);

        service_getCurrentUserInfo({
          setShowLoader,
          loginUserSuccess: props.loginUserSuccess,
          history,
        });

        props.onLoginEmitter();
      }
    });
  }

  return (
    <>
      {showLoader && <GlobalLoader />}

      <form className="enter-to-app__form">
        {/*<p className="text-field">Choose authentication method</p>*/}
        {/*<Select*/}
        {/*  className="esign-select-list"*/}
        {/*  classNamePrefix="esign-select-list"*/}
        {/*  options={authOption}*/}
        {/*  value={selectedAuthOption}*/}
        {/*  onChange={setSelectedAuthOption}*/}
        {/*/>*/}

        <CustomInput
          type="number"
          placeholder="Authenticator app code"
          value={otpTokenController.value}
          onChange={otpTokenController.handleChange}
        />
        {otpTokenController.value.trim().length !== 0 && (
          <button
            type="submit"
            disabled={otpTokenController.isInvalid}
            className="esign-button esign-button--blue"
            onClick={onLoginViaAuthenticatorHandler}
          >
            Login
          </button>
        )}
        {/*{selectedAuthOption?.value === 'email' && (*/}
        {/*  <>*/}
        {/*    <CustomInput*/}
        {/*      type="text"*/}
        {/*      placeholder="Email code"*/}
        {/*      value={emailCodeController.value}*/}
        {/*      onChange={emailCodeController.handleChange}*/}
        {/*    />*/}
        {/*    <br />*/}
        {/*    <button*/}
        {/*      type="button"*/}
        {/*      className="esign-button esign-button--full-width"*/}
        {/*      disabled={emailCodeController.value.length > 0}*/}
        {/*      onClick={onRequestEmailCodeHandler}*/}
        {/*    >*/}
        {/*      Request code*/}
        {/*    </button>*/}
        {/*    {emailCodeController.value.trim().length !== 0 &&*/}
        {/*      <button*/}
        {/*        type="submit"*/}
        {/*        disabled={emailCodeController.isInvalid}*/}
        {/*        className="esign-button esign-button--blue"*/}
        {/*        onClick={onLoginViaEmailHandler}*/}
        {/*      >*/}
        {/*        Login*/}
        {/*      </button>*/}
        {/*    }*/}
        {/*  </>*/}
        {/*)}*/}
      </form>
    </>
  );
};

SignIn2FA.propTypes = {
  onLoginEmitter: PropTypes.func,
};

const mapDispatchToProps = {
  loginUserSuccess,
};

export default connect(null, mapDispatchToProps)(SignIn2FA);
