import React from 'react';
import './style.scss';

const Checkbox = ({ className, label, disabled, style, ...rest }) => {
  return (
    <label
      className={`checkbox ${
        disabled ? 'checkbox--disabled' : ''
      } ${className}`}
      style={style}
    >
      <input type="checkbox" {...rest} />
      <span className="checkbox__mark" />
      {label && <span className="checkbox__label">{label}</span>}
    </label>
  );
};

export default Checkbox;
