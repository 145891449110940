import { useEffect, useState } from 'react';
import { validateEmail, validateFullName } from '../../utils';

/**
 * Custom hook to work with text input.
 * @param {string} initialValue.
 * @param {function} [isInvalidCheckFn]
 * @param {{maxLength: number}} [options]
 */
export function useFormInput(initialValue, isInvalidCheckFn, options = {}) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    let { value } = e.target;

    if ('maxLength' in options) {
      if (value.length > options.maxLength) {
        value = value.substring(0, options.maxLength);
      }
    }
    setValue(value);
  }

  let isInvalid = false;
  if (typeof isInvalidCheckFn === 'function') {
    isInvalid = isInvalidCheckFn(value);
  }

  return { value, handleChange, setValue, isInvalid };
}

/**
 * Custom hook to work with modals
 * @param {*} modalRef
 * @param {Array} dependencies
 */
export function useModalWithFreezePosition(modalRef, dependencies = []) {
  const [modalStyle, setModalStyle] = useState({});

  useEffect(() => {
    const { bottom } = modalRef.current.getBoundingClientRect();

    const { scrollHeight, clientHeight } = modalRef.current;
    // modal has scrollbar if setModalHeight function has been already called
    // and modal has calculated height
    const hasModalVerticalScrollBar = scrollHeight > clientHeight;

    if (bottom > window.innerHeight || hasModalVerticalScrollBar) {
      setModalHeight();
    } else {
      setModalStyle({});
    }

    function setModalHeight() {
      // find amount of pixels which don't fit in viewport
      const deltaY = bottom - window.innerHeight;
      // need for adding some space between bottom side of modal and viewport
      const bottomOffset = 20;

      const { offsetHeight: modalHeight } = modalRef.current;
      const height = modalHeight - deltaY - bottomOffset + 'px';

      setModalStyle({ height });
    }
  }, dependencies);

  return modalStyle;
}

/**
 * @typedef {Object} SignerType
 * @property {string} email
 * @property {string} fullName
 * @property {boolean} isEmailValid
 * @property {boolean} isNameValid
 * @property {boolean} isChanged
 * @property {boolean} isDisabled
 */

/**
 * Custom hook to work with SignersForm component in Sign/Notarize modals.
 * @param {SignerType[]} initialSigners
 */
export function useSigners(initialSigners = []) {
  const [signers, setSigners] = useState(initialSigners);

  function addSigner(signer) {
    setSigners([...signers, signer]);
  }

  function removeSigner(signerIndex) {
    const nextSigners = signers.filter((s, index) => index !== signerIndex);
    setSigners(nextSigners);
  }

  function setSignerFullName(fullName, signerIndex) {
    const nextSigners = signers.map((s, ind) => {
      if (ind === signerIndex) {
        const isNameValid =
          fullName.trim() !== '' && validateFullName(fullName);
        return { ...s, fullName, isNameValid, isChanged: true };
      }
      return s;
    });

    setSigners(nextSigners);
  }

  function setSignerEmail(email, signerIndex) {
    const nextSigners = signers.map((s, ind) => {
      if (ind === signerIndex) {
        const isEmailValid = validateEmail(email);
        return { ...s, email, isEmailValid, isChanged: true };
      }
      return s;
    });

    setSigners(nextSigners);
  }

  return {
    signers,
    addSigner,
    removeSigner,
    setSignerFullName,
    setSignerEmail,
  };
}

/**
 * @param {SignerType[]} signers
 */
export function getSignersValidationStatus(signers = []) {
  const notEmptySigners = signers.filter(
    s => s.email.length > 0 || s.fullName.length > 0,
  );
  const hasInvalidSigner = notEmptySigners.some(s => {
    return (
      validateEmail(s.email) === false ||
      s.fullName.trim() === '' ||
      s.fullName.length > 50 ||
      !validateFullName(s.fullName)
    );
  });

  return {
    hasInvalidSigner,
    notEmptySignersAmount: notEmptySigners.length,
  };
}

/**
 * @param {boolean} isSenderChecked.
 * @param {SignerType[]} signers
 */
export function isSignersInvalid(isSenderChecked, signers = []) {
  const {
    hasInvalidSigner,
    notEmptySignersAmount,
  } = getSignersValidationStatus(signers);

  if (isSenderChecked) {
    return hasInvalidSigner;
  }

  if (notEmptySignersAmount < 1) {
    return true;
  }

  return hasInvalidSigner;
}

/**
 * @param {SignerType[]} signers
 */
export function getNotEmptyUniqueSigners(signers = []) {
  const notEmptySigners = signers
    .filter(s => s.email !== '' && s.fullName !== '')
    .map(s => ({ email: s.email, fullName: s.fullName }));

  const uniqueSigners = notEmptySigners.filter((signer, index, self) => {
    return self.map(s => s.email).indexOf(signer.email) === index;
  });

  return uniqueSigners;
}
