import { api } from './index';

export const removeMultipleFiles = (fileIds, folderIds) => {
  return api.post({
    path: '/bulk/remove',
    body: { fileIds, folderIds },
  });
};

export const trashMultipleFiles = (fileIds, folderIds) => {
  return api.post({
    path: '/bulk/trash',
    body: { fileIds, folderIds },
  });
};

export const restoreDocument = (fileIds, folderIds) => {
  return api.post({
    path: '/bulk/restore',
    body: { fileIds, folderIds },
  });
};

export const clearTrash = () =>
  api.post({
    path: `/filesystem/trash/clear`,
  });

export const removeUserFromSharing = (type, filesystemElementId) =>
  api.delete({
    path: `/filesystem/${type}/${filesystemElementId}/shared`,
  });
