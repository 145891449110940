import React, { Fragment, Suspense, lazy } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import WebFont from 'webfontloader';

import GlobalLoader from './components/GlobalLoader';
import Loader from './components/Loader';
import AppLayout from './layout';

import { SETTINGS_ROUTES_WITH_BREAD_CRUMBS } from './pages/Settings/routes';

import { PageDetectionMethod } from './utils';

import { getTokenFromCookie, setCookie } from './api';
import { prologToken } from './api/auth';
import { getSignatureRequest } from './api/signature';
import { API_UPLOAD_FILE_FROM_WP } from './pages/Editor/api';

// redux actions
import {
  getCurrentUser,
  logoutUser,
  setIs2FAModalOpen,
} from './redux/auth/actions';
import { setContacts, setLoadingContacts } from './redux/contact/actions';
import {
  toggleNewTemplateModal,
  toggleShowContactModal,
  toggleUploadDocumentSignNotarizeModal,
} from './redux/documentController/actions';
import { updateWaitForMeDocuments } from './redux/myDocuments/actions';
import { resizeDeviceWidth } from './redux/navbar/actions';
import {
  setSenderFullName,
  setSenderEmail,
  setRoles,
  setSigners,
  setLoadingTemplate,
  setActiveFileId,
} from './redux/createTemplate/actions';
import {
  setTemplateType,
  setUserCompleteToken,
  setUserProfileCompletedStatus,
} from './redux/pandaDoc/actions';

import './style/index.scss';
import './font.css';
import SigningProcessV2 from './components/SigningProcessV2';
import PandaDocIframe from './pages/PandaDocIframe';
import Banner from './components/Banner';
import { getFileUrlPublic } from './api/file';
import { searchContact as getAllContact } from './api/contact';
import { DOMAIN_URL } from './static/config';
import { animationStateEnumeration } from './static';
import NotificationManager from './components/Notifications/NotificationManager';
import ModalRecipientRemove from './components/Modals/ModalRecipientRemove';
import { TEMPLATE_FLOW_STEPS } from './components/TemplateFlow/constants';
import TemplateFlow from './components/TemplateFlow';
import ContactListModal from './components/Modals/ContactListModal';

// dynamic lazy import pages and components
const Home = lazy(() => import('./pages/Home'));
const Editor = lazy(() => import('./pages/Editor'));
const SharedFile = lazy(() => import('./pages/SharedFile'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const MyDocuments = lazy(() => import('./pages/MyDocuments'));
const SharedWithMe = lazy(() => import('./pages/SharedWithMe'));
const Starred = lazy(() => import('./pages/Starred'));
const Trash = lazy(() => import('./pages/Trash'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Pricing = lazy(() => import('./pages/Pricing'));
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail'));
const AfterSigning = lazy(() => import('./pages/AfterSigning'));
const ExpiredLink = lazy(() => import('./pages/ExpiredLink'));
const VoidedLink = lazy(() => import('./pages/VoidedLink'));
const DocumentProcessing = lazy(() => import('./pages/DocumentProcessing'));
const EnterToApp = lazy(() => import('./components/EnterToApp'));
const SigningProcess = lazy(() => import('./components/SigningProcess'));
const PrepareForm = lazy(() => import('./pages/PrepareForm'));
const SigningProcessPage = lazy(() => import('./pages/SigningProcess'));
const NotificationContainer = lazy(() =>
  import('./components/Notifications/NotificationContainer'),
);
const Templates = lazy(() => import('./pages/Templates'));
const Mailbox = lazy(() => import('./pages/Mailbox'));
const DetailView = lazy(() => import('./pages/DetailView'));

const withLayout = (Component, props = {}) => (
  <AppLayout>
    <Component {...props} />
  </AppLayout>
);

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        withLayout(Component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { prevPath: window.location.pathname },
          }}
        />
      )
    }
  />
);

class App extends React.Component {
  state = {
    showEnterToApp: false,
    enterToAppInitTemplate: 'SAVE_DOCUMENT_COPY',
    showSigningProcessModal: false,
    showSignRequestModal: false,
    fileIdForSign: null,
    activeRoleContact: false,
    step: TEMPLATE_FLOW_STEPS.TEMPLATE_TYPE,
    animationState: animationStateEnumeration.STATIC,
    signatureRequestId: '',
    token: getTokenFromCookie(),
    searchContact: '',
    selectedContact: null,
    fieldRemoved: 0,
    showRecipientRemove: {},
    updatedRemoved: false,
  };

  INACTIVE_USER_TIME_THRESHOLD = 10 * 60 * 1000; // 10 minute
  USER_ACTIVITY_THROTTLER_TIME = 5 * 60 * 1000; // 5 minute

  userActivityThrottlerTimeout = null;
  userActivityTimeout = null;

  activateActivityTracker = () => {
    window.addEventListener('mousemove', this.userActivityThrottler);
    window.addEventListener('scroll', this.userActivityThrottler);
    window.addEventListener('keydown', this.userActivityThrottler);
    window.addEventListener('resize', this.userActivityThrottler);
  };

  disableMobilePinch = () => {
    // This is only working for ios safari
    document.addEventListener('gesturestart', this.preventGesture);
    document.addEventListener('gesturechange', this.preventGesture);
    document.addEventListener('gestureend', this.preventGesture);
  };

  resetUserActivityTimeout = () => {
    clearTimeout(this.userActivityTimeout);
    this.userActivityTimeout = setTimeout(() => {
      this.inactiveUserAction();
    }, this.INACTIVE_USER_TIME_THRESHOLD);
  };

  userActivityThrottler = () => {
    if (!this.userActivityThrottlerTimeout) {
      this.userActivityThrottlerTimeout = setTimeout(() => {
        this.resetUserActivityTimeout();

        const { authUserDetails } = this.props;

        if (authUserDetails) {
          prologToken().then(res => {
            if (res?.token) {
              setCookie(res.token);
            }
          });
        }

        clearTimeout(this.userActivityThrottlerTimeout);
        this.userActivityThrottlerTimeout = null;
      }, this.USER_ACTIVITY_THROTTLER_TIME);
    }
  };

  preventGesture = e => {
    e.preventDefault();
  };

  inactiveUserAction = () => {
    // this.props.logoutUser();
    // this.props.history.push('/');
  };

  componentDidMount = async () => {
    const { history, location, getCurrentUser } = this.props;

    this.activateActivityTracker();
    if (!PageDetectionMethod.LicenseInvite()) {
      // License Invite deep link is using token but this is not document token.
      this.openPandaDocIframeFromUrl();
    }
    this.openSigningProccess();
    this.completeUserProfile();
    this.disableMobilePinch();

    const userToken = getTokenFromCookie();
    userToken && (await getCurrentUser(history, location));

    if (process.env.REACT_APP_LIBRARY_URL === 'https://esign.com') {
      const script = document.createElement('script');

      script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-167879132-1';
      script.async = true;

      document.body.appendChild(script);
    }

    window.addEventListener('resize', this.handleWindowSizeChange);

    // Preload Custom fonts
    WebFont.load({
      custom: {
        families: [
          'Times New Roman',
          'Savoye LET',
          'SignPainter',
          'SnellRoundhand',
          'AppleChancery',
          'Noteworthy',
          'SFProText',
        ],
        urls: ['./font.css'],
      },
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('mousemove', this.userActivityThrottler);
    window.removeEventListener('scroll', this.userActivityThrottler);
    window.removeEventListener('keydown', this.userActivityThrottler);
    window.removeEventListener('resize', this.userActivityThrottler);
    window.removeEventListener('resize', this.handleWindowSizeChange);

    document.removeEventListener('gesturestart', this.preventGesture);
    document.removeEventListener('gesturechange', this.preventGesture);
    document.removeEventListener('gestureend', this.preventGesture);

    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  };

  handleWindowSizeChange = () => {
    const { resizeDeviceWidth } = this.props;

    resizeDeviceWidth(window.innerWidth);
  };

  openPandaDocIframeFromUrl = () => {
    // ignore iFrame for new signing flow
    if (
      !['/prepare-form', '/template-form', '/sign/v2', '/template/v2'].includes(
        this.props.location.pathname,
      )
    ) {
      const params = new URLSearchParams(this.props.location.search);
      const token = params.get('token');
      const fileId = params.get('fileId');

      if (token) {
        this.props.setTemplateType({
          type: 'GET_IFRAME_URL_BY_PUBLIC_TOKEN',
          iframeUrlAccessKey: token,
          fileId,
        });
      }
    }
  };

  openSigningProccess = () => {
    if (PageDetectionMethod.CreateSignature()) {
      const urlParams = new URLSearchParams(this.props.location.search);
      const fileUrl = atob(urlParams.get('hash'));

      if (fileUrl) {
        API_UPLOAD_FILE_FROM_WP({ url: fileUrl }).then(res => {
          this.setState(
            {
              showSigningProcessModal: true,
              fileIdForSign: res.id,
            },
            () => {
              if (!this.props.user) {
                this.props.history.push('/');
              }
            },
          );
        });
      } else {
        this.props.history.push('/');
      }
    }
  };

  completeUserProfile = () => {
    const params = new URLSearchParams(this.props.location.search);
    const completeToken = params.get('completeToken');

    if (completeToken !== null) {
      this.props.setUserCompleteToken(completeToken);
      this.props.setSenderEmail(params.get('email'));
      this.props.setSenderFullName(params.get('fullName'));
    }
  };

  removeRoleConfirm = async (role, index) => {
    this.props.setLoadingTemplate(true);
    let needFieldRemove = false;
    if (this.props.activeFields.length > 0) {
      const fieldRemoved = (this.props.activeFields || []).filter(
        f => f.role === role.role,
      ).length;

      if (fieldRemoved > 0) {
        this.setState({
          fieldRemoved,
          showRecipientRemove: {
            deleted: { role, index },
          },
        });
      }
      needFieldRemove = fieldRemoved > 0;
    }

    this.props.setLoadingTemplate(false);
    return needFieldRemove;
  };

  deleteRecipient = showRecipientRemove => {
    this.props.setLoadingTemplate(true);
    if (showRecipientRemove && Object.keys(showRecipientRemove).length > 0) {
      const newRoles = this.props.roles.filter(
        f => f.role !== showRecipientRemove.deleted.role.role,
      );
      this.props.setRoles(newRoles);
      this.props.setLoadingTemplate(false);
      this.setState(
        {
          updatedRemoved: true,
          showRecipientRemove: {},
        },
        () => {
          // this.props.toggleNewTemplateModal(false);
        },
      );
      setTimeout(() => {
        this.setState({ updatedRemoved: false });
      }, 10000);
    }
  };

  setShowModalByName = (modalName, show) => {
    this.setState({ [modalName]: show });
  };

  onPandaDocCloseHandler = () => {
    this.props.updateWaitForMeDocuments(true);

    if (!this.props.isUserProfileCompleted) {
      this.setShowModalByName('showEnterToApp', true);
      this.setState({ enterToAppInitTemplate: 'SAVE_DOCUMENT_COPY' });
      this.props.setUserProfileCompletedStatus(true);
    }

    if (this.props.userCompleteToken) {
      this.setShowModalByName('showEnterToApp', true);
      this.setState({ enterToAppInitTemplate: 'SAVE_DOCUMENT_COPY' });
    }
  };

  openTemplateForm = async (type, roles, signatureRequestId) => {
    this.props.setLoadingTemplate(true);
    if (signatureRequestId) {
      const activeUrl = `/template-form?type=${type}&token=${this.state.token}&documentId=${this.props.activeFileId}&signatureRequest=true&signatureRequestId=${signatureRequestId}`;
      this.props.history.push(activeUrl);
      this.props.setLoadingTemplate(false);
      return;
    }
    this.props.history.push(
      `/template-form?type=${type}&token=${this.state.token}&documentId=${this.props.activeFileId}`,
    );
    this.props.setLoadingTemplate(false);
  };

  onSelectedContact = value => () => {
    this.setState({
      selectedContact: value,
    });
  };

  selectContactandUpdateRole = roleContact => {
    const selectedContactItem = this.props.contacts.find(
      c => c.value === this.state.selectedContact,
    );
    const newRoles = this.props.roles.map(r =>
      r.role === roleContact.role
        ? {
            ...r,
            prefillName: selectedContactItem.name,
            prefillEmail: selectedContactItem.email,
            isContactNameTouched: false,
            isPrefillNameTouched: true,
            isPrefillEmailTouched: true,
            isPrefillEmailValid: true,
            isPrefillNameValid: true,
            color: roleContact.color,
          }
        : { ...r },
    );
    this.props.setRoles(newRoles);
    this.setState(
      {
        activeRoleContact: true,
        selectedContact: null,
        searchContact: '',
      },
      () => {
        this.searchActiveContact('');
      },
    );
    this.props.toggleNewTemplateModal(true);
    this.props.toggleShowContactModal(false);
  };

  selectContactandUpdateSigner = signerContact => {
    const selectedContactItem = this.props.contacts.find(
      c => c.value === this.state.selectedContact,
    );
    const newSigners = this.props.signers.map(r =>
      r.role === signerContact.role
        ? {
            ...r,
            fullName: selectedContactItem.fullName,
            email: selectedContactItem.email,
            isNameValid: true,
            isNameTouched: false,
            isEmailValid: true,
            isEmailTouched: false,
          }
        : { ...r },
    );
    this.props.setSigners(newSigners);
    this.setState(
      {
        activeSignerContact: true,
        selectedContact: null,
        searchContact: '',
      },
      () => {
        this.searchActiveContact('');
      },
    );
    // this.props.toggleUploadDocumentSignNotarizeModal(false);
    this.props.toggleShowContactModal(false);
  };

  searchActiveContact = (search, order = 'desc') => {
    const params = new URLSearchParams({
      order,
      query: search,
      sortBy: 'createdAt',
    }).toString();

    getAllContact(params)
      .then(data => {
        const newContacts = data.contacts.map(c => {
          return {
            ...c,
            name: c.fullName,
            value: c.id,
          };
        });
        this.props.setLoadingContacts(false);
        this.props.setContacts(newContacts);
      })
      .catch(error => {
        NotificationManager.error(
          error?.message || 'Some problem with searching contact',
          '',
          3000,
          null,
          null,
          'filled',
        );
      });
  };

  handleOpenPrepareForm = token => {
    this.props.history.push(`/prepare-form?token=${token}`);
    this.setState({
      showSigningProcessModal: false,
      fileIdForSign: null,
    });
  };

  handleOpenSelfSigning = () => {
    this.setState(
      {
        showLoader: true,
      },
      () => {
        getFileUrlPublic(this.state.fileIdForSign).then(res => {
          this.setState({
            showSigningProcessModal: false,
          });
          if (res?.url) {
            localStorage.setItem('fileIdByEditor', this.state.fileIdForSign);

            const encodeUrl = btoa(res.url);
            this.props.history.push(
              `/editor?hash=${encodeUrl}&guide=false&fromLanding=true&redirectUrl=${DOMAIN_URL}/documents&signing=true`,
            );
          } else {
            this.setState(
              {
                showLoader: false,
              },
              () => {
                NotificationManager.error(
                  res?.message ||
                    'Something went wrong. Please, try again later.',
                  '',
                  4000,
                  null,
                  null,
                  'filled',
                );
              },
            );
          }
        });
      },
    );
  };

  render() {
    const {
      authUserDetails: authUser,
      loading,
      loadingTemplate,
      history,
    } = this.props;

    const locationSearch = new URLSearchParams(history.location.search);
    return (
      <BreadcrumbsProvider>
        <Suspense
          fallback={
            window.location.pathname !== '/' &&
            !PageDetectionMethod.Editor() &&
            !PageDetectionMethod.EditorLanding() &&
            !PageDetectionMethod.ExpiredLink() &&
            !PageDetectionMethod.VoidedLink() &&
            !PageDetectionMethod.PrepareForm() &&
            !PageDetectionMethod.SigningProcess() &&
            !PageDetectionMethod.AfterSigning() &&
            !PageDetectionMethod.LicenseInvite() &&
            !PageDetectionMethod.AfterCreatedTemplated() ? (
              <AppLayout>
                {(PageDetectionMethod.Documents() ||
                  PageDetectionMethod.Shared() ||
                  PageDetectionMethod.Starred() ||
                  PageDetectionMethod.Trash() ||
                  PageDetectionMethod.Settings()) && (
                  <div
                    style={{ height: '60vh', alignItems: 'center' }}
                    className="spinner"
                  >
                    <Loader size={35} />
                  </div>
                )}
              </AppLayout>
            ) : (
              <GlobalLoader />
            )
          }
        >
          {this.props.templateType && (
            <PandaDocIframe onCloseModal={this.onPandaDocCloseHandler} />
          )}

          {this.state.showEnterToApp &&
            this.state.enterToAppInitTemplate === 'SAVE_DOCUMENT_COPY' && (
              <EnterToApp
                initTemplate="SAVE_DOCUMENT_COPY"
                emitCloseModal={() => {
                  this.setShowModalByName('showEnterToApp', false);
                  this.props.setUserCompleteToken(null);
                }}
              />
            )}

          {this.props.show2FALoginModal && (
            <EnterToApp
              initTemplate="SIGN_IN_2FA"
              emitCloseModal={() => this.props.setIs2FAModalOpen(false)}
            />
          )}

          {this.state.showSigningProcessModal && (
            <SigningProcessV2
              fileForSign={{ _id: this.state.fileIdForSign }}
              initStep={'WHO_NEEDS_TO_SIGN'}
              signType={'IMMEDIATE'}
              template={true}
              closeModalEmitter={() => {
                this.setState({
                  showSigningProcessModal: false,
                  fileIdForSign: null,
                });
              }}
              openPrepareForm={token => this.handleOpenPrepareForm(token)}
              openSelfSigning={() => this.handleOpenSelfSigning()}
              notarizeFileEmitter={() => {}}
              goToPrevStepEmitter={() => {
                this.setState({
                  fileIdForSign: null,
                  showSigningProcessModal: false,
                });
              }}
              onCreateAccountBtnClick={() => {
                this.setState({
                  showSigningSentModal: false,
                  enterToApp: true,
                  initTemplate: 'SIGN_UP',
                });
              }}
            />
          )}

          {this.props?.showNewTemplateModal &&
            !PageDetectionMethod.Templates() && (
              <TemplateFlow
                removeRoleConfirm={async (role, index) => {
                  const res = await this.removeRoleConfirm(role, index);
                  return res;
                }}
                updatedRemoved={this.state.updatedRemoved}
                step={this.state.step}
                // this to determined wheter form roles using roles or not
                afterSigning={null}
                global={true}
                setActiveTemplateId={() => {}}
                setActiveRoleContact={activeRoleContact => {
                  this.setState({ activeRoleContact });
                }}
                makeSignatureFromTemplate={() => {}}
                setStep={step => {
                  this.setState({ step });
                }}
                activeFileId={this.props.activeFileId}
                signatureRequestId={this.state.signatureRequestId}
                activeRoleContact={this.state.activeRoleContact}
                animationState={this.state.animationState}
                setAnimationState={value => {
                  this.setState({
                    animationState: value,
                  });
                }}
                closeModalEmitter={() => {
                  this.props.setActiveFileId('');
                  this.props.toggleNewTemplateModal(false);
                }}
                openTemplateForm={({ type, roles, signatureRequestId }) => {
                  this.openTemplateForm(type, roles, signatureRequestId);
                }}
                resetState={() => {}}
              />
            )}
          {Object.keys(this.state.showRecipientRemove).length > 0 && (
            <ModalRecipientRemove
              closeModalEmitter={() => {
                this.setState({ showRecipientRemove: {} });
              }}
              fieldRemoved={this.state.fieldRemoved || 0}
              onDeleteBtnClick={() => {
                this.deleteRecipient(this.state.showRecipientRemove);
              }}
            />
          )}
          {this.props?.showContactModal && (
            <ContactListModal
              searchContact={this.state.searchContact}
              selectedContact={this.state.selectedContact}
              loadingContact={this.props.loadingContact}
              activeRoles={
                this.props.roles.length > 0
                  ? this.props.roles.map(r => r.prefillEmail)
                  : []
              }
              setSearchContact={(value, order = 'desc') => {
                this.setState(
                  {
                    searchContact: value,
                  },
                  () => {
                    this.props.setLoadingContacts(true);
                    this.searchActiveContact(value, order);
                  },
                );
              }}
              onSelectedContact={this.onSelectedContact}
              options={this.props.contacts}
              nextBtnEmitter={() => {
                if (this.props.activeFileId) {
                  this.selectContactandUpdateRole(this.props.roleContact);
                } else {
                  this.selectContactandUpdateSigner(this.props.signerContact);
                }
              }}
              closeModalEmitter={() => {
                this.setState({
                  setActiveRoleContact: true,
                });

                if (this.props.activeFileId) {
                  this.props.toggleNewTemplateModal(true);
                }
                if (!this.props.activeFileId) {
                  this.props.setActiveFileId('');
                }
                this.props.toggleShowContactModal(false);
              }}
            />
          )}

          <Banner />
          {loadingTemplate && <GlobalLoader />}
          {loading ? (
            <GlobalLoader />
          ) : (
            <>
              <NotificationContainer />
              <Switch>
                <AuthRoute
                  path="/dashboard"
                  component={Dashboard}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/documents"
                  component={MyDocuments}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/inbox"
                  component={Mailbox}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/sent"
                  component={Mailbox}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/templates"
                  component={Templates}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/shared"
                  component={SharedWithMe}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/starred"
                  component={Starred}
                  authUser={authUser}
                />
                <AuthRoute
                  path="/trash"
                  component={Trash}
                  authUser={authUser}
                />
                {SETTINGS_ROUTES_WITH_BREAD_CRUMBS.map(route => (
                  <AuthRoute {...route} exact={true} authUser={authUser} />
                ))}
                <Route
                  path="/not-found"
                  exact
                  render={() => withLayout(NotFound)}
                />
                <Route
                  path="/template/v2"
                  exact
                  component={SigningProcessPage}
                />
                <Route path="/preview/:fileId" exact component={DetailView} />
                <Route path="/sign/v2" exact component={SigningProcessPage} />
                <Route path="/pdf-editor" exact component={GlobalLoader} />
                <Route path="/prepare-form" exact component={PrepareForm} />
                <Route path="/template-form" exact component={PrepareForm} />
                <Route path="/sign/v2" exact component={SigningProcessPage} />
                <Route path="/pdf-editor" exact component={GlobalLoader} />
                <Route path="/prepare-form" exact component={PrepareForm} />
                <Route path="/editor" exact component={Editor} />
                <Route path="/after-signing" exact component={AfterSigning} />
                <Route
                  path="/after-created-template"
                  exact
                  component={AfterSigning}
                />
                <Route path="/expired-link" exact component={ExpiredLink} />
                <Route path="/voided-link" exact component={VoidedLink} />
                <Route
                  path="/document-processing"
                  exact
                  component={DocumentProcessing}
                />
                <Route
                  path="/pricing"
                  exact
                  render={() => withLayout(Pricing)}
                />
                <Route
                  path="/confirm-email"
                  exact
                  render={() => withLayout(ConfirmEmail)}
                />
                <Route
                  path="/shared-link/:sharedLinkId"
                  component={SharedFile}
                />
                <Route path="/password-reset" render={() => withLayout(Home)} />
                <Route
                  path="/reminder-unsubscribe"
                  render={() => withLayout(Home)}
                />
                <Route path="/reminder-off" render={() => withLayout(Home)} />
                <Route
                  path="/complete-profile"
                  render={() => withLayout(Home)}
                />
                <Route path="/email-verified" render={() => withLayout(Home)} />
                <Route path="/sent-request" render={() => withLayout(Home)} />
                <Route path="/security-check" render={() => withLayout(Home)} />
                <Route path="/license-invite" render={() => withLayout(Home)} />
                <Route
                  path="/account-deleted"
                  render={() => withLayout(Home)}
                />
                <Route
                  path="/create-signature"
                  render={() => withLayout(Home)}
                />
                {/* ToDo: will be removed */}
                <Route path="/add-signature" render={() => withLayout(Home)} />
                <Route path="/login" render={() => withLayout(Home)} />
                <Route path="/login/" render={() => withLayout(Home)} />
                <Route path="/sign-up" render={() => withLayout(Home)} />
                <Route path="/sign-up/" render={() => withLayout(Home)} />
                <Route path="/" render={() => withLayout(Home)} />

                <Redirect to="/" />
              </Switch>
            </>
          )}
        </Suspense>
      </BreadcrumbsProvider>
    );
  }
}

const mapStateToProps = ({
  authUser,
  pandaDoc,
  documentController,
  contact,
  createTemplate,
}) => {
  const { user: authUserDetails, loading, show2FALoginModal } = authUser;
  const { isUserProfileCompleted, templateType, userCompleteToken } = pandaDoc;
  const { showNewTemplateModal, showContactModal } = documentController;
  const { contacts, loading: loadingContact } = contact;
  const {
    signers,
    roles,
    loadingTemplate,
    roleContact,
    signerContact,
    activeRoleContact,
    activeFileId,
    activeFields,
  } = createTemplate;

  return {
    authUserDetails,
    show2FALoginModal,
    templateType,
    userCompleteToken,
    isUserProfileCompleted,
    showNewTemplateModal,
    showContactModal,
    loadingContact,
    contacts,
    loading,
    roleContact,
    signerContact,
    roles,
    signers,
    activeFileId,
    loadingTemplate,
    activeRoleContact,
    activeFields,
  };
};

const mapDispatchToProps = {
  getCurrentUser,
  setIs2FAModalOpen,
  resizeDeviceWidth,
  setTemplateType,
  setUserCompleteToken,
  setSenderEmail,
  setSenderFullName,
  logoutUser,
  updateWaitForMeDocuments,
  setUserProfileCompletedStatus,
  setLoadingContacts,
  setContacts,
  setRoles,
  setSigners,
  toggleShowContactModal,
  toggleNewTemplateModal,
  toggleUploadDocumentSignNotarizeModal,
  setLoadingTemplate,
  setActiveFileId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
