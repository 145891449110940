import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AppStoreIcon } from '../../assets/img/download-in-app-store.svg';

const initBackground = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

const AppStoreLink = props => {
  return (
    <a
      href="https://apps.apple.com/us/app/esign-app/id1513896514"
      target="_blank"
    >
      <AppStoreIcon />
    </a>
  );
};

AppStoreLink.propTypes = {
  background: PropTypes.oneOf(Object.keys(initBackground)),
};

AppStoreLink.defaultProps = {
  background: initBackground.DARK,
};

export default AppStoreLink;
