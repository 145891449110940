import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './services';

import { configureStore } from './redux/store';

import App from './App';

import './index.css';
import browserHistory from './hook/history';

export const ReduxStore = configureStore();

ReactDOM.render(
  <Provider store={ReduxStore}>
    <Router history={browserHistory}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
