import { api } from './index';

export const createContact = contactData => {
  return api.post({
    path: '/contact',
    body: contactData,
  });
};

export const updateContact = (contactId, contactData) => {
  return api.put({
    path: `/contact/${contactId}`,
    body: contactData,
  });
};

export const getContact = contactId => {
  return api.get({
    path: `/contact/${contactId}`,
  });
};

export const searchContact = (params, signal = null) => {
  return api.get({
    path: `/contact?${params}`,
    signal,
  });
};

export const deleteContact = contactId => {
  return api.delete({
    path: `/contact/${contactId}`,
  });
};

export const updateSettingsContact = contactSettingsData => {
  return api.delete({
    path: `/user/settings/contact`,
    body: contactSettingsData,
  });
};
