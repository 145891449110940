import {
  LOGIN_USER,
  LOGIN_USER_2FA,
  SET_LOADING,
  SET_IS_2FA_MODAL_OPEN,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  GET_CURRENT_USER,
  LOGIN_USER_ERROR,
  SET_USER_FULL_NAME,
  SET_USER_EMAIL,
  SET_USER_AVATAR,
  SET_USER_ACCOUNT_TYPE,
  SET_USER_ADDRESS,
  SET_USER_PHONE,
  SET_USER_REGIONAL_SETTINGS,
  SET_USER_NOTIFICATION,
  SET_USER_TWO_FACTOR_AUTH,
  SET_USER_TWO_FACTOR_AUTH_AUTHENTICATOR,
  DELETE_USER_TWO_FACTOR_AUTH_APP,
  SET_USER_TWO_FACTOR_AUTH_EMAIL,
  DELETE_USER_TWO_FACTOR_AUTH_EMAIL,
  STORAGE_REMAINING_SUCCESS,
  SET_SHARED_LINK,
  CLEAR_SHARED_LINK,
  SET_CONFIRM_EMAIL_SEND,
  SET_IS_EMAIL_VERIFIED,
  SET_USER_COMPANY_NAME,
  SET_USER_JOB_TITLE,
  SET_USER_NOTIFICATION_RECEPIENT_VIEWS_SIGN_REQUEST_SETTINGS,
  SET_USER_NOTIFICATION_RECEPIENT_SIGN_A_DOCUMENT_SETTINGS,
  SET_USER_NOTIFICATION_DOCUMENT_HAS_BEEN_SIGNED_ALL_PARTIES_SETTINGS,
  SET_USER_NOTIFICATION_BROWSER_PUSH_NOTIFICATIONS_SETTINGS,
  SET_USER_NOTIFICATION_IN_APP_PUSH_NOTIFICATIONS_SETTINGS,
  SET_USER_SIGNING_PROCESS,
  SET_USER_CONTACTS_AUTO_FILL_SETTINGS,
  SET_USER_CONTACTS_SAVE_RECEPIENTS_SETTINGS,
  SET_SHOW_LOGIN_MODAL_OPEN,
  SET_USER_TEMPLATE_TIP_SETTINGS,
} from '../actions';

const INIT_STATE = {
  user: null,
  show2FALoginModal: false,
  loading: false,
  errorLogin: null,
  showLoginModal: false,
  viaSharedLink: {
    status: false,
    visit: false,
    link: '',
  },
  storage: { total: 0, used: 0 },
  confirmEmailSend: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: false };
    case LOGIN_USER_2FA:
      return { ...state, loading: false };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_SHOW_LOGIN_MODAL_OPEN:
      return { ...state, showLoginModal: action.payload };
    case SET_IS_2FA_MODAL_OPEN:
      return { ...state, show2FALoginModal: action.payload, loading: false };
    case SET_USER_FULL_NAME:
      return { ...state, user: { ...state.user, fullName: action.payload } };
    case SET_USER_EMAIL:
      return { ...state, user: { ...state.user, email: action.payload } };
    case SET_USER_AVATAR:
      return { ...state, user: { ...state.user, logoUrl: action.payload } };
    case SET_USER_ACCOUNT_TYPE:
      return { ...state, user: { ...state.user, business: action.payload } };
    case SET_USER_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            personal: {
              ...state.user.settings.personal,
              address: action.payload,
            },
          },
        },
      };
    case SET_USER_PHONE:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            personal: {
              ...state.user.settings.personal,
              phone: action.payload,
            },
          },
        },
      };
    case SET_USER_REGIONAL_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: { ...state.user.settings, regional: action.payload },
        },
      };
    case SET_USER_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            notificationsEnabled: action.payload,
          },
        },
      };
    case SET_USER_TWO_FACTOR_AUTH:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            twoFactor: {
              ...state.user.settings.twoFactor,
              enabled: action.payload,
            },
          },
        },
      };
    case SET_USER_TWO_FACTOR_AUTH_AUTHENTICATOR:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            twoFactor: {
              ...state.user.settings.twoFactor,
              authenticator: action.payload,
            },
          },
        },
      };
    case SET_USER_TWO_FACTOR_AUTH_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            twoFactor: {
              ...state.user.settings.twoFactor,
              email: action.payload,
            },
          },
        },
      };
    case DELETE_USER_TWO_FACTOR_AUTH_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            twoFactor: { ...state.user.settings.twoFactor, email: null },
          },
        },
      };
    case DELETE_USER_TWO_FACTOR_AUTH_APP:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            twoFactor: {
              ...state.user.settings.twoFactor,
              authenticator: null,
            },
          },
        },
      };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, errorLogin: action.payload };
    case LOGOUT_USER:
      return { ...state, user: null };
    case STORAGE_REMAINING_SUCCESS:
      return { ...state, storage: action.payload };
    case GET_CURRENT_USER:
      return { ...state, loading: true };
    case SET_SHARED_LINK:
      return {
        ...state,
        viaSharedLink: { status: true, link: action.payload, visit: false },
      };
    case CLEAR_SHARED_LINK:
      return {
        ...state,
        viaSharedLink: { status: false, link: '', visit: true },
      };
    case SET_CONFIRM_EMAIL_SEND:
      return { ...state, confirmEmailSend: action.payload };
    case SET_IS_EMAIL_VERIFIED:
      return {
        ...state,
        user: {
          ...state.user,
          isEmailVerified: action.payload,
        },
      };
    case SET_USER_COMPANY_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            main: { ...state.user.settings?.main, company: action.payload },
          },
        },
      };
    case SET_USER_JOB_TITLE:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            main: { ...state.user.settings?.main, jobTitle: action.payload },
          },
        },
      };
    case SET_USER_NOTIFICATION_RECEPIENT_VIEWS_SIGN_REQUEST_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            emailNotifications: {
              ...state.user.settings?.emailNotifications,
              emailOnRecipientView: action.payload,
            },
          },
        },
      };
    case SET_USER_NOTIFICATION_RECEPIENT_SIGN_A_DOCUMENT_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            emailNotifications: {
              ...state.user.settings?.emailNotifications,
              emailOnRecipientSignature: action.payload,
            },
          },
        },
      };
    case SET_USER_NOTIFICATION_DOCUMENT_HAS_BEEN_SIGNED_ALL_PARTIES_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            emailNotifications: {
              ...state.user.settings?.emailNotifications,
              emailOnCompletedSignature: action.payload,
            },
          },
        },
      };
    case SET_USER_NOTIFICATION_BROWSER_PUSH_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        notification: {
          ...state.notification,
          browserPush: action.payload,
        },
      };
    case SET_USER_NOTIFICATION_IN_APP_PUSH_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        notification: {
          ...state.notification,
          inAppPush: action.payload,
        },
      };
    case SET_USER_SIGNING_PROCESS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            signatureProcess: {
              ...state.user.settings?.signatureProcess,
              guideOptionalFields: action.payload,
            },
          },
        },
      };
    case SET_USER_CONTACTS_SAVE_RECEPIENTS_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            contacts: {
              ...state.user.settings?.contacts,
              autoSaveRecipients: action.payload,
            },
          },
        },
      };
    case SET_USER_CONTACTS_AUTO_FILL_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            contacts: {
              ...state.user.settings?.contacts,
              autoFillContacts: action.payload,
            },
          },
        },
      };
    case SET_USER_TEMPLATE_TIP_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            templates: {
              ...state.user.settings?.templates,
              skipTipModal: action.payload,
            },
          },
        },
      };
    default:
      return { ...state };
  }
};
