import {
  SET_SELECTED_FILTER,
  GET_FOLDER_ELEMENTS,
  GET_FOLDER_ELEMENTS_SUCCESS,
  GET_RECENT_DOCUMENTS,
  GET_RECENT_DOCUMENTS_SUCCESS,
  SET_FOLDER_ELEMENTS,
  TRIGGER_UPDATE_WAIT_FOR_ME_DOCUMENTS,
  TOGGLE_DOCUMENT_CHECKBOX,
  TOGGLE_ALL_DOCUMENTS_CHECKBOX,
  CHANGE_PERMISSION_VIA_SHARE_MODAL,
  CHECK_AS_FAVORITE,
  CHECK_AS_UNFAVORITE,
  SET_TYPE_MAIL_BOX,
} from '../actions';

const INIT_STATE = {
  selectedFilter: 'lastAdded',
  documents: [],
  mailBoxType: '',
  loading: false,
  recentDocuments: [],
  updateWaitForMeDoc: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_FILTER:
      return { ...state, selectedFilter: action.payload };
    case GET_FOLDER_ELEMENTS:
      return { ...state, loading: true };
    case SET_TYPE_MAIL_BOX:
      return {
        ...state,
        loading: false,
        mailBoxType: action.payload,
      };
    case SET_FOLDER_ELEMENTS:
      return {
        ...state,
        loading: false,
        documents: action.payload.map(d => ({ ...d, isChecked: false })),
      };
    case GET_RECENT_DOCUMENTS:
      return { ...state, loadingRecentDocuments: true };
    case GET_RECENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loadingRecentDocuments: false,
        recentDocuments: action.payload,
      };
    case GET_FOLDER_ELEMENTS_SUCCESS:
      return { ...state, loading: false, documents: action.payload };
    case TRIGGER_UPDATE_WAIT_FOR_ME_DOCUMENTS:
      return { ...state, updateWaitForMeDoc: action.payload };
    case TOGGLE_DOCUMENT_CHECKBOX:
      return {
        ...state,
        documents: state.documents.map((d, i) => {
          if (i === action.payload.index) {
            return { ...d, isChecked: action.payload.isChecked };
          }
          return d;
        }),
      };
    case TOGGLE_ALL_DOCUMENTS_CHECKBOX:
      return {
        ...state,
        documents: state.documents.map(d => {
          return { ...d, isChecked: action.payload.isChecked };
        }),
      };
    case CHANGE_PERMISSION_VIA_SHARE_MODAL:
      return {
        ...state,
        documents: state.documents.map(item => {
          return {
            ...item,
            shared: item.shared.map(item =>
              item.email === action.payload.email
                ? { ...item, permission: action.payload.permission.value }
                : item,
            ),
          };
        }),
      };
    case CHECK_AS_FAVORITE:
      return {
        ...state,
        documents: state.documents.map(item => {
          return item._id === action.payload
            ? { ...item, favorite: true }
            : item;
        }),
      };
    case CHECK_AS_UNFAVORITE:
      return {
        ...state,
        documents: state.documents.map(item => {
          return item._id === action.payload
            ? { ...item, favorite: false }
            : item;
        }),
      };
    default:
      return { ...state };
  }
};
