import {
  SET_TEMPLATE_ID,
  SET_TEMPLATE_TYPE,
  SET_TEMPLATE_TOKEN,
  SET_TEMPLATE_IFRAME,
  SET_USER_PROFILE_COMPLETED_STATUS,
  SET_USER_COMPLETE_TOKEN,
} from '../actions';

const INIT_STATE = {
  templateId: '',
  templateType: null,
  templateStatus: '',
  templateUrl: '',
  templateToken: '',
  isUserProfileCompleted: true,
  userCompleteToken: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TEMPLATE_ID:
      return { ...state, templateId: action.payload };
    case SET_TEMPLATE_TYPE:
      return { ...state, templateType: action.payload };
    case SET_TEMPLATE_TOKEN:
      return { ...state, templateToken: action.payload };
    case SET_TEMPLATE_IFRAME:
      return { ...state, templateUrl: action.payload };
    case SET_USER_PROFILE_COMPLETED_STATUS:
      return { ...state, isUserProfileCompleted: action.payload };
    case SET_USER_COMPLETE_TOKEN:
      return { ...state, userCompleteToken: action.payload };
    default:
      return { ...state };
  }
};
