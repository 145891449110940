import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setWhoNeedsToSign } from '../../redux/createTemplate/actions';
import CustomInput from '../custom/CustomInput';
import { validateEmail } from '../../utils';

const WhereSendCopyStep = props => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [isNameValid, setNameValid] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const onSetEmail = e => {
    setEmail(e.target.value);
    setEmailValid(validateEmail(e.target.value));
  };

  const onSetFullName = e => {
    setFullName(e.target.value);
    setNameValid(e.target.value.trim() !== '');
  };

  const onClickNext = () => {
    setShowValidationMessage(true);
    if (isNameValid && isEmailValid && fullName.length <= 50) {
      props.goToNextStepEmitter(props.signers, {
        senderName: fullName,
        senderEmail: email,
      });
    }
  };

  return (
    <div
      className={`esign-modal signing-step-modal signing-step-modal__where-to-send ${props.animationState}`}
    >
      <h3 className="esign-modal__title">Where should we send copy?</h3>
      <p>
        Once all recipients have signed, we will send you a copy of the
        completed document.
        <br /> <br />
        Please enter your name and email to continue:
      </p>
      <div className="input-field-wrapper">
        <CustomInput
          value={fullName}
          type="text"
          placeholder="Your full name"
          name="fullName"
          onChange={onSetFullName}
          onBlur={onSetFullName}
          error={
            (showValidationMessage && !isNameValid) || fullName.length > 50
          }
          errorMessage={
            fullName.length > 50
              ? 'The name shouldn’t be longer than 50 characters.'
              : 'Please enter your first and last name'
          }
        />
      </div>
      <div className="input-field-wrapper">
        <CustomInput
          value={email}
          type="email"
          placeholder="Your email"
          name="email"
          onChange={onSetEmail}
          onBlur={onSetEmail}
          error={showValidationMessage && !isEmailValid}
          errorMessage="Please enter a valid email address"
        />
      </div>
      <div className={'signing-step-modal__where-to-send--divider'}>
        <hr />
        <span className={'signing-step-modal__where-to-send--divider-text'}>
          OR
        </span>
      </div>
      <button
        className="esign-button esign-button--light esign-button--full-width signup-button"
        onClick={props.signUpForFree}
      >
        Sign up for Free
      </button>
      <div className="esign-modal__footer">
        <button
          className="esign-button esign-button--light"
          onClick={props.goToPrevStepEmitter}
        >
          Back
        </button>
        <button
          className="esign-button esign-button--blue"
          onClick={onClickNext}
        >
          Next
        </button>
      </div>
    </div>
    // </Modal>
  );
};

WhereSendCopyStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  goToPrevStepEmitter: PropTypes.func,
};

WhereSendCopyStep.defaultProps = {
  showBackButton: false,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const { signers } = createTemplate;

  return { user, signers };
};

const mapDispatchToProps = {
  setWhoNeedsToSign,
};

export default connect(mapStateToProps, mapDispatchToProps)(WhereSendCopyStep);
