import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';

import './style.scss';

const Settings = React.lazy(() => import('./index'));
const SyncSettings = React.lazy(() => import('./SyncSettings/index'));
const PreferencesSettings = React.lazy(() =>
  import('./PreferencesSettings/index'),
);
const SecuritySettings = React.lazy(() => import('./SecuritySettings/index'));
const BillingSettings = React.lazy(() => import('./BillingSettings/index'));
const ExportSettings = React.lazy(() => import('./ExportSettings/index'));
const PlansSettings = React.lazy(() => import('./PlansSettings'));
const CheckoutSettings = React.lazy(() => import('./CheckoutSettings'));
const ContactsSettings = React.lazy(() => import('./ContactsSettings'));
const ContactsNewSettings = React.lazy(() => import('./ContactsSettings/New'));
const LicensesSettings = React.lazy(() => import('./LicensesSettings'));

const settingsRoutes = [
  { path: '/settings', component: Settings },
  { path: '/settings/contacts', component: ContactsSettings },
  { path: '/settings/contacts/new', component: ContactsNewSettings },
  { path: '/settings/contacts/:slug', component: ContactsNewSettings },
  { path: '/settings/sync', component: SyncSettings },
  { path: '/settings/preferences', component: PreferencesSettings },
  { path: '/settings/security', component: SecuritySettings },
  { path: '/settings/licenses', component: LicensesSettings },
  { path: '/settings/export', component: ExportSettings },
  { path: '/settings/billing', component: BillingSettings },
  { path: '/settings/billing/plans', component: PlansSettings },
  { path: '/settings/billing/plans/checkout', component: CheckoutSettings },
];

export const SETTINGS_ROUTES_WITH_BREAD_CRUMBS = settingsRoutes.map(route => ({
  ...route,
  component: withBreadcrumbs(route.component),
}));

function withBreadcrumbs(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <>
          <div className="settings-breadcrumbs">
            <Breadcrumbs
              separator={<span className="settings-breadcrumbs__separator" />}
              item={NavLink}
              container={'div'}
            />
          </div>
          <WrappedComponent {...this.props} />
        </>
      );
    }
  };
}
