import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Modal from '../custom/Modal';
import SignIn from './signIn';
import SignIn2FA from './signIn2FA';
import SignUp from './signUp';
import SaveDocumentCopy from './saveDocumentCopy';
import ResetPassword from './resetPassword';
import SetNewPassword from './setNewPassword';

import { ReactComponent as EsignLogoBlack } from '../../assets/img/esign-logo-black.svg';

import './style.scss';
import { animationStateEnumeration } from '../SigningProcess';

const templateTypes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_2FA: 'SIGN_IN_2FA',
  SIGN_UP: 'SIGN_UP',
  SAVE_DOCUMENT_COPY: 'SAVE_DOCUMENT_COPY',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
};

class EnterToApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templateType: this.props.initTemplate,
      animationState: animationStateEnumeration.STATIC,
      initEmail: '',
    };
  }

  goToTemplate = templateType => {
    this.setState({ templateType });
  };

  goToAllDonePage = () => {
    this.props.history.push('/');
    this.props.emitCloseModal(false);
  };

  render() {
    const { templateType } = this.state;
    const {
      SIGN_IN,
      SIGN_IN_2FA,
      SIGN_UP,
      SAVE_DOCUMENT_COPY,
      RESET_PASSWORD,
      SET_NEW_PASSWORD,
    } = templateTypes;

    if (
      (templateType === SIGN_IN || templateType === SIGN_UP) &&
      this.props.user
    ) {
      this.props.emitCloseModal();
    }

    return (
      <Modal
        className={'enter-to-app-modal'}
        toggle={this.props.emitCloseModal}
      >
        <div className="enter-to-app">
          {templateType === SAVE_DOCUMENT_COPY && (
            <SaveDocumentCopy
              goToSignInTemplateEmitter={() => this.goToTemplate(SIGN_IN)}
              goToAllDonePageEmitter={this.goToAllDonePage}
            />
          )}
          {templateType !== SAVE_DOCUMENT_COPY && (
            <div className="enter-to-app__content">
              <div className="enter-to-app__form-wrapper">
                {/* <div className="enter-to-app__logo">
                  <EsignLogoBlack />
                  <p>TM</p>
                </div> */}
                {templateType === SIGN_IN && (
                  <SignIn
                    animationState={this.state.animationState}
                    loginCallback={this.props.emitCloseModal}
                    onForgotPasswordBtnClick={(e, email) => {
                      this.setState(
                        {
                          animationState:
                            animationStateEnumeration.RIGHT_TO_LEFT,
                          initEmail: email,
                        },
                        () => this.goToTemplate(RESET_PASSWORD),
                      );
                    }}
                    onDontHaveAccountBtnClick={() =>
                      this.setState(
                        {
                          animationState:
                            animationStateEnumeration.RIGHT_TO_LEFT,
                        },
                        () => this.goToTemplate(SIGN_UP),
                      )
                    }
                  />
                )}
                {templateType === SIGN_IN_2FA && (
                  <SignIn2FA onLoginEmitter={this.props.emitCloseModal} />
                )}
                {templateType === SIGN_UP && (
                  <SignUp
                    animationState={this.state.animationState}
                    initData={this.props.initData.signUp}
                    goToSignInTemplateEmitter={() => {
                      this.setState(
                        {
                          animationState:
                            animationStateEnumeration.LEFT_TO_RIGHT,
                        },
                        () => this.goToTemplate(SIGN_IN),
                      );
                    }}
                  />
                )}
                {templateType === RESET_PASSWORD && (
                  <ResetPassword
                    animationState={this.state.animationState}
                    initEmail={this.state.initEmail}
                    goToSignInTemplateEmitter={() =>
                      this.setState(
                        {
                          animationState:
                            animationStateEnumeration.LEFT_TO_RIGHT,
                        },
                        () => this.goToTemplate(SIGN_IN),
                      )
                    }
                  />
                )}
                {templateType === SET_NEW_PASSWORD && (
                  <SetNewPassword
                    animationState={this.state.animationState}
                    setNewPasswordCallback={this.props.emitCloseModal}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

EnterToApp.propTypes = {
  initTemplate: PropTypes.oneOf(
    Object.keys(templateTypes).filter(t => t !== 'RESET_PASSWORD'),
  ),
  emitCloseModal: PropTypes.func,
  initData: PropTypes.shape({
    signUp: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

EnterToApp.defaultProps = {
  initData: {},
};

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;

  return { user };
};

export default withRouter(connect(mapStateToProps, null)(EnterToApp));
