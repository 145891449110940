import { api, getTokenFromCookie } from './index';
import { BASE_URL_V2, BASE_URL_V3 } from '../static/config';
import axios from 'axios';

export const createPandaDocTemplate = body => {
  // return api.post({
  //   path: '/pandadoc/template',
  //   body: data,
  // });

  return axios
    .post(`${BASE_URL_V3}/pandadoc/template`, body, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const createPandaDocTemplateImmediate = body => {
  // return api.post({
  //   path: '/pandadoc/template/immediate',
  //   body: data,
  // });

  return axios
    .post(`${BASE_URL_V3}/pandadoc/template/immediate`, body, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const checkSignTemplateStatus = fileId => {
  return api.get({
    path: `/pandadoc/template/${fileId}/completed`,
  });
};

export const checkSignTemplateStatusPublic = signatureToken => {
  return api.get({
    path: `/pandadoc/template/completed?token=${signatureToken}`,
  });
};

export const getTemplateStatus = templateId => {
  return api.get({
    path: `/pandadoc/${templateId}/status`,
  });
};

export const getIframeUrl = templateId => {
  return api.get({
    path: `/pandadoc/${templateId}/iframe`,
  });
};

export const getIframeUrlPublic = token => {
  return api.get({
    path: `/pandadoc/iframe/public?token=${token}`,
  });
};

export const getIframeUrlByFileId = fileId => {
  return api.get({
    path: `/pandadoc/template/file/${fileId}/iframe`,
  });
};

export const getFileHistoryByFileId = fileId => {
  // return api.get({
  //   path: `/pandadoc/template/file/${fileId}/history`,
  // });

  return axios
    .get(`${BASE_URL_V2}/pandadoc/template/file/${fileId}/history`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const remindSigners = (fileId, users) => {
  return api.post({
    path: `/pandadoc/template/file/${fileId}/remind`,
    body: {
      emailsToRemind: users,
    },
  });
};

export const voidSignRequest = (reasonMessage, fileId) => {
  // api.post({
  //   path: `/pandadoc/template/file/${fileId}/void`,
  //   body: { message: reasonMessage || '' },
  // });

  return axios
    .post(
      `${BASE_URL_V2}/pandadoc/template/file/${fileId}/void`,
      {
        message: reasonMessage || '',
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`,
        },
      },
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const voidNotarizeRequest = (reasonMessage, fileId) => {
  return api.post({
    path: `/file/${fileId}/notarize/void`,
    body: { message: reasonMessage || '' },
  });

  // return axios.post(`${BASE_URL_V2}/file/${fileId}/notarize/void`,
  //   {
  //     message: reasonMessage || ''
  //   },
  //   {
  //     headers: {
  //       'Authorization': `Bearer ${getTokenFromCookie()}`,
  //     },
  //   }
  // ).then(response => response?.data)
  //   .catch(error => error?.response?.data)
};

export const getFreeSignatureStatus = () => {
  return api.get({
    path: '/pandadoc/free-signature',
  });
};

export const getFreeSignatureStatusByEmail = email => {
  return api.get({
    path: `/pandadoc/free-signature/public?email=${email}`,
  });
};

export const getUrlForSignatureRequestedFileByToken = signatureToken => {
  return api.get({
    path: `/pandadoc/template/url?token=${signatureToken}`,
  });
};

export const getFileInfoByToken = signatureToken => {
  return api.get({
    path: `/pandadoc/file/token?token=${signatureToken}`,
  });
};
