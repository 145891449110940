import React from 'react';

import { chooseIconByExtension } from '../../../utils';

import Modal from '../../custom/Modal';

import { ReactComponent as WarningIcon } from '../../../assets/img/warning.svg';

import './style.scss';

const DeleteInProgressElementModal = props => {
  return (
    <Modal toggle={props.closeModalEmitter}>
      <div className="esign-modal delete-in-progress-element-modal">
        <h1 className="esign-modal__title">Delete in-Progress File</h1>

        <p className="esign-modal__subtitle">
          {chooseIconByExtension('pdf')} {props.element.name}
        </p>

        <hr />

        <br />

        <p className="esign-modal__subtitle">
          <WarningIcon /> Warning: this file is still awaiting signatures
        </p>

        <p className="esign-modal__description">
          By deleting this document, you are cancelling all signing activities.
          All recipients will be notified that this document can no longer be
          signed.
        </p>

        <div className="esign-modal__footer">
          <button
            className="esign-button esign-button--light"
            onClick={props.closeModalEmitter}
          >
            Cancel
          </button>
          <button
            className="esign-button esign-button--blue"
            onClick={() => props.onDeleteBtnClick()}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteInProgressElementModal;
