import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter, Link, NavLink, useHistory } from 'react-router-dom';

import { trashMultipleFiles } from '../../api/bulk';
import { getFolders } from '../../api/folder';
import { voidSignRequest, voidNotarizeRequest } from '../../api/pandadoc';
import {
  moveFileToFolder,
  moveFolderToFolder,
  addMarkFavoriteFile,
} from '../../api/file';

import {
  setFolderElements,
  ckeckAsFavorite,
} from '../../redux/myDocuments/actions';

import { ReactComponent as SettingsIcon } from '../../assets/img/setings.svg';
import { ReactComponent as BasketIcon } from '../../assets/img/basket.svg';
import { ReactComponent as StarredIcon } from '../../assets/img/starred.svg';
import { ReactComponent as SharedIcon } from '../../assets/img/share-2.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/dashboard.svg';
import { ReactComponent as MyDocumentsIcon } from '../../assets/img/my-documents.svg';
import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg';
import { ReactComponent as PlusIcon } from '../../assets/img/plus.svg';
import { ReactComponent as PlusIconV2 } from '../../assets/img/new_icons/plus-icon.svg';
import { ReactComponent as UploadIcon } from '../../assets/img/upload-file-2.svg';
import { ReactComponent as CreateFolderIcon } from '../../assets/img/new-folder.svg';
import { ReactComponent as UploadFolderIcon } from '../../assets/img/upload-folder.svg';
import { ReactComponent as DownSelectorIcon } from '../../assets/img/dropdown-selector.svg';
import { ReactComponent as DocumentsLibraryIcon } from '../../assets/img/document-library.svg';
import { ReactComponent as MailIcon } from '../../assets/img/mail-filled_18x18.svg';
import { ReactComponent as InboxIcon } from '../../assets/img/inbox-filled_18x18.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/img/template-filled_18x18.svg';
import { ReactComponent as SentIcon } from '../../assets/img/sent-filled_18x18.svg';

import {
  isMobileBrowser,
  PageDetectionMethod,
  chooseIconByExtension,
} from '../../utils';

import { DOMAIN_URL } from '../../static/config';

import { setUploadedFiles } from '../../redux/createTemplate/actions';

import { updateWaitForMeDocuments } from '../../redux/myDocuments/actions';

import {
  toggleUploadDocumentModal,
  toggleCreateFolderModal,
  toggleUploadFolderModal,
  toggleUploadDocumentSignNotarizeModal,
  toggleNewTemplateModal,
  setDetailMailbox,
} from '../../redux/documentController/actions';

import LinkItem from './LinkItem';
import SignRemaining from '../../components/SignRemaining';
import NotificationManager from '../../components/Notifications/NotificationManager';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import MoveElementToTrashModal from '../../components/Modals/MoveElementToTrashModal';
import WpDocumentsLibraryModal from '../../components/Modals/WpDocumentsLibraryModal';
import DeleteInProgressElementModal from '../../components/Modals/DeleteInProgressElementModal';

import './style.scss';
import { voidByFileId } from '../../api/signature';

let SideBar = ({
  documents,
  showSidebar,
  toggleSidebar,
  updateWaitForMeDocuments,
  setUploadedFiles,
  toggleUploadDocumentModal,
  toggleCreateFolderModal,
  toggleUploadFolderModal,
  toggleUploadDocumentSignNotarizeModal,
  isSomeElementDragging,
  setFolderElements,
  ckeckAsFavorite,
  setDetailMailbox,
  detailMailbox,
  toggleNewTemplateModal,
}) => {
  const [folders, setFolders] = useState([]);
  const [sourceElement, setSourceElement] = useState(null);
  const [sourceExtension, setSourceExtension] = useState('pdf');
  const [showFileAlreadyExistsModal, setShowFileAlreadyExistsModal] = useState(
    false,
  );
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [showVoidFileModal, setShowVoidFileModal] = useState(false);
  const [showWpDocumentsLibrary, setShowWpDocumentsLibrary] = useState(false);

  const history = useHistory();
  const linkRef = useRef();

  const SIDE_BAR_PAGES_MAP = [
    {
      id: 'DASHBOARD',
      title: 'Dashboard',
      icon: <DashboardIcon />,
      url: '/dashboard',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active',
      separatorIcon: null,
      innerWrapperTag: React.Fragment,
      canDrop: false,
    },
    {
      id: 'MAIL',
      title: 'Inbox / Sent',
      icon: <MailIcon />,
      url: '/inbox',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
        updateWaitForMeDocuments(true);
        if(detailMailbox){
          setDetailMailbox(false);
        }
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active item_link--active--inbox',
      separatorIcon: (
        <span
          style={{
            opacity: 1,
            transform:
              PageDetectionMethod.Inbox() || PageDetectionMethod.Sent()
                ? 'rotate(90deg)'
                : 'rotate(0deg)',
          }}
        >
          <ArrowIcon />
        </span>
      ),
      innerWrapperTag: React.Fragment,
      canDrop: false,
    },
    [
      {
        id: 'INBOX',
        title: 'Inbox',
        icon: <InboxIcon />,
        url: `/inbox`,
        exact: true,
        click: () => {
          isMobileBrowser() && toggleSidebar();
          updateWaitForMeDocuments(true);
          if(detailMailbox){
            setDetailMailbox(false);
          }
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: true,
      },
      {
        id: 'SENT',
        title: 'Sent',
        icon: <SentIcon />,
        url: '/sent',
        exact: true,
        click: () => {
          isMobileBrowser() && toggleSidebar();
          updateWaitForMeDocuments(true);
          if(detailMailbox){
            setDetailMailbox(false);
          }
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: true,
      },
    ],
    {
      id: 'DOCUMENTS',
      title: 'Documents',
      icon: <MyDocumentsIcon />,
      url: '/documents',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
        PageDetectionMethod.Documents() && updateWaitForMeDocuments(true);
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active',
      separatorIcon:
        folders.length > 1 ? (
          <span
            style={{
              opacity: PageDetectionMethod.Documents() ? '1' : '0',
              transform: PageDetectionMethod.Documents()
                ? 'rotate(90deg)'
                : 'rotate(0deg)',
            }}
          >
            <ArrowIcon />
          </span>
        ) : null,
      innerWrapperTag: React.Fragment,
      canDrop: false,
    },
    folders
      .map(
        (item, index) =>
          index !== 0 && {
            id: item.id,
            title: <label>{item.name}</label>,
            icon: chooseIconByExtension(
              item?.shared?.length > 1 ? 'folder-shared' : 'folder',
            ),
            url: `/documents/${item.id}`,
            exact: true,
            click: () => {
              isMobileBrowser() && toggleSidebar();
            },
            className: 'item_link',
            activeClassName: 'item_link item_link--active',
            separatorIcon: null,
            innerWrapperTag: 'p',
            canDrop: true,
          },
      )
      .filter(item => item),
    {
      id: 'TEMPLATES',
      title: 'Templates',
      icon: <TemplatesIcon />,
      url: '/templates',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
        PageDetectionMethod.Templates() && updateWaitForMeDocuments(true);
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active',
      separatorIcon: null,
      innerWrapperTag: React.Fragment,
      canDrop: false,
    },
    {
      id: 'SHARED',
      title: 'Shared',
      icon: <SharedIcon />,
      url: '/shared',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
        PageDetectionMethod.Shared() && updateWaitForMeDocuments(true);
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active',
      separatorIcon: null,
      innerWrapperTag: React.Fragment,
      canDrop: false,
    },
    {
      id: 'STARRED',
      title: 'Starred',
      icon: <StarredIcon />,
      url: '/starred',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active',
      separatorIcon: null,
      innerWrapperTag: React.Fragment,
      canDrop: true,
    },
    {
      id: 'TRASH',
      title: 'Trash',
      icon: <BasketIcon />,
      url: '/trash',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active',
      separatorIcon: null,
      innerWrapperTag: React.Fragment,
      canDrop: true,
    },
    {
      id: 'SETTINGS',
      title: 'Settings',
      icon: <SettingsIcon />,
      url: '/settings',
      exact: false,
      click: () => {
        isMobileBrowser() && toggleSidebar();
        if (PageDetectionMethod.Settings()) {
          // toggleExpandSetting();
        }
      },
      className: 'item_link',
      activeClassName: 'item_link item_link--active item_link--active--border',
      separatorIcon: (
        <span
          className={`arrow-setting ${
            PageDetectionMethod.Settings()
              ? `arrow-setting-opened`
              : `arrow-setting-closed`
          }`}
          style={{
            opacity: 1,
          }}
        >
          <ArrowIcon />
        </span>
      ),
      innerWrapperTag: React.Fragment,
      canDrop: false,
    },
    [
      {
        id: 'PROFILE',
        title: 'Profile',
        icon: null,
        url: '/settings',
        exact: true,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'CONTACTS',
        title: 'Contacts',
        icon: null,
        url: '/settings/contacts',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'SYNC',
        title: 'Sync',
        icon: null,
        url: '/settings/sync',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'PREFERENCES',
        title: 'Preferences',
        icon: null,
        url: '/settings/preferences',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'SECURITY',
        title: 'Security',
        icon: null,
        url: '/settings/security',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'LICENSES',
        title: 'Licenses',
        icon: null,
        url: '/settings/licenses',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'BILLING',
        title: 'Billing',
        icon: null,
        url: '/settings/billing',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
      {
        id: 'EXPORT',
        title: 'Export',
        icon: null,
        url: '/settings/export',
        exact: false,
        click: () => {
          isMobileBrowser() && toggleSidebar();
        },
        className: 'item_link',
        activeClassName: 'item_link item_link--active',
        separatorIcon: null,
        innerWrapperTag: 'p',
        canDrop: false,
      },
    ],
  ];

  useEffect(() => {
    if (showSidebar && isMobileBrowser()) {
      document.body.classList.add('body-stop-scrolling');

      return () => {
        document.body.classList.remove('body-stop-scrolling');
      };
    }
  });

  useEffect(() => {
    getFolders().then(res => {
      if (res?.length) {
        setFolders(res);
      }
    });
  }, [documents]);

  const onChooseUploadModal = data => {
    isMobileBrowser() && toggleSidebar();

    if (!PageDetectionMethod.Documents()) {
      history.push('/documents');
    }

    if (data.value === 'Upload-File') {
      toggleUploadDocumentModal(true);
    }

    if (data.value === 'Create-Folder') {
      toggleCreateFolderModal(true);
    }

    if (data.value === 'Upload-Folder') {
      toggleUploadFolderModal(true);
    }
  };

  const toggleExpandSetting = () => {
    const element = document.getElementsByClassName('arrow-setting')[0];
    const elementExpand = document.getElementsByClassName('settings-toggle')[0];
    if (elementExpand.classList?.contains('expand')) {
      elementExpand.classList.remove('expand');
      elementExpand.classList.add('not-expand');
    } else if (elementExpand.classList?.contains('not-expand')) {
      elementExpand.classList.remove('not-expand');
      elementExpand.classList.add('expand');
    }

    if (element.classList?.contains('arrow-setting-opened')) {
      element.classList.remove('arrow-setting-opened');
      element.classList.add('arrow-setting-closed');
    } else if (element.classList?.contains('arrow-setting-closed')) {
      element.classList.add('arrow-setting-opened');
      element.classList.remove('arrow-setting-closed');
    }
  };

  const onOpenUploadDDocumentSignNotarizeModal = () => {
    isMobileBrowser() && toggleSidebar();
    setUploadedFiles([]);
    history.push('/documents');

    toggleUploadDocumentSignNotarizeModal(true);
  };

  const onOpenNewTemplateModal = () => {
    isMobileBrowser() && toggleSidebar();
    // toggleNewTemplateModal(true);
    toggleUploadDocumentSignNotarizeModal(true);
  };

  const isTooltipButtonActive = () => {
    const { offsetWidth, scrollWidth } = linkRef.current || {};

    return offsetWidth <= scrollWidth;
  };

  const onDropElementHandler = (sourceId, targetId, sourceExtension) => {
    setSourceElement(documents.filter(item => item._id === sourceId)[0]);
    setSourceExtension(sourceExtension);

    const allowedTabIdForDrop = ['STARRED', 'TRASH'];

    if (allowedTabIdForDrop.includes(targetId)) {
      if (targetId === 'STARRED') {
        const sourceElement = documents.filter(
          item => item._id === sourceId,
        )[0];

        if (!sourceElement.favorite) {
          onStarDocumentHandler(sourceId, targetId);
        } else {
          NotificationManager.info(
            'File already marked as favorite',
            '',
            3000,
            null,
            null,
            'filled',
          );
        }
      } else if (targetId === 'TRASH') {
        const sourceElement = documents.filter(
          item => item._id === sourceId,
        )[0];

        if (
          sourceElement.waitForOthersCompletedByMe ||
          sourceElement.notarizeRequested ||
          sourceElement.signRequested ||
          sourceElement.signRequested?.waitForMe ||
          sourceElement.signRequested?.waitForOthers
        ) {
          setShowVoidFileModal(true);
        } else {
          setShowDeleteFileModal(true);
        }
      }
    } else if (targetId) {
      if (sourceExtension === 'folder') {
        onMoveFolderToFolderHandler(sourceId, targetId);
      } else {
        onMoveDocumentToFolderHandler(sourceId, targetId);
      }
    }
  };

  const onStarDocumentHandler = (sourceId, targetId) => {
    const sourceElement = documents.filter(item => item._id === sourceId)[0];

    ckeckAsFavorite(sourceElement._id);
    addMarkFavoriteFile(sourceElement.type, sourceElement._id);

    addMarkFavoriteFile(sourceElement.type, sourceElement._id).then(res => {
      if (res?.success) {
        NotificationManager.success(
          'File marked as favorite',
          '',
          3000,
          null,
          null,
          'filled',
        );
      } else {
        NotificationManager.error(res?.message, '', 3000, null, null, 'filled');
      }
    });
  };

  const onTrashDocumentHandler = (sourceId, targetId) => {
    setShowDeleteFileModal(false);

    const trashMultipleFilesMethod =
      sourceExtension === 'folder'
        ? trashMultipleFiles([], [sourceId])
        : trashMultipleFiles([sourceId], []);

    trashMultipleFilesMethod.then(res => {
      if (res?.success?.length) {
        NotificationManager.success(
          'File deleted',
          '',
          3000,
          null,
          null,
          'filled',
        );

        const newElements = documents.filter(item => item._id !== sourceId);

        setFolderElements(newElements);
      } else if (res?.failed) {
        res.failed.map(item => {
          NotificationManager.error(
            item.message,
            '',
            3000,
            null,
            null,
            'filled',
          );
        });
      } else {
        NotificationManager.error(
          'Something went wrong. Please, try again later.',
          '',
          3000,
          null,
          null,
          'filled',
        );
      }
    });
  };

  const onMoveDocumentToFolderHandler = (sourceId, targetId, resolveMethod) => {
    moveFileToFolder(sourceId, targetId).then(res => {
      if (res?.success) {
        NotificationManager.success(
          'File was moved',
          '',
          3000,
          null,
          null,
          'filled',
        );

        const newElements = documents.filter(item => item._id !== sourceId);

        setFolderElements(newElements);
      } else {
        if (res?.code === 409) {
          setShowFileAlreadyExistsModal(false);
        } else {
          NotificationManager.error(
            res?.message || 'Something went wrong. Please, try again later.',
            '',
            3000,
            null,
            null,
            'filled',
          );
        }
      }
    });
  };

  const onMoveFolderToFolderHandler = (sourceId, targetId) => {
    moveFolderToFolder(targetId, sourceId).then(res => {
      if (res?.success) {
        NotificationManager.success(
          'File was moved',
          '',
          3000,
          null,
          null,
          'filled',
        );

        const newElements = documents.filter(item => item._id !== sourceId);

        setFolderElements(newElements);
      } else {
        NotificationManager.error(
          res?.message || 'Something went wrong. Please, try again later.',
          '',
          3000,
          null,
          null,
          'filled',
        );
      }
    });
  };

  const onVoidDocumentHandler = (reasonMessage, file) => {
    setShowVoidFileModal(false);

    if (file?.notarizeRequested) {
      voidNotarizeRequest(reasonMessage, file._id).then(res => {
        if (res?.success) {
          NotificationManager.success(
            'Document was voided',
            '',
            4000,
            null,
            null,
            'filled',
          );

          const newElements = documents.map(item =>
            item._id === file._id
              ? {
                  ...item,
                  notarizeRequested: false,
                  ownedSignatureNotFinished: false,
                  signRequested: false,
                  waitForOthersCompletedByMe: false,
                  signatureRequest: {
                    ...item?.signatureRequest,
                    waitForMe: false,
                    waitForOthers: false,
                  },
                  voided: true,
                }
              : item,
          );
          const elementIndex = documents.indexOf(
            documents.filter(item => item._id === file._id)[0],
          );
          [newElements[0], newElements[elementIndex]] = [
            newElements[elementIndex],
            newElements[0],
          ];

          setFolderElements(newElements);
        } else {
          NotificationManager.error(
            res?.message || 'Something went wrong. Please, try again later.',
            '',
            4000,
            null,
            null,
            'filled',
          );
        }
      });
    } else {
      voidSignRequest(reasonMessage, file._id).then(res => {
        if (res?.success) {
          NotificationManager.success(
            'Document was voided',
            '',
            4000,
            null,
            null,
            'filled',
          );

          const newElements = documents.map(item =>
            item._id === file._id
              ? {
                  ...item,
                  notarizeRequested: false,
                  ownedSignatureNotFinished: false,
                  signRequested: false,
                  waitForOthersCompletedByMe: false,
                  signatureRequest: {
                    ...item?.signatureRequest,
                    waitForMe: false,
                    waitForOthers: false,
                  },
                  voided: true,
                }
              : item,
          );
          const elementIndex = documents.indexOf(
            documents.filter(item => item._id === file._id)[0],
          );
          [newElements[0], newElements[elementIndex]] = [
            newElements[elementIndex],
            newElements[0],
          ];

          setFolderElements(newElements);
        } else {
          NotificationManager.error(
            res?.message || 'Something went wrong. Please, try again later.',
            '',
            4000,
            null,
            null,
            'filled',
          );
        }
      });
    }
  };

  return (
    <>
      <div className={`side-bar-wrapper ${showSidebar ? 'show-sidebar': ''} ${isMobileBrowser() ? `side-bar-wrapper-mobile` : ``}`}>
        <ul>
          {(PageDetectionMethod.Dashboard() ||
            PageDetectionMethod.Documents() ||
            PageDetectionMethod.Inbox() ||
            PageDetectionMethod.Sent() ||
            PageDetectionMethod.Shared() ||
            PageDetectionMethod.Starred() ||
            PageDetectionMethod.Trash()) && (
            <div className="buttons-block">
              <button
                className="esign-button esign-button--blue"
                onClick={() => onOpenUploadDDocumentSignNotarizeModal()}
              >
                START
              </button>
              <Select
                className="esign-select-list"
                classNamePrefix="esign-select-list"
                isSearchable={false}
                options={[
                  {
                    value: 'Upload-File',
                    label: (
                      <>
                        <UploadIcon /> Upload file
                      </>
                    ),
                  },
                  {
                    value: 'Create-Folder',
                    label: (
                      <>
                        <CreateFolderIcon /> New folder
                      </>
                    ),
                  },
                  {
                    value: 'Upload-Folder',
                    label: (
                      <>
                        <UploadFolderIcon /> Upload folder
                      </>
                    ),
                  },
                ]}
                onChange={data => onChooseUploadModal(data)}
                value={{ value: '', label: <PlusIcon /> }}
              />
            </div>
          )}
          {PageDetectionMethod.Templates() && (
            <div className="buttons-block full-width">
              <button
                className="esign-button esign-button--blue new-template--button"
                onClick={() => onOpenNewTemplateModal()}
              >
                <PlusIconV2 /> NEW TEMPLATE
              </button>
            </div>
          )}
          {SIDE_BAR_PAGES_MAP.map((item, index) =>
            Array.isArray(item) ? (
              <div
                key={index}
                className={
                  (PageDetectionMethod.Inbox() || PageDetectionMethod.Sent()) &&
                  index === 2
                    ? 'expand'
                    : PageDetectionMethod.Documents() && index === 4
                    ? 'expand'
                    : PageDetectionMethod.Settings() && index === 10
                    ? `expand settings-toggle`
                    : `not-expand`
                }
                style={{
                  maxHeight:
                    PageDetectionMethod.Documents() &&
                    index === 2 &&
                    isSomeElementDragging
                      ? '576px'
                      : '320px',
                  transition: 'max-height 0.3s ease-in-out',
                }}
              >
                {item.map((item, index) => (
                  <LinkItem
                    index={index}
                    id={item.id}
                    title={item.title}
                    icon={item.icon}
                    url={item.url}
                    exact={item.exact}
                    click={item.click}
                    className={item.className}
                    activeClassName={item.activeClassName}
                    separatorIcon={item.separatorIcon}
                    innerWrapperTag={item.innerWrapperTag}
                    canDrop={item.canDrop}
                    onDropElementHandler={onDropElementHandler}
                    isSomeElementDragging={isSomeElementDragging}
                  />
                ))}
              </div>
            ) : (
              <LinkItem
                index={index}
                id={item.id}
                title={item.title}
                icon={item.icon}
                url={item.url}
                exact={item.exact}
                click={item.click}
                className={
                  PageDetectionMethod.Sent() && item.id === 'MAIL'
                    ? item.activeClassName
                    : item.className
                }
                activeClassName={item.activeClassName}
                separatorIcon={item.separatorIcon}
                innerWrapperTag={item.innerWrapperTag}
                canDrop={item.canDrop}
                onDropElementHandler={onDropElementHandler}
                isSomeElementDragging={isSomeElementDragging}
              />
            ),
          )}
        </ul>

        <br />
        <hr />

        <div className="documents-library-link">
          Looking for a form?
          <button
            className="esign-button esign-button--link"
            onClick={() => {
              isMobileBrowser() && toggleSidebar();
              setShowWpDocumentsLibrary(true);
            }}
          >
            <DocumentsLibraryIcon />
            Document Library
          </button>
        </div>

        <SignRemaining />
      </div>

      <div
        className={`side-bar-controller ${
          showSidebar ? 'show-controller' : ''
        }`}
        onClick={() => toggleSidebar()}
      ></div>

      {showFileAlreadyExistsModal && (
        <ConfirmationModal
          title="A file with this name already exists. Would you like to keep both or replace the existing one?"
          toggleModal={() => setShowFileAlreadyExistsModal(false)}
          cancelButtonTitle={'Keep both'}
          cancelAction={() => {
            setShowFileAlreadyExistsModal(false);
            // this.moveFileToFolder('keepBoth');
          }}
          submitButtonTitle={'Replace'}
          submitAction={() => {
            setShowFileAlreadyExistsModal(false);
            // this.moveFileToFolder('replace');
          }}
        />
      )}

      {showDeleteFileModal && (
        <MoveElementToTrashModal
          element={sourceElement}
          closeModalEmitter={() => setShowDeleteFileModal(false)}
          onDeleteBtnClick={() => onTrashDocumentHandler(sourceElement._id)}
        />
      )}

      {showVoidFileModal && (
        <DeleteInProgressElementModal
          element={sourceElement}
          closeModalEmitter={() => setShowVoidFileModal(false)}
          onDeleteBtnClick={() => onVoidDocumentHandler('', sourceElement)}
        />
      )}

      {showWpDocumentsLibrary && (
        <WpDocumentsLibraryModal
          closeModalEmitter={() => setShowWpDocumentsLibrary(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ myDocs, documentController }) => {
  const { documents } = myDocs;
  const { isSomeElementDragging, detailMailbox } = documentController;

  return { documents, isSomeElementDragging, detailMailbox };
};

const mapDispatchToProps = {
  updateWaitForMeDocuments,
  setUploadedFiles,
  toggleUploadDocumentModal,
  toggleCreateFolderModal,
  toggleUploadFolderModal,
  toggleUploadDocumentSignNotarizeModal,
  setFolderElements,
  ckeckAsFavorite,
  toggleNewTemplateModal,
  setDetailMailbox,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar),
);
