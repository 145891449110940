import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ClipboardIcon } from '../../assets/img/clipboard-with-bg.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/copy-with-bg.svg';
import { ReactComponent as RocketIcon } from '../../assets/img/rocket-with-bg.svg';

import SignUp from './signUp';

const SaveDocumentCopy = props => {
  return (
    <>
      <h3 className="enter-to-app__title">Save a copy of your document</h3>
      <div className="enter-to-app__content">
        <div className="save-document-copy">
          <p className="save-document-copy__banner">
            Sign up for a FREE eSign account today and sign all your documents
            electronically
          </p>
          <div className="save-document-copy__benefit">
            <div className="save-document-copy__icon-wrapper">
              <ClipboardIcon />
            </div>
            <span>Electronically sign any document.</span>
          </div>
          <div className="save-document-copy__benefit">
            <div className="save-document-copy__icon-wrapper">
              <CopyIcon />
            </div>
            <span>Get signatures from others.</span>
          </div>
          <div className="save-document-copy__benefit">
            <div className="save-document-copy__icon-wrapper">
              <RocketIcon />
            </div>
            <span>Sign on the go with eSign App!</span>
          </div>
        </div>
        <div className="enter-to-app__form-wrapper">
          <SignUp
            showSaveDocumentCopyFooter={true}
            goToSignInTemplateEmitter={props.goToSignInTemplateEmitter}
            goToAllDonePageEmitter={props.goToAllDonePageEmitter}
          />
        </div>
      </div>
    </>
  );
};

SaveDocumentCopy.propTypes = {
  goToSignInTemplateEmitter: PropTypes.func.isRequired,
  goToAllDonePageEmitter: PropTypes.func.isRequired,
};

export default SaveDocumentCopy;
