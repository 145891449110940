import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../custom/Modal';

const ConfirmationModal = ({
  className,
  title,
  description,
  cancelAction,
  submitAction,
  showCancelButton,
  showActionButton,
  cancelButtonTitle,
  submitButtonTitle,
  toggleModal,
  children,
}) => {
  useEffect(() => {
    document.addEventListener('keydown', onEnterKeyPressHandler);

    return () => {
      document.removeEventListener('keydown', onEnterKeyPressHandler);
    };
  }, []);

  const onEnterKeyPressHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.repeat) {
      if (e.code === 'Enter') {
        submitAction();
      }

      if (e.code === 'Backspace') {
        cancelAction() || toggleModal();
      }
    }
  };

  return (
    <Modal
      toggle={toggleModal}
      className={`sign-document-page-wrapper ${className}`}
    >
      <div className="esign-modal esign-modal--narrow">
        <h3 className="esign-modal__title esign-modal__title--left">
          {title || children}
        </h3>
        {!children && <p className="esign-modal__subtitle">{description}</p>}
        <div className="esign-modal__footer esign-modal__footer--centered">
          {showCancelButton && (
            <button
              className="esign-button esign-button--light"
              onClick={cancelAction || toggleModal}
            >
              {cancelButtonTitle}
            </button>
          )}
          {showActionButton && (
            <button
              className="esign-button esign-button--blue"
              onClick={submitAction}
            >
              {submitButtonTitle}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  showCancelButton: PropTypes.bool,
  showActionButton: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  cancelAction: PropTypes.func,
  submitAction: PropTypes.func,
  toggleModal: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  title: '',
  showCancelButton: true,
  showActionButton: true,
  cancelButtonTitle: 'No',
  submitButtonTitle: 'Yes',
};

export default ConfirmationModal;
