import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as WarningIcon } from '../../assets/img/warning.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import './style.scss';
import { getBanner } from '../../api/support';

const Banner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [warning, setWarning] = useState('');

  const location = useLocation();

  useEffect(() => {
    fetchBanner();
  }, [location.pathname]);

  const isPortable = useMemo(
    () =>
      [
        '/editor',
        '/sign/v2',
        '/template/v2',
        '/prepare-form',
        '/template-form',
      ].includes(location.pathname),
    [location.pathname],
  );

  const fetchBanner = () => {
    setShowBanner(false);
    getBanner().then(resp => {
      setShowBanner(!!resp?.enableWarning);
      setWarning(resp?.warningText);
    });
  };
  return (
    showBanner && (
      <div className={cn('system-banner', { portable: isPortable })}>
        <WarningIcon /> <span>{warning}</span>
        <CloseIcon className="close" onClick={() => setShowBanner(false)} />
      </div>
    )
  );
};

export default Banner;
