import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DragHandleIcon } from '../../assets/img/new_icons/drag-handle.svg';

const HandleWrapper = styled.div`
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
  &:hover {
    svg {
      #Drag-Icon {
        filter: none;
      }
    }
    path {
      fill: #5e5e5e;
    }
  }
`;

export const DragHandle = props => {
  return (
    <HandleWrapper
      {...props}
      tabIndex={-1}
      className={'signers-form-v2__add-signer--drag-handle'}
    >
      <DragHandleIcon />
    </HandleWrapper>
  );
};
