import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';

import './style.scss';

const Modal = ({
  toggle,
  onContextMenuHandler,
  disableBackdropClick,
  children,
  className,
  freezeBodyScroll,
  freezeModalPosition,
  showCloseButton = true,
}) => {
  useEffect(() => {
    if (freezeBodyScroll) {
      document.body.classList.add('body-stop-scrolling');

      return () => {
        document.body.classList.remove('body-stop-scrolling');
      };
    }
  }, []);

  const [styles, setStyles] = useState({});

  const modalBodyRef = useRef(null);

  useEffect(() => {
    if (modalBodyRef.current) {
      const modalHeight = modalBodyRef.current.offsetHeight;
      // calc top position to center modal in horizontal direction
      const top = (window.innerHeight - modalHeight) / 2 + 'px';

      if (freezeModalPosition) {
        setStyles({ top });
      }
    }
  }, []);

  function onBackdropClickHandler(e) {
    if (!disableBackdropClick) {
      toggle(e);
    }
  }

  return (
    <Portal node={document && document.getElementById('root')}>
      <div
        className={`custom-modal ${className}`}
        onClick={onBackdropClickHandler}
        onContextMenu={onContextMenuHandler}
      >
        {children ? (
          <div
            className="modal-body"
            style={styles}
            ref={modalBodyRef}
            onClick={e => e.stopPropagation()}
          >
            {children}
            {showCloseButton && (
              <button className="modal-body__close-btn" onClick={toggle}>
                <CloseIcon />
              </button>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  toggle: PropTypes.func.isRequired,
  onContextMenuHandler: PropTypes.func,
  className: PropTypes.string,
  freezeBodyScroll: PropTypes.bool,
  freezeModalPosition: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Modal.defaultProps = {
  className: '',
  freezeBodyScroll: true,
  freezeModalPosition: false,
  disableBackdropClick: false,
};

export default Modal;
